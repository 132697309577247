export default function getVideoThumbnail(file, seekTo = 0.0) {
	return new Promise((resolve, reject) => {
		// load the file to a video player
		const videoPlayer = document.createElement("video");
		videoPlayer.setAttribute("src", URL.createObjectURL(file));
		videoPlayer.load();
		videoPlayer.addEventListener("error", (ex) => {
			reject("error when loading video file", ex);
		});
		// load metadata of the video to get video duration and dimensions
		videoPlayer.addEventListener("loadedmetadata", () => {
			// seek to user defined timestamp (in seconds) if possible
			if (videoPlayer.duration < seekTo) {
				reject("video is too short.");
				return;
			}
			// delay seeking or else 'seeked' event won't fire on Safari
			setTimeout(() => {
				videoPlayer.currentTime = seekTo;
			}, 200);
			// extract video thumbnail once seeking is complete
			videoPlayer.addEventListener("seeked", () => {
				// define a canvas to have the same dimension as the video
				const canvas = document.createElement("canvas");
				canvas.width = videoPlayer.videoWidth;
				canvas.height = videoPlayer.videoHeight;
				// draw the video frame to canvas
				const ctx = canvas.getContext("2d");
				ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

				const dataURL = canvas.toDataURL("image/jpg", 0.8);

				const convertedFile = dataURLtoFile(
					dataURL,
					`${file.name}-thumbnail.jpg`
				);

				resolve(convertedFile);
			});
		});
	});
}

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}