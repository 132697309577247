import React, { Component } from "react";
import moment from "moment";

export default class TableRow extends Component {

    isValidURL(string) {
        if (typeof string !== 'string') return false
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    isDate(date) {
        return (new Date(date) !== "Invalid Date" && !isNaN(new Date(date)) ) ? true : false;
    }

    renderValueCol(value) {
        if (typeof value === 'number' && String(value).length === 13) { // to refactor: or find better implementation of isDate func
            return moment(value).format("DD MMMM YYYY HH:mm:ss")
        }
        else if (this.isValidURL(value)) {
            return <a key={value} href={value} target="_blank" rel="noopener noreferrer" className="user-table-col-val">{value}</a>
        }
        else if (typeof value === "boolean") return String(value)
        else if (Array.isArray(value)) {
            if (value.length === 0) return 'empty'
            return value.map((item, index) => {
                if (typeof item === 'object' && item && !Array.isArray(item)) {
                    return Object.entries(item).map(([key, itmeValue]) => {
                        return <TableRow key={key} title={key} value={itmeValue}/>
                    })
                }
                return index === 0 ? this.renderValueCol(item) : ', ' + this.renderValueCol(item); 
            })
        }
        else if (typeof value === "object") return null
        else 
            return value
    }

	render() {
        const { title, value } = this.props;

 		return (
			<div className="user-profile-row">
				<div className="user-profile-col">
					<span>{title}</span>
				</div>
				<div className="user-profile-col">{this.renderValueCol(value)}</div>
			</div>
		);
	}
}