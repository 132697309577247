import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import { Link } from 'react-router-dom';
import './css/faq.css';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import * as moment from 'moment';
import Button from '@material-ui/core/Button';

// imgs
import RefreshIcon from 'imgs/refresh.svg';
import DefaultProfileIcon from 'imgs/user-icon.svg';

class Search extends Component {

    constructor(props) {
        super(props);
        this.generateNewFaq = this.generateNewFaq.bind(this);
    }

    async generateNewFaq() {
        try {
            let response = await APIrequest.generate_faq_json();
            alert(response);
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {

        return (
            <div className="faq">
                <span className="faq-text">Generate new Faq files</span>
                <Button variant="contained" color="primary" onClick={this.generateNewFaq}>
                    Generate
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
