import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/forum.css';
import AnswerCard from './AnswerCard';
import safeKey from 'functions/safeKey.js'

// libs
import Scroll from 'react-scroll';

// imgs
import RefreshIcon from 'imgs/refresh.svg';

var Element = Scroll.Element;
var scroller = Scroll.scroller;

class Topic extends Component {
  
  constructor(props) {
    super(props);
    this.getTopic = this.getTopic.bind(this);

    this.state = {
      mode: 'loading',
      categories: [],
      selectedCategory: 'all',
      page: 0,
      lastPage: 1,
      answears: [],
      topic: {
        actualContent: {
          title: ''
        }
      }
    };

    const query = new URLSearchParams(this.props.location.search);
    this.topicID = query.get('id');
    this.anchor = query.get('anchor');

    this.getTopic(this.topicID);
  }

  async getTopic(idTopic) {
    try {
      let response = await APIrequest.forum_get_answers_full(idTopic);
      this.setState({
        mode: 'loaded',
        answears: response.data.answers,
        topic: response.data.topic
      }, () => {
        scroller.scrollTo(this.anchor, {
          duration: 1000,
          delay: 0,
          smooth: true,
          offset: -20, // Scrolls to element + 50 pixels down the page
        })
      })
    }
    catch (error) {
      console.log(error)
      this.setState({
        mode: 'error'
      })
    }
  }

  render() {

    return (
      <div className="forum">
        {(() => {
          switch (this.state.mode) {
            case 'loaded':
              return <div className="forum-container">
                <div className="forum-header-container">
                  <img onClick={() => this.getTopic(this.topicID)} src={RefreshIcon} alt="refresh" />
                  <div>
                    <div className="forum-header">Forum topic: {this.state.topic.actualContent.title}</div>
                    <div>Topic category: {safeKey( () => this.state.topic.category.localizedString)}</div>
                  </div>
                </div>
                <div className="cards">
                  {this.state.answears.map((answer) => {
                    return <Element name={answer.idAnswer} key={answer.idAnswer} style={{ width: '100%' }}>
                      <AnswerCard answer={answer} updateCallback={() => this.getTopic(this.topicID)} history={this.props.history} />
                    </Element>
                  })}
                </div>
              </div>;
            case 'loading':
              return <div>Loading...</div>;
            case 'error':
              return <div>No topic with such ID. It can be deleted or never existed</div>;
            default:
              return null;
          }
        })()}
      </div>
    )
  }
}

export default Topic
