import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';

export default class UploadPromoVideo extends Component {

    state = {
        isLoading: false,
    }

    async uploadProfileVideo(formData) {
        try {
            await APIrequest.upload_profile_video(formData, this.props.idUser);
            alert("Success!");
            this.props.onLoaded();
        } catch (error) {
            console.log(error);
            alert("Uploading file failed, server error!");
        }
    }

    deleteProfileVideo = async () => {
        try {
            const data = ['expertInfo.promoVideo']
            await APIrequest.clear_user_fields(data, this.props.idUser);
            alert("Success!");
        } catch (error) {
            console.log(error);
            alert("Deleting file failed, server error!");
        }
    }
    
    onChangeHandler = event => {
        try {
            this.setState({ isLoading: true })
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file)
            this.uploadProfileVideo(formData);
        } catch (error) {
            console.log(error);
            alert("Error, file is corrupted! Choose another file");
        }
    }

    render() {
        const { isLoading } = this.state;

        return (
            <div className="upload-profile-video">
                <div className="title-3 mb_16">Choose video file to upload</div>
                <input className="upload-pv-btn" type="file" accept="video/*,.mp4,.mov" onChange={this.onChangeHandler} size="60"></input>
                {isLoading && 
                    <div className="mt_16"><CircularProgress /></div>
                }

                <Button onClick={this.deleteProfileVideo} variant="contained" color="secondary">Delete promo video</Button>
            </div>
        )
    }
}
