import { NAV_COUNTERS } from "../constants/list";

const initialState = {
	withdraw: 0,
	experts: 0,
    productUpdates: 0,
    collaborationRequests: 0,
};

export default function updateNavCounters(state = initialState, action) {
	switch (action.type) {
		case NAV_COUNTERS:
			return { ...state, ...action.payload };

		default:
			return state;
	}
}
