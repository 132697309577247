import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import { escapeSpecialCharacters } from 'functions/escapeSpecialCharacters';
import './css/orders.css';

// libs
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SearchBar from 'material-ui-search-bar';

import DefaultProfileIcon from 'imgs/user-icon.svg';
import { Link } from 'react-router-dom';

export default class Orders extends Component {

    constructor(props) {
        super(props);
        this.changeUserOrder = this.changeUserOrder.bind(this);
        this.handleTermChange = this.handleTermChange.bind(this);

        this.state = {
            groups: [],
            selectedGroup: "all",
            users: [],
            selectedUserID: null,
            newOrder: 999999,
            lastPage: 1,
            page: 0,
        };
        this.timeout = 0;
        
        this.getGroups();
        this.findUsersByGroup();
    }

    componentDidUpdate(prevProps, prevState) {
		if (prevState.page !== this.state.page) {
			this.findUsersByGroup();
		}
	}

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleChangeSelect = event => {
        this.setState({ 
            [event.target.name]: event.target.value,
            page: 0,
        }, () => this.findUsersByGroup() );
    };

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    async handleTermChange(term) {
        if (term.length > 2) {
            if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(async () => { // send req not more often than 400ms
                if (this.validateEmail(term)) {
                    let validFirstPartOfEmail = term.split('@')[0].split('.').join("").split('+')[0]; // replace '.' and '+' before '@'. cut all after '+'
                    term = validFirstPartOfEmail + '@' + term.split('@')[1];
                }
                let data = {
                    "result": escapeSpecialCharacters(term)
                }
                if (this.state.selectedGroup !== 'all') {
                    data.categories = [this.state.selectedGroup];
                }

                try {
                    let response = await APIrequest.find_users_by_query(data, true);
                    this.setState({
                        users: response.data,
                        // selectedGroup: "all",
                        selectedUserID: null,
                        newOrder: 999999,
                        lastPage: 1,
                        page: 0,
                    });
                } catch (error) {
                    console.log(error)
                }
            }, 400); 
        }
        else if (term.length === 0) this.findUsersByGroup();
    }

    async getGroups() {
        try {
            const response = await APIrequest.get_groups();
            this.setState({
                groups: response.data
            });
        } catch (error) {
            console.log(error)
        };
    }

    async findUsersByGroup() {
        try {
            const data = {
                "page": this.state.page,
                "size": 16,
                "categories": this.state.selectedGroup === "all" ? null : [this.state.selectedGroup]
            }
            const response = await APIrequest.find_users_by_group(data);
            this.setState({
                users: response.data.items,
                lastPage: response.data.totalPages,
            });
        } catch (error) {
            console.log(error)
        };
    }

    async changeUserOrder() {
        try {
            const data = {
                "idUser": this.state.selectedUserID,
                "order": this.state.newOrder,
                "group": this.state.selectedGroup === "all" ? null : [this.state.selectedGroup]
            }
            const response = await APIrequest.change_user_order(data);

            if (response.status === 200) {
                alert("Order changed successfully")
                this.setState({
                    selectedUserID: null
                });
                this.findUsersByGroup();
            }
        } catch (error) {
            alert("error")
        }
    }

    handlePage(step) {
        this.setState({
            page: this.state.page + step
        })
    }

    render() {

        return (
			<div className="orders">
                <div className="flex mb_16">
                    <MobileStepper
                        style={{
                            height: "56px"
                        }}
                        steps={this.state.lastPage}
                        position="static"
                        variant="text"
                        activeStep={this.state.page}
                        nextButton={
                            <Button size="small" onClick={() => this.handlePage(1)} disabled={this.state.page === this.state.lastPage - 1}>
                                Next
                                <KeyboardArrowRight />
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={() => this.handlePage(-1)} disabled={this.state.page === 0}>
                                <KeyboardArrowLeft />
                                Back
                            </Button>
                        }
                    />
                    <FormControl className="group-picker" variant="filled">
                        <InputLabel htmlFor="filled-age-simple">Group</InputLabel>
                        <Select value={this.state.selectedGroup} onChange={this.handleChangeSelect} input={<FilledInput name="selectedGroup" id="filled-age-simple" />}>
                            <MenuItem value="all">All</MenuItem>
                            {this.state.groups.map( group => 
                                <MenuItem value={group.idGroup}>{group.localizedString}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <SearchBar
                        onChange={(term) => this.handleTermChange(term)}
                        onRequestSearch={() => console.log('onRequestSearch')}
                        style={{
                            marginLeft: '16px',
                            height: '56px',
                            width: 500
                        }}
                        iconButtonStyle={{
                            height: '56px',
                            width: '56px',
                        }}
                    />
                </div>
                <div className="users-list search">
                    <div className="search-container">
                        <div className="search-table">
                            <div className="search-table-row search-table-row-header">
                                <div className="search-table-col search-table-col-user search-table-col-user-header">
                                    <img className="search-table-usr-img" alt="none" />
                                    <div className="search-table-col-user-text">
                                        <span className="search-table-col-val">User Name</span>
                                        <span className="search-table-col-subval">User ID</span>
                                    </div>
                                </div>
                                <div className="search-table-col">
                                    <span className="search-table-col-val">Order</span>
                                </div>
                                <div className="search-table-col"></div>
                            </div>
                            {this.state.users.map( user => {
                                return <div className="search-table-row" key={user.idUser}>
                                    <Link to={`/user/${user.idUser}`} className="search-table-col search-table-col-user">
                                        <img className="search-table-usr-img" src={user.imageLink ? user.imageLink : DefaultProfileIcon} alt="profile" />
                                        <div className="search-table-col-user-text">
                                            <span className="search-table-col-val">{user.name}</span>
                                            <span className="search-table-col-subval">{user.idUser}</span>
                                        </div>
                                    </Link>
                                    <div className="search-table-col">
                                        <span className="search-table-col-val">
                                            { this.state.selectedUserID === user.idUser ? 
                                                <TextField
                                                    label="Order"
                                                    value={this.state.newOrder}
                                                    onChange={this.handleChange('newOrder')}
                                                    margin="none"
                                                />
                                                :
                                                user.order
                                            }
                                        </span>
                                    </div>
                                    <div className="search-table-col">
                                        { this.state.selectedUserID === user.idUser ? 
                                            <Button variant="contained" color="secondary" onClick={this.changeUserOrder}>Save</Button>
                                            :
                                            <Button variant="contained" color="primary" onClick={() => this.setState({ selectedUserID: user.idUser, newOrder: user.order })}>Change</Button>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
			</div>
		);
    }
}
