import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/categories.css';

// libs
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import CardActionArea from '@material-ui/core/CardActionArea';

class Tags extends Component {

    constructor(props) {
        super(props);
        this.addTag = this.addTag.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.state = {
            categories: [],
            selectedCategory: null
        };
        this.categoryName = this.props.location.pathname.split('/')[2] || '';
        this.categoryID = this.props.location.search.split('?id=')[1] || '';
        this.getTagsByCategories();
    }

    handleChange(name, value) {
        this.setState({
            [name]: value,
        });
    };

    handleChangeObjState = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;

        let newState = Object.assign({}, this.state[name[0]]);
        if (name.length === 2) {
            newState[name[1]] = value;
        }
        else if (name.length === 3) {
            newState[name[1]][name[2]] = value;
        }
        this.setState({
            selectedCategory: newState
        })
    };

    async getTagsByCategories() {
        const data = {
            categories: [this.categoryName],
        }
        try {
            const response = await APIrequest.get_tags_by_categories(data);
            this.setState({
                categories: Object.values(response.data)[0] ? Object.values(response.data)[0] : []
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async deleteTag(idTag, name) {
        let data = {
            idTag: idTag,
            idCategory: this.categoryID,
            name: name,
        }
        try {
            await APIrequest.delete_tag(data);
            alert("Tag deleted!");
            this.setState({
                selectedCategory: null
            });
            this.getTagsByCategories();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async addTag() {
        let data = this.state.selectedCategory;
        data.idCategory = this.categoryID;
        try {
            await APIrequest.add_tag(data);
            alert("Tag changed!");
            this.setState({
                selectedCategory: null
            });
            this.getTagsByCategories();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {
        console.log(this.state.selectedCategory)

        return (
            <div className="categories">
                <div className="page-header-container">
                    <Typography variant="h4" component="h2">
                        Tags
                    </Typography>
                </div>
                <div className="page-content">
                    {this.state.selectedCategory ? 
                        <div className="edit-category">
                            <TextField
                                label="Text"
                                value={this.state.selectedCategory.name}
                                onChange={this.handleChangeObjState(["selectedCategory", "name"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Order"
                                type="number"
                                value={this.state.selectedCategory.order}
                                onChange={this.handleChangeObjState(["selectedCategory", "order"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="En"
                                value={this.state.selectedCategory.localizationMap.en}
                                onChange={this.handleChangeObjState(["selectedCategory", "localizationMap", "en"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Ru"
                                value={this.state.selectedCategory.localizationMap.ru}
                                onChange={this.handleChangeObjState(["selectedCategory", "localizationMap", "ru"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Uk"
                                value={this.state.selectedCategory.localizationMap.uk}
                                onChange={this.handleChangeObjState(["selectedCategory", "localizationMap", "uk"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <Button className="edit-category-btn" variant="contained" color="primary" onClick={this.addTag}>save</Button>
                            <Button className="edit-category-btn" variant="contained" color="secondary" onClick={this.deleteTag.bind(this, this.state.selectedCategory.idTag, this.state.selectedCategory.name)}>delete</Button>
                        </div>
                    :
                    <div className="categories-cards">
                        {this.state.categories.map( a => {
                            return <Card className="categories-card">
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {a.name}
                                    </Typography>
                                    <br/>
                                    <Typography color="textSecondary">
                                        Order: {a.order}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        ID: {a.idCategory}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button fullWidth size="small" onClick={this.handleChange.bind(this, "selectedCategory", a)}>Edit</Button>
                                </CardActions>
                            </Card>
                        })}
                        <Card className="categories-card">
                            <CardActionArea className="add-new-category-CAA" onClick={this.handleChange.bind(this, "selectedCategory", { name: '', localizationMap: { en: '', ru: '', uk: '' } })}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    Add new tag
                                </Typography>
                            </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default Tags;