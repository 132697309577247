import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/news.css';

// libs 
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import AutoselectUsers from 'components/autoselect users/AutoselectUsers';
import Checkbox from '@material-ui/core/Checkbox';

class NewsWithNotification extends Component {

  constructor(props) {
    super(props);
    this.sendNews = this.sendNews.bind(this);
    this.getChannels = this.getChannels.bind(this);
    this.state = {
      messageEn: '',
      messageRu: '',
      messageUk: '',
      channels: ['push', 'message', 'email'],
      checkedEmail: true,
      checkedPush: true,
      checkedMessage: true,
      recipientType: 'ALL'
    };
    this.selectedUsersToSend = [];
    this.selectedUsersToExclude = [];
  }

  handleChangeCheckbox = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleUsersList = (userList, userID) => {
    switch (userList) {
      case 'send':
        this.selectedUsersToSend = updateList(this.selectedUsersToSend);
        break;
      case 'exclude':
        this.selectedUsersToExclude = updateList(this.selectedUsersToExclude);
        break
      default:
        break;
    }

    function updateList(list) {
      if (list.indexOf(userID) === -1) {
        list.push(userID);
      }
      else {
        list.splice(list.indexOf(userID), 1);
      }
      return list
    }
  }

  async sendNews() {
    try {
      let localizedMessage = {}
      if (this.state.messageEn.length > 0) localizedMessage.en = this.state.messageEn;
      if (this.state.messageRu.length > 0) localizedMessage.ru = this.state.messageRu;
      if (this.state.messageUk.length > 0) localizedMessage.uk = this.state.messageUk;

      let data = {
        users: [],
        channels: this.getChannels(),
        recipientType: this.state.recipientType,
        localizedMessage: localizedMessage,
      };
      if (this.selectedUsersToSend !== [])
        data.users = this.selectedUsersToSend;
      if (this.selectedUsersToExclude !== [])
        data.excludeUsers = this.selectedUsersToExclude;
      
      await APIrequest.send_news(data);
      alert("News are sent");
    } catch (error) {
      console.log(error)
      alert("Network error. Try again later")
    }
  }

  getChannels() {
    let channels = [];
    if (this.state.checkedEmail)
      channels.push('email')
    if (this.state.checkedPush)
      channels.push('push')
    if (this.state.checkedMessage)
      channels.push('message')
    return channels
  }

  render() {

    return (
      <div className="news">
        <span className="news-text">Send fresh news</span>
        <div className="news-content">
        <div className="box-1">
          <TextField
            className="message-input"
            label="Message EN"
            value={this.state.messageEn}
            onChange={this.handleChange("messageEn", "value")}
            margin="normal"
            variant="outlined"
            multiline
            rows="8"
          />
          <TextField
            className="message-input"
            label="Message RU"
            value={this.state.messageRu}
            onChange={this.handleChange("messageRu", "value")}
            margin="normal"
            variant="outlined"
            multiline
            rows="8"
          />
          <TextField
            className="message-input"
            label="Message UK"
            value={this.state.messageUk}
            onChange={this.handleChange("messageUk", "value")}
            margin="normal"
            variant="outlined"
            multiline
            rows="8"
          />
        </div>
        <div className="box-2">
          <span>Users to send</span><br/>
          <AutoselectUsers onUsersListChange={this.handleUsersList.bind(this, 'send')} label="Leave this field empty to send message for all" placeholder="Type ID or name"/>
          <span>Users to exclude</span><br />
          <AutoselectUsers onUsersListChange={this.handleUsersList.bind(this, 'exclude')} label="Optional" placeholder="Type ID or name"/>
          <div>
            <div className="cheboxes">
              <div className="chebox-container">
                <Checkbox
                  checked={this.state.checkedEmail}
                  onChange={this.handleChangeCheckbox('checkedEmail')}
                  value="checkedEmail"
                  color="primary"
                />
                <span className="label-1">Email</span>
              </div>
              <div className="chebox-container">
                <Checkbox
                  checked={this.state.checkedPush}
                  onChange={this.handleChangeCheckbox('checkedPush')}
                  value="checkedPush"
                  color="primary"
                />
                <span>Push</span>
              </div>
              <div className="chebox-container">
                <Checkbox
                  checked={this.state.checkedMessage}
                  onChange={this.handleChangeCheckbox('checkedMessage')}
                  value="checkedMessage"
                  color="primary"
                />
                <span>Message</span>
              </div>
            </div>
            <Radio
              checked={this.state.recipientType === 'ALL'}
              onChange={this.handleChange('recipientType', 'value')}
              value="ALL"
              name="radio-button-demo"
            />
            <span>All</span>
            <Radio
              checked={this.state.recipientType === 'EXPERTS_ONLY'}
              onChange={this.handleChange('recipientType', 'value')}
              value="EXPERTS_ONLY"
              name="radio-button-demo"
            />
            <span>Experts only</span>
            <Radio
              checked={this.state.recipientType === 'USERS_ONLY'}
              onChange={this.handleChange('recipientType', 'value')}
              value="USERS_ONLY"
              name="radio-button-demo"
            />
            <span>Users only</span>
          </div>
          <Button disabled={!(this.state.messageEn.length > 0 || this.state.messageRu.length > 0 || this.state.messageUk.length > 0)} variant="contained" color="primary" onClick={this.sendNews}>
            Send
          </Button>
        </div>
        </div>
      </div>
    )
  }
}

export default NewsWithNotification;