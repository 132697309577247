import {store} from 'index';

function logoutUser() {
    console.log('logoutUser', window)
    store.dispatch(({
        type: 'STORE_USER',
        payload: null
    }));; // redux
    localStorage.setItem('user', JSON.stringify(null)); // localstorage
    navigator.serviceWorker.getRegistrations().then( // service worker
        function (registrations) {
            for (let registration of registrations) {
                registration.unregister();
            }
        }
    );

    window.location.href = '/login';
}

export default logoutUser