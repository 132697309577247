import React, { Component } from 'react'

export default class TableSection extends Component {
    render() {
        const { title, children } = this.props;

        return (
            <div>
                <div className="user-wallet-header">{title}</div>
                {
                    children
                }
            </div>
        )
    }
}
