import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import UniCard from '../UniCard/UniCard';
import ExecuteBtn from './ExecuteBtn';
import RefundBtn from './RefundBtn';
import AddScore from './AddScore';

export default class Transaction extends Component {

    constructor(props) {
        super(props);
        this.getLog = this.getLog.bind(this);

        this.state = {
            log: {},
            executedBy: null,
        };

        this.getLog(this.props.match.params.id);
    }

    async getLog(idLog) {
        try {
            const response = await APIrequest.history_log_by_id(idLog);
            const log = response.data;
            this.setState({
                log: log
            })

            if (log.status === "COMPLETED" && (log.operationType === "PRODUCT" || log.operationType === "PRODUCT_REQUEST")) {
                this.getExecutedBy(idLog);
            }
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async getExecutedBy(idLog) {
        try {
            const response = await APIrequest.get_executed_by(idLog);
            this.setState({
                executedBy: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { log, executedBy } = this.state;

        return (
            <div className="transaction">
                <ExecuteBtn
					product={log}
				/>
                <RefundBtn product={log} />
                <AddScore product={log} />
                <UniCard obj={log}/>
                { executedBy && 
                    <div>
                        <div className="title-5 center mt_16 mb_16">Executed by</div>
                        <UniCard obj={executedBy}/>
                    </div>
                }
            </div>
        )
    }
}
