import React, { useState } from "react";
import { md5 } from "functions";

// libs
import { Button, CircularProgress } from "@material-ui/core";
import Snackbar from "components/common/snackbar/Snackbar";

export default function ExecutePhysicalBtn(props) {
	const { setAnswerMsg, product } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const [fileName, setFileName] = useState(null)

	const displayCond = (() => {
        try {
            const productType = props.product.object.product.productType;
            return product.status === "IN_PROGRESS" && productType === "PHYSICAL";
        } catch (error) {
            console.log(error);
            return false;
        }
	})();

	const valideFileSize = (file) => {
		const maxFileSize = Math.pow(1024, 3); // 1 Gb
		return file.size <= maxFileSize;
	};

	const showSendScreen = (file, md5) => {
		const formData = new FormData();
        formData.append("file", file, file.name);
        setFileName(file.name);
		setAnswerMsg({
			id: product.idTransaction,
			message: "Product.Autograph.Create.TextField.Comment.Placeholder",
			title: file.name,
			type: "execute-physical-order",
			limits: { min: 0 },
			answer: {
				file: formData,
				md5: md5,
			},
		});
		setIsLoading(false);
	};

	const filePicked = async (event) => {
		try {
			setIsLoading(true);
			const file = event.target.files[0];
			if (!valideFileSize(file))
				showError("UploadVideo.Error.FileSize");
			else {
				md5(file, "file").then(
					(md5) => showSendScreen(file, md5),
					(error) => showError(`Ошибка: ${error.message}`)
				);
			}
		} catch (error) {
			showError("Error, file is corrupted! Choose another file");
		}
	};

	const resetInputValue = (event) => {
		// allow to pick same file multiple times
		event.target.value = "";
	};

	const showError = (errorText) => {
		setIsLoading(false);
		setSnackbarText(errorText);
		setSnackbarIsOpen(true);
	};

	return displayCond ? (
		<>
			{isLoading ? (
				<button className="ppc-btn_fw ppc-btn_trans mb_8">
					<CircularProgress className="cir-prog_white" size={24} />
				</button>
			) : (
                <Button variant="contained" color="primary" style={{ padding: '0px' }}>
                    <label style={{ width: "100%", padding: "6px 16px" }}>
                        <input
                            hidden
                            type="file"
                            accept="image/*, .jpeg, .jpg, .png"
                            onChange={filePicked}
                            onClick={resetInputValue}
                        ></input>
                        {fileName || "Pick file"}
                    </label>
                </Button>
			)}
			<Snackbar
				isOpen={snackbarIsOpen}
				handleClose={() => setSnackbarIsOpen(false)}
				text={snackbarText}
			/>
		</>
	) : null;
}
