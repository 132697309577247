import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/product.css';

// components
import UploadPhoto from "components/upload photo/UploadPhoto";

// libs
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
// import ReactCrop from 'react-image-crop';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import 'react-image-crop/dist/ReactCrop.css';
import { DateTimePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// imgs 
import DEFAULT_IMG_COVER from 'imgs/cover_autograph@2x.png';
import EditGallery from './EditGallery';
import UploadPromoVideo from './UploadProductVideo';

const productStatus = [
    {
        value: 'ENABLED',
        label: 'Enable',
    },
    {
        value: 'DISABLED',
        label: 'Disable',
    },
    {
        value: 'DELETED',
        label: 'Delete',
    },
];

const productTypes = [ "AUTOGRAPH", "VIDEO_GREETING", "AUTO_REPLY", "PHYSICAL", "EVENT", "COLLABORATION", "DONATION" ];

const productSellTypes = ["AUCTION", "PURCHASE"];

class Products extends Component {

    constructor(props) {
        super(props);
        this.createProduct = this.createProduct.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.saveVariationHandle = this.saveVariationHandle.bind(this);
        this.editVariationHandle = this.editVariationHandle.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);

        this.state = {
            about: "",
            shortAbout: "",
            expressAvailable: false,
            expressRate: 0,
            image: null,
            coverImage: null,
            productStatus: "ENABLED",
            productType: "VIDEO_GREETING",
            standardRate: 0,
            idProduct: '',
            userID: this.props.userID ? this.props.userID : '',
            operationType: this.props.operationType,
            products: [],
            mode: 'viewProducts',
            editCoverMode: false,
            idOwner: "",
            name: "",
            fileURL: "",
            fileDescription: "",
            aboutIsValid: true,
            standardRateIsValid: true,
            productStatusIsValid: true,
            counterAvailable: null,
            newCounterAvailable: null,
            counterUsed: null,
            eventDetailsAddress: null, 
            eventDetailsAdditionalText: null, 
            eventDetailsDate: null,
            gallery: [],
            video: "",
            agentCommission: 0,
            sellType: null,
            auctionSettingsEndDate: new Date(),
            auctionSettingsStartDate: new Date(),
            auctionSettingsStartBid: 1,
            auctionSettings: {},
            variations: null,
            variationToEditPrice: "",
            variationToEditName: "",
            variationToEdit: null,
        };

        this.getUserProducts();
    }

    handleChange = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;
        this.setState({
            [name]: value,
        });
    };

    handleChangeCoverMode(event) {
        this.setState({
            editCoverMode: event
        })
    }

    fieldsAreValid(formData) {
        let errors = false;
        // if (!!!formData.about) {
        //     this.setState({ aboutIsValid: false })
        //     errors = true;
        // }
        // else {
        //     this.setState({ aboutIsValid: true })
        // }

        if (typeof formData.standardRate === 'number' && Number(formData.standardRate) < 0) {
            this.setState({ standardRateIsValid: false })
            errors = true;
        }
        else if (formData.sellType === "AUCTION") {
            const startDate = formData.auctionSettings.startDate;
            const endDate = formData.auctionSettings.endDate;
            const currentTime = new Date();
            if (!!!startDate || !!!endDate || startDate < currentTime || endDate < currentTime || startDate >= endDate) {
                errors = true;
                alert("Invalid auction dates. Provide future start and end dates. End date should be greater that start date.")
            }
        }
        else {
            this.setState({ standardRateIsValid: true })
        }
        
        // if (formData.productStatus === "INITIAL") {
        //     this.setState({ productStatusIsValid: false })
        //     errors = true;
        // }
        // else {
        //     this.setState({ productStatusIsValid: true })
        // }

        return !errors;
    }

    async getUserProducts () {
        try {
            let response = await APIrequest.get_user_products(this.props.userID);
            this.setState({
                products: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    async createProduct() {
        let data = {
            about: this.state.about,
            shortAbout: this.state.shortAbout,
            image: this.state.image,
            coverImage: this.state.coverImage,
            productStatus: this.state.productStatus,
            productType: this.state.productType,
            standardRate: this.state.standardRate,
            fileDescription: this.state.fileDescription,
            fileURL: this.state.fileURL,
            name: this.state.name,
            gallery: this.state.gallery,
            video: this.state.video,
            sellType: this.state.sellType,
        };
        if (this.state.expressRate > 0) {
            data.expressAvailable = true;
            data.expressRate = this.state.expressRate;
        }
        if (this.state.idProduct && this.state.idProduct.length > 0)
            data.idProduct = this.state.idProduct;
        if (!!this.state.agentCommission) data.agentCommission = this.state.agentCommission;
        if (this.state.productType === "EVENT") {
            data.eventDetails = {};
            data.eventDetails.address = this.state.eventDetailsAddress;
            data.eventDetails.additionalText = this.state.eventDetailsAdditionalText;
            data.eventDetails.date = this.state.eventDetailsDate;
        }
        if (this.state.newCounterAvailable && (Number(this.state.newCounterAvailable) !== (this.state.counterAvailable - this.state.counterUsed))) {
            data.counterAvailable = Number(this.state.newCounterAvailable);
            data.counterUsed = this.state.counterUsed;
        }
        if (this.state.sellType === "AUCTION") {
            data.auctionSettings = this.state.auctionSettings;
            data.auctionSettings.startBid = this.state.auctionSettingsStartBid;
            data.auctionSettings.startDate = this.state.auctionSettingsStartDate;
            data.auctionSettings.endDate = this.state.auctionSettingsEndDate;
        }
        if(this.state.productType === "DONATION") {
            data.variations = this.state.variations;
        }

        const fieldsAreValid = this.fieldsAreValid(data);

        if (fieldsAreValid) {
            try {
                await APIrequest.save_product(data, this.props.userID);
                alert("Product successful changed");
                this.props.userUpdatedCallback();
            } catch (error) {
                console.log(error)
                alert("Network error. Try again later")
            }
        }
        else {
            alert("Fill all fields correctly!")
        }
    }

    async deleteProduct() {
        try {
            const data = {
                idProduct: this.state.idProduct
            }
            const response = await APIrequest.delete_product(this.state.userID, data);
            if(response.status === 200) {
                alert("Product successful deleted");
                this.props.userUpdatedCallback();
            }
            else alert("Network error. Try again later")
        } catch (error) {
            console.log(error);
            alert("Network error. Try again later")
        }
    }

    async uploadImg(formData) {
        try {
            formData.append('info', JSON.stringify({
                type: 'PRODUCT_COVER',
                productType: this.state.productType,
                idUser: this.state.userID,
            }));
            const response = await APIrequest.upload_some_image(formData);
            alert("img uploaded! plz, finish updating product info and press submit");
            this.setState({
                coverImage: response.data.result,
                editCoverMode: false
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    editProduct(productDetails) {
        this.setState({
            mode: 'editProduct',
            about: productDetails.about,
            shortAbout: productDetails.shortAbout,
            expressAvailable: productDetails.expressAvailable,
            expressRate: productDetails.expressRate,
            image: productDetails.image,
            coverImage: productDetails.coverImage,
            productStatus: productDetails.productStatus,
            productType: productDetails.productType,
            standardRate: productDetails.standardRate,
            idProduct: productDetails.idProduct,
            idOwner: productDetails.idOwner,
            name: productDetails.name,
            fileURL: productDetails.fileURL,
            fileDescription: productDetails.fileDescription,
            counterAvailable: productDetails.counterAvailable,
            counterUsed: productDetails.counterUsed,
            gallery: productDetails.gallery,
            video: productDetails.video,
            agentCommission: productDetails.agentCommission,
            sellType: productDetails.sellType,
        })

        if (productDetails.productType === "EVENT") {
            this.setState({
                eventDetailsAddress: productDetails.eventDetails.address, 
                eventDetailsAdditionalText: productDetails.eventDetails.additionalText, 
                eventDetailsDate: productDetails.eventDetails.date,
            })
        }

        if (!!productDetails.auctionSettings && !!productDetails.auctionSettings.endDate) {
            this.setState({
                auctionSettingsEndDate: productDetails.auctionSettings.endDate,
                auctionSettingsStartDate: productDetails.auctionSettings.startDate,
                auctionSettingsStartBid: productDetails.auctionSettings.startBid,
                auctionSettings: productDetails.auctionSettings,
            })
        }

        if (productDetails.variations) {
            this.setState({
                variations: productDetails.variations,
            })
        }
    }

    saveVariationHandle() {
        const { variationToEditPrice, variationToEditName, variationToEdit } = this.state;
        let newVariations = [];
        const newVariation = {
            name: variationToEditName,
            price: variationToEditPrice,
        }
        if (variationToEdit !== "new") newVariation.id = variationToEdit;
        const currentVariations = this.state.variations || [];
        const variationToReplaceIndex = currentVariations.findIndex(variation => !!variation.id && variation.id === newVariation.id);
        if(variationToReplaceIndex > -1) {
            currentVariations[variationToReplaceIndex] = newVariation;
            newVariations = currentVariations;
        }
        else {
            newVariations = [...currentVariations, newVariation];
        };

        this.setState({
            variations: newVariations,
            variationToEditPrice: "",
            variationToEditName: "",
            variationToEdit: null,
        })
    }

    editVariationHandle(variation) {
        const { id, name, price } = variation;
        this.setState({
            variationToEditPrice: price,
            variationToEditName: name,
            variationToEdit: id,
        })
    }

    deleteVariationHandle({id}) {
        const currentVariations = this.state.variations;
        const variationToReplaceIndex = currentVariations.findIndex(variation => !!variation.id && variation.id === id);
        currentVariations.splice(variationToReplaceIndex, 1);
        this.setState({
            variations: currentVariations,
        })
    }

    render() {
        const { productType, video, sellType, auctionSettings, variations, variationToEditPrice, variationToEditName, variationToEdit } = this.state;

        return (
            <div className="add-user-table">
                <div className="user-table-header-container">
                    <span className="user-table-header">
                        Set up products
                    </span>
                </div>
                {this.state.mode === "editProduct" ? 
                    <div className="add-new-user">
                        <div className="add-new-user-form flex-col edit-product-form">
                            <TextField
                                label="Name"
                                value={this.state.name}
                                onChange={this.handleChange("name", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="File URL"
                                value={this.state.fileURL}
                                onChange={this.handleChange("fileURL", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="File Description"
                                value={this.state.fileDescription}
                                onChange={this.handleChange("fileDescription", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="About"
                                value={this.state.about}
                                onChange={this.handleChange("about", "value")}
                                error={!this.state.aboutIsValid}
                                // helperText={'Required field'}
                                margin="normal"
                                variant="outlined"
                                multiline
                            />
                            <TextField
                                label="Short about"
                                value={this.state.shortAbout}
                                onChange={this.handleChange("shortAbout", "value")}
                                margin="normal"
                                variant="outlined"
                                multiline
                            />
                            {(this.state.productType === "EVENT") && <>
                                <TextField
                                    label="Event details address"
                                    value={this.state.eventDetailsAddress}
                                    onChange={this.handleChange("eventDetailsAddress", "value")}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <TextField
                                    label="Event details additional text"
                                    value={this.state.eventDetailsAdditionalText}
                                    onChange={this.handleChange("eventDetailsAdditionalText", "value")}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <div className="text-1">Event details date</div>
                                <DateTimePicker 
                                    value={this.state.eventDetailsDate} 
                                    onChange={value => this.setState({ eventDetailsDate: value })} 
                                    margin="normal"
                                />
                            </>}
                            <TextField
                                label="Standard rate"
                                value={this.state.standardRate}
                                onChange={this.handleChange("standardRate", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                                error={!this.state.standardRateIsValid}
                                helperText={'Should be > 0'}
                            />
                            <TextField
                                label="Express rate"
                                value={this.state.expressRate}
                                onChange={this.handleChange("expressRate", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Agent commission"
                                value={this.state.agentCommission}
                                onChange={this.handleChange("agentCommission", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />      
                            <TextField
                                label="Product cover"
                                value={this.state.coverImage}
                                onChange={this.handleChange("coverImage", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Video URL"
                                value={video}
                                onChange={this.handleChange("video", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                select
                                label="Select product status"
                                value={this.state.productStatus}
                                onChange={this.handleChange('productStatus')}
                                margin="normal"
                                variant="outlined"
                                error={!this.state.productStatusIsValid}
                                helperText={'Required field'}
                            >
                                {productStatus.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <FormControl margin="normal" variant="outlined" fullWidth>
                                <InputLabel id="product-sell-type-label">Sell type</InputLabel>
                                <Select
                                    labelId="product-sell-type-label"
                                    value={sellType || ""}
                                    onChange={this.handleChange('sellType', 'value')}
                                    label="Sell type"
                                    fullWidth
                                >
                                    {productSellTypes.map( st => <MenuItem key={st} value={st}>{st}</MenuItem>)}
                                </Select>
                            </FormControl>
                            {(this.state.productType === "DONATION") && <div className="mt_16 mb_16">
                                <div className="title-5">Donation variations</div>
                                {variations && variations.map(variation => {
                                    return <div className="variation-item">
                                        <div className="text-1">
                                            <div>{`Name: ${variation.name}`}</div>
                                            <div>{`Price: ${variation.price}`}</div>
                                        </div>
                                        {!!variation.id &&
                                            <div className="flex">
                                                <Button style={{marginRight: "8px"}} variant="outlined" color="primary" fullWidth={false} onClick={() => this.editVariationHandle(variation)}>Edit</Button>
                                                <Button variant="outlined" color="secondary" fullWidth={false} onClick={() => this.deleteVariationHandle(variation)}>Delete</Button>
                                            </div>
                                        }
                                    </div>
                                })}
                                {variationToEdit && <div className="variation-item">
                                    <div>
                                        <TextField
                                            label="Name"
                                            value={variationToEditName}
                                            onChange={this.handleChange("variationToEditName", "value")}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                        <br/>
                                        <TextField
                                            label="Price"
                                            value={variationToEditPrice}
                                            onChange={this.handleChange("variationToEditPrice", "value")}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="flex-center">
                                        <Button variant="contained" color="primary" fullWidth={false} onClick={this.saveVariationHandle}>Save</Button>
                                    </div>
                                </div>}
                                {!!!variationToEdit && 
                                    <Button
                                        variant="contained" 
                                        color="primary" 
                                        fullWidth={false} 
                                        onClick={() => this.setState({variationToEdit: "new"})}
                                    >
                                        Add new variation
                                    </Button>
                                }
                            </div>}
                            {
                                (sellType === "AUCTION" && 
                                    <div className="mt_16 mb_16">
                                        <div className="title-5">Auction settings</div>
                                        <TextField
                                            label="Start bid"
                                            value={this.state.auctionSettingsStartBid}
                                            onChange={this.handleChange("auctionSettingsStartBid", "value")}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                        /> 
                                        <div>
                                            <div className="text-2">Start date</div>
                                            <DateTimePicker 
                                                value={this.state.auctionSettingsStartDate} 
                                                onChange={value => this.setState({ auctionSettingsStartDate: value })}
                                            />
                                        </div>
                                        <div>
                                            <div className="text-2">End date</div>
                                            <DateTimePicker 
                                                value={this.state.auctionSettingsEndDate} 
                                                onChange={value => this.setState({ auctionSettingsEndDate: value })}
                                            />
                                        </div>
                                        { auctionSettings && (
                                            <>
                                                <div className="text-1">{`Instant Purchase: ${auctionSettings.instantPurchase}`}</div>
                                                <div className="text-1">{`Notified about start: ${auctionSettings.notifiedAboutStart}`}</div>
                                                <div className="text-1">{`Total bids: ${auctionSettings.totalBids}`}</div>
                                            </>
                                        )}
                                    </div>
                                )
                            }
                            {
                                (this.state.productType === "PHYSICAL" || this.state.productType === "EVENT") &&
                                    <div className="mt_16 mb_16">
                                        <div className="text-1">Counter available: {this.state.counterAvailable - this.state.counterUsed}</div>
                                        <div className="text-1">Counter used: {this.state.counterUsed}</div>
                                        <TextField
                                            label="Update available counter. Old counters values will be refreshed"
                                            value={this.state.newCounterAvailable}
                                            onChange={this.handleChange("newCounterAvailable", "value")}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                            }
                            <EditGallery onUploaded={(newGallery) => this.setState({ gallery: newGallery })} currentGallery={this.state.gallery} />
                            <UploadPromoVideo onLoaded={(videoURL) => this.setState({ video: videoURL })} />
                            {/* <div className="flex-col">
                                <div className="product-cover-edit">
                                    <img src={this.state.coverImage ? this.state.coverImage : DEFAULT_IMG_COVER} alt="Product cover"/>
                                </div>
                            </div>
                            <Button className="register-new-user-btn" variant="contained" color="primary" onClick={this.createProduct}>
                                Submit
                            </Button> */}
                            {this.state.editCoverMode ?
                                <UploadPhoto photoIsUploadedCallbak={this.uploadImg}/>
                                :
                                <div className="flex-col">
                                    <div className="product-cover-edit" onClick={this.handleChangeCoverMode.bind(this, true)}>
                                        <div className="product-cover-edit-label">Edit</div>
                                        <img src={this.state.coverImage ? this.state.coverImage : DEFAULT_IMG_COVER} alt="Product cover"/>
                                    </div>
                                    <Button className="register-new-user-btn" variant="contained" color="primary" onClick={this.createProduct}>
                                        Submit
                                    </Button>
                                    <Button className="register-new-user-btn" variant="contained" color="secondary" onClick={this.deleteProduct}>
                                        Delete product
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="product-container">
                        <Card className="product-card">
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Add new product
                                </Typography>
                                <FormControl margin="normal" variant="outlined" fullWidth>
                                    <InputLabel id="new-product-type-label">Product type</InputLabel>
                                    <Select
                                        labelId="new-product-type-label"
                                        value={productType}
                                        onChange={this.handleChange('productType', 'value')}
                                        label="Product type"
                                        fullWidth
                                    >
                                        {productTypes.map( pt => <MenuItem key={pt} value={pt}>{pt}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <Button color="primary" variant="contained" fullWidth onClick={() => this.setState({ mode: 'editProduct' })}>
                                    Create
                                </Button>
                            </CardContent>
                        </Card>
                        {this.state.products.map(a => {
                            return <Card className="product-card">
                                <CardActionArea onClick={this.editProduct.bind(this, a)}>
                                    <CardMedia
                                        component="img"
                                        height="250"
                                        image={a.coverImage ? a.coverImage : DEFAULT_IMG_COVER}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {a.productType}
                                        </Typography>
                                        <Typography component="p">
                                            About: {a.about}
                                        </Typography>
                                        <Typography component="p">
                                            idProduct: {a.idProduct}
                                        </Typography>
                                        <Typography component="p">
                                            expressRate: {a.expressRate}
                                        </Typography>
                                        <Typography component="p">
                                            standardRate: {a.standardRate}
                                        </Typography>
                                        <Typography component="p">
                                            productStatus: {a.productStatus}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        })}
                    </div>
                }
            </div>
        )
    }
}

export default Products;