import React, { useEffect, useState } from 'react'
import "../css/user-subscriptions.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { API } from '../../../APIcalls/APIrequest';
import moment from "moment"
import DEFAULT_PROFILE_ICON from 'imgs/user-icon.svg';
import { Button, CircularProgress } from '@material-ui/core';

const modes = [
    {
        id: 'Purchased subscribers',
        req: (idUser, data) => API.post(`get-purchased-subscribers/${idUser}`, data),
        model: "subscription",
    },
    {
        id: 'Purchased subscriptions',
        req: (idUser, data) => API.post(`get-purchased-subscriptions/${idUser}`, data),
        model: "subscription",
    },
    {
        id: 'User subscribers',
        req: (idUser, data) => API.post(`get-user-subscribers/${idUser}`, data),
        model: "user",
    },
    {
        id: 'User subscriptions',
        req: (idUser, data) => API.post(`get-user-subscriptions/${idUser}`, data),
        model: "user",
    },
    {
        id: 'Incoming regular donations',
        req: (idUser, data) => API.post(`get-incoming-regular-donations/${idUser}`, data),
        model: "subscription",
    },
    {
        id: 'Outgoing regular donations',
        req: (idUser, data) => API.post(`get-outgoing-regular-donations/${idUser}`, data),
        model: "subscription",
    },
]

export default function SubscriptionsList(props) {
    const { user } = props;
    const [activeMode, setActiveMode] = useState(modes[0]);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!!user && !!user.idUser && !!!items.length) {
            loadTable(modes[0])
        }
    }, [user])

    useEffect(() => {
        if (!!user && !!user.idUser) {
            loadTable(activeMode);
        }
    }, [activeMode, page]);

    const handleChangeMode = (event) => {
        const mode = event.target.value;
        setActiveMode(mode);        
        setPage(0);
    }

    const loadTable = async (mode) => {
        setIsFetching(true);
        try {
            let data = {
                "page": page,
                "size": 50,
            }
            const response = await mode.req(user.idUser, data);
            setItems(response.data.items);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.log(error);
        }
        setIsFetching(false);
    } 

    const nextBtnHandle = () => setPage(page + 1);
    const prevBtnHandle = () => setPage(page - 1);

    return (
        <div className="user-table">
            <div className="user-table-header-container">
                <span className="user-table-header">Subscriptions list</span>
            </div>
            <div className="user-subscriptions">
                <div className="controlls">
                    <FormControl variant="outlined" style={{ width: "300px" }}>
                        <InputLabel id="mode-select">Mode</InputLabel>
                        <Select
                            labelId="mode-select"
                            value={activeMode}
                            onChange={handleChangeMode}
                            label="Mode"
                        >
                            {modes.map(mode => {
                                return <MenuItem key={mode.id} value={mode}>{mode.id}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <div className="controlls-page-btns">
                        <Button disabled={page <= 0} onClick={prevBtnHandle}>Prev</Button>
                        {`${page + 1}/${totalPages}`}
                        <Button disabled={page + 1 >= totalPages} onClick={nextBtnHandle}>Next</Button>
                    </div>
                </div>
                {isFetching ? 
                    <CircularProgress />
                    :
                    <div className="items-list">
                        {items.map((sub) => {
                            return <SubItem key={sub.id || sub.idUser} sub={sub} activeMode={activeMode} />;
                        })}
                    </div>
                }
            </div>
        </div>
    )
}

const SubItem = (props) => {
	const { sub, activeMode: { model } } = props;
	let img = "DEFAULT_PROFILE_ICON";
	let name = "";
	let price = 0;
	let endPeriod = 0;
    let subRowText = "";

    switch (model) {
        case "subscription":
            if (!!!sub.partner) return null;
            if (sub.partner.imageLink) img = sub.partner.imageLink;

	        const isCanceled = sub.cancelAtPeriodEnd;
            name = sub.partner.name;
            price = sub.amount || sub.subscription.price;
            endPeriod = moment(sub.periodEnd).format("L");
            const priceLabel = `$${price}/month. `;
            const endPeriodLabel = "will end on " + endPeriod;
            const nextPaymentLabel = "Next payment " + endPeriod;

            subRowText = isCanceled
                ? endPeriodLabel
                : priceLabel + nextPaymentLabel;
            break;
        case "user":
            if (sub.imageLink) img = sub.imageLink;
            name = sub.name;
            subRowText = sub.expertInfo ? sub.expertInfo.work : "";
            break;
        default:
            break;
    }
	

	return (
		<SubItemView
			img={img}
			name={name}
			subRowText={subRowText}
		/>
	);
};

function SubItemView(props) {
	const { img, name, subRowText, btn } = props;

	return (
		<div className="sub-item">
			<div className="content-col">
				<div>
					<img
						src={img || DEFAULT_PROFILE_ICON}
						alt={name}
						className="icon_md__round"
						onError={(ev) => {
							ev.target.src = DEFAULT_PROFILE_ICON;
						}}
					/>
				</div>
				<div className="text-col">
					<div className="text-2 wrap-text">{name}</div>
					<div className="text-1 c_base60  wrap-text">{subRowText}</div>
				</div>
			</div>
			{btn && btn}
		</div>
	);
}