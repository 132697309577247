import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import App from '../App';
import Login from 'components/login/Login';
import Withdraw from 'components/withdraw/Withdraw';
import WithdrawReq from 'components/withdraw/WithdrawReq';
import Search from 'components/search/Search';
import Faq from 'components/faq/Faq';
import User from 'components/userPage/User';
import News from 'components/news/News';
import Categories from 'components/categories/Categories';
import Groups from 'components/groups/Groups';
import Tags from 'components/categories/Tags';
import Placeholder from 'components/categories/Placeholder';
import Expert from 'components/expert/Experts';
import Forum from 'components/forum/Forum';
import Topic from 'components/forum/Topic';
import Funds from 'components/funds/Funds';
import FundsStats from 'components/funds/FundsStats';
import Transactions from 'components/transactions/Transactions';
import Transaction from 'components/transactions/Transaction';
import Promocodes from 'components/promocodes/Promocodes';
import CreatePayment from 'components/create payment/CreatePayment';
import Orders from "components/orders/Orders";
import ProductsUpdate from "components/products update/ProductsUpdate";
import ChangeLog from 'components/change log/ChangeLog';
import Collaboration from 'components/collaboration/Collaboration';
import CreateStream from 'components/stream/CreateStream';
import Streams from 'components/stream/Streams';
import BindStream from 'components/stream/BindStream';

const Root = (props) => (
		<Router {...props}>
			<div>
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/" component={App} />
				</Switch>

				<Switch>
                    <Route path="/withdraw/:id" component={WithdrawReq} />
					<Route path="/withdraw" component={Withdraw} />
					<Route path="/faq" component={Faq} />
					<Route path="/search" component={Search} />
					<Route path="/user/:id" render={(props) => (
						<User key={props.match.params.id} {...props} />)
					} />
					<Route path="/news" component={News} />
					<Route path="/experts" component={Expert} />
					<Route path="/upgrade-to-expert/:id" component={Expert} />
					<Route path="/update-expert-info/:id" component={Expert} />
					<Route path="/categories/:tagid" component={Tags} />
					<Route path="/placeholder/:tagid" component={Placeholder} />
					<Route path="/categories" component={Categories} />
                    <Route path="/groups" component={Groups} />
					<Route exact path="/forum" component={Forum} />
					<Route path="/forum/topic" component={Topic} />
					<Route path="/funds" component={Funds} />
                    <Route path="/funds-stats" component={FundsStats} />
                    <Route path="/transactions" component={Transactions} />
                    <Route path="/transaction/:id" component={Transaction} />
                    <Route path="/promocodes" component={Promocodes} />
                    <Route path="/create-payment" component={CreatePayment} />
                    <Route path="/orders" component={Orders} />
                    <Route path="/products-update" component={ProductsUpdate} />
                    <Route path="/change-log" component={ChangeLog} />
                    <Route path="/сollaboration" component={Collaboration} />
                    <Route path="/streams" component={Streams} />
                    <Route path="/create-stream" component={CreateStream} />
                    <Route path="/bind-stream" component={BindStream} />
				</Switch>
			</div>
		</Router>
);

export default Root;