import React, { Component } from 'react';
import './css/promocodes.css';
import APIrequest from 'APIcalls/APIrequest';

import PromocodeUsersModal from './PromocodeUsersModal';
import PromocodeTile from './PromocodeTile';
import AddPromocodeTile from './AddPromocodeTile';

export default class Promocodes extends Component {

    constructor(props) {
        super(props);
        this.getAllPromocodes = this.getAllPromocodes.bind(this);

        this.state = {
            promocodes: [],
            modalIsOpen: false,
            users: [],
        }

        this.getAllPromocodes();
    }

    async getAllPromocodes() {
        try {
            const response = await APIrequest.get_promo_codes();
            this.setState({
                promocodes: response.data
            })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { modalIsOpen, users } = this.state;

        return (
			<div className="promocodes">
				<div className="grid">
					<AddPromocodeTile addedCallback={this.getAllPromocodes} />
					{this.state.promocodes.map(promocode => {
						return <PromocodeTile
                            key={promocode.id} 
                            promocode={promocode} 
                            deletedCallback={this.getAllPromocodes}
                            usersCallback={ users => this.setState({ users: users, modalIsOpen: true })}
                        />;
					})}
                    <PromocodeUsersModal
                        open={modalIsOpen} 
                        onClose={() => this.setState({ modalIsOpen: false })} 
                        users={users}
                    />
				</div>
			</div>
		);
    }
}
