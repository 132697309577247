export default function compareArrays(arr1,arr2){
    if(!arr1  || !arr2) return
    if(!(Array.isArray(arr1) && Array.isArray(arr2))) return
    let result;
    arr1.forEach((e1,i)=>arr2.forEach(e2=>{
            if(e1.length > 1 && e2.length){
                result = compareArrays(e1,e2);
            }else if(e1 !== e2 ){
                result = false
            }else{
                result = true
            }
        })
    )
    return result
}