import axios from 'axios';
import logoutUser from 'functions/logout-user';
let ServerKey = 'ServerKey QM7d3gb7vZBateWGzNtZhwVR2BgedOzPyR0I9KBw_3KfdT9c7';
let FaqServer = 'https://main.pphndc.com:8002';
// let FaqServer = 'http://localhost:8002';
let RequestedDomain = process.env.REACT_APP_API_DOMAIN;

// test
// let RequestedDomain = 'https://test.bevailable.com:7280/api';
// staging
// let RequestedDomain = 'https://test.bevailable.com:7290/api';
// prod 
// let RequestedDomain = 'https://lb.pphndc.com:7290/api';

function getAtoken() {
    let aToken = 'Bearer ' + JSON.parse(localStorage.getItem('aToken'));
    return aToken
}

function storeAToken(response) {
    try {
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
    } catch (error) {
        // console.log('error saving bearer from response', error);
    }
}

function handleError(error) {
	if (error.response.data.status === 403) {
        alert('Your session have been expired. Please relogin')
        logoutUser();
    }
}

let API = {
	post: async (path, payload = {}) => {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
			data: payload,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		storeAToken(response);
		return response;
	},

	postSK: async (path, payload = {}, config = { storeBearer: true }) => {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: ServerKey,
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
			data: payload,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		if (config.storeBearer) storeAToken(response);
		return response;
	},

	get: async (path) => {
		const response = await axios({
			method: "get",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		storeAToken(response);
		return response;
	},

	multipart: async (path, payload = {}, uploadPercent) => {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "multipart/form-data",
			},
			url: `${RequestedDomain}/${path}`,
			data: payload,
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				uploadPercent(percentCompleted);
			},
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		storeAToken(response);
		return response;
	},

	delete: async (path) => {
		const response = await axios({
			method: "delete",
			headers: { Authorization: getAtoken() },
			url: `${RequestedDomain}/${path}`,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		return response;
	},

	// to do: add general req method
};

const APIrequest = {

    get_url_token: function (url) {
        let splitedURLarray = url.split('/');
        return splitedURLarray[splitedURLarray.length - 1]
    },

    login_with_password: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/login-with-password',
            data: data
        })
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    login_admin: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/login-admin',
            data: data
        }).catch(function(error) {
			throw error;
		});
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    reset_password_admin: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/reset-password-admin',
            data: data
        }).catch(function(error) {
			throw error;
		});
        try {
            localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        } catch (error) { }
        return response
    },

    check_admin: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/check-admin',
            data: data
        })
        try {
            localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        } catch (error) { }
        return response
    },

    get_withdraw_requests: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            data: data,
            url: RequestedDomain + '/get-withdraw-requests/',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    get_bank_details: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-bank-details/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_upgrade_requests_count: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-upgrade-requests-count/',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_product_change_requests_count: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-products-review-count',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_withdraw_requests_count: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-withdraw-requests-count/',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_product_by_id: async function (id) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-product-by-id/' + id,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_change_log: async function () {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-change-log/',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    save_club: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/club/save-club',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_collaboration_requests: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-collaboration-requests',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    confirm_collaboration_request: async function (idRequest, confirm, data = {}) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/confirm-collaboration-request/${idRequest}/${confirm}`,
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_collaboration_requests_count: async function (idRequest, confirm, data = {}) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/get-collaboration-requests-count`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    approve_withdraw: async function (idTransaction, approve) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/approve-withdraw/' + idTransaction + '/' + approve,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response.data
    },

    get_transaction_stats: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-transaction-stats',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    get_charity_stats: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-charity-stats',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    find_users_by_group: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/find-users-by-group',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    change_user_order: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/change-user-order',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                alert('Your session have been expired. Please relogin')
                logoutUser();
            }
        });
        return response
    },

    generate_faq_json: async function (idTransaction, approve) {
        let response = await axios({
            method: 'get',
            url: FaqServer + '/generate-faq-json',
        }).catch(function (error) {
            console.log(error)
        });
        return response.data
    },

    get_groups_by_ids: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-groups-by-ids/en',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_products_review: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-products-review',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    // save_product_change_request: async function (data) {
    //     let response = await axios({
    //         method: 'post',
    //         headers: { 'Authorization': getAtoken() },
    //         url: RequestedDomain + '/save-product-change-request',
    //         data: data
    //     }).catch(function (error) {
    //         if (error.response.data.status === 403) {
    //             logoutUser();
    //         }
    //     });
    //     return response
    // },
    save_product_review_result: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/save-product-review-result',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_charity_funds_by_ids: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-charity-funds-by-ids/en',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    find_users_by_name: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/find-users-by-name/',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    get_users_by_ids: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-users-by-ids',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    link_user_to_agent: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/link-user-to-agent',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    find_users_by_query: async function (data, onlyExperts) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/find-users-by-query/' + onlyExperts,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    set_score: async function (data, onlyExperts) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/set-score',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    add_group: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/add-group',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_product: async function (idUser, data) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/delete-product/${idUser}`,
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_group: async function (data) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/delete-group',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    history_log_by_id: async function (idLog) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/history-log-by-id/' + idLog,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_streams: async function () {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-streams',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    bind_broadcast: async function (id, watchToken, userId) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/bind-broadcast/${id}/${watchToken}/${userId}`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_stream: async function (id) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/delete-stream/${id}`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
            throw error;
        });
        return response
    },

    get_scores: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-scores/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    hide_score: async function (idScore) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/hide-score/' + idScore,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_groups: async function () {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-groups/en/true',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_groups_full: async function () {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-groups',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_promo_codes: async function () {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-promo-codes',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_user_full_by_id: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-user-full-by-id/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    get_full_info: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-full-info/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response.data
    },

    get_logs_for_device: async function (deviceId) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-logs-for-device/' + deviceId,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response.data
    },

    remove_user_admin: async function (idUser) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/remove-user-admin/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    forum_remove_post: async function (postType, idPost) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/forum/remove/' + postType + '/' + idPost,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_user_admin: async function (idUser) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/delete-user-admin/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_promo_code: async function (data) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/delete-promo-code/',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    hide_complete_product: async function (idResponse) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/hide-complete-product/' + idResponse,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    hide_user_from_catalog: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/hide-user-from-catalog/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    refund_payment: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/refund-payment',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_complete_products_short: async function (data, idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-complete-products-short/' + idUser,
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    create_promo_code: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/create-promo-code',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    create_payment: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/create-payment',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    transaction_log: async function (idUser, data, sortType) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/transaction-log/${idUser}?sortType=${sortType}&order=TRANSACTION_START_DATE`,
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    get_executed_by: async function (idLog) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/get-executed-by/${idLog}`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    confirm_user: async function (idUser, isConfirmed) {
        let data = {
            "result": isConfirmed
        }
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/confirm-user/' + idUser,
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    block_user: async function (idUser, block) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/block-user/' + idUser + '/' + block,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    update_user: async function (data, idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/update-user/' + idUser,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    create_user: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/create-user',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
            throw error;
        });
        storeAToken(response);
        return response
    },

    forum_get_user_posts: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/forum/get-user-posts/' + idUser + '/en',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },


    forum_get_topics: async function (data, feed) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/forum/get-topics/' + feed + '/en',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    forum_get_answers_full: async function (idTopic) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/forum/get-answers-full/' + idTopic + '/en',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    clear_user_fields: async function (data, idUser) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/clear-user-fields/' + idUser,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    sell_video_greeting: async function (data, idUser, uploadPercent) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + `/sell-video-greeting/${idUser}`,
            data: data,
            onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				uploadPercent(percentCompleted);
			},
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    sell_autograph: async function (data, idUser, uploadPercent) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + `/sell-autograph/${idUser}`,
            data: data,
            onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				uploadPercent(percentCompleted);
			},
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    sell_physical: async function (data, idUser, uploadPercent) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + `/sell-physical/${idUser}`,
            data: data,
            onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				uploadPercent(percentCompleted);
			},
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    upload_profile_video: async function (data, idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + `/upload-profile-video/${idUser}`,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    upload_product_video: async function (data, idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + `/upload-video-content`,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    send_code: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/send-code`,
            data: data
        }).catch(function (error) {
            throw error;
        });
        return response
    },

    add_broadcast: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/add-broadcast`,
            data: data
        }).catch(function (error) {
            throw error;
        });
        return response
    },

    profile_image: async function (data, idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + '/profile-image/' + idUser,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    upload_some_image: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + '/upload-some-image',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    upload_ad_video: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + '/upload-ad-video',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    send_news: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/send-news',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_report: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/get-report',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    save_news: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/save-news',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_news: async function (data) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/get-news/true',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    add_category: async function (data, idGroup) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/add-category/' + idGroup,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    add_tag: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/add-tag',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    add_placeholder: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/add-placeholder',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_categories: async function () {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/get-categories',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_categories_by_group_id: async function (idGroup) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/get-categories-by-group-id/' + idGroup,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_forum_categories: async function (locale) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/forum/get-forum-categories/' + locale,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_category: async function (data) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/delete-category',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_tag: async function (data) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/delete-tag',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_placeholder: async function (data) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken(), 'Content-Type': 'application/json' },
            url: RequestedDomain + '/delete-placeholder',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    remove_device_admin: async function (idDevice) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/remove-device-admin/' + idDevice,
        })
        return response
    },

    get_upgrade_requests: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-upgrade-requests',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_change_requests: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-change-requests',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_tags_by_categories: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-tags-by-categories',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_tags_by_ids: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-tags-by-ids/en',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_placeholders_by_categories: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-placeholders-by-categories',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    confirm_upgrade: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/confirm-upgrade',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    save_change_request: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/save-change-request',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    verify_corporate_email_manual: async function (idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/verify-corporate-email-manual/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_user_products: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-user-products/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    upload_product_cover: async function (data, productType, idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken(), 'content-type': 'multipart/form-data' },
            url: RequestedDomain + '/upload-product-cover/' + productType + '/' + idUser,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    save_product: async function (data, idUser) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/save-product/' + idUser,
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    get_charity_funds: async function (data) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-charity-funds',
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    can_create_auction: async function (idUser, canCreate) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/can-create-auction/${idUser}/${canCreate}
            `,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    add_charity_fund: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/add-charity-fund',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    admin_history_log: async function (data, sortType) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/admin-history-log?sortType=${sortType}`,
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    make_agent: async function (idUser, isAgent) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/make-agent/${idUser}/${isAgent}`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    delete_charity_fund: async function (data) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/delete-charity-fund',
            data: data,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));
        return response
    },

    save_subscription: async function (data, idUser) {
        let response = await axios({
            method: 'post',
            data: data,
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/save-subscription?idUser=${idUser}`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    remove_subscription: async function (idSubscription, idUser) {
        let response = await axios({
            method: 'delete',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/remove-subscription/${idSubscription}?idUser=${idUser}`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    community_enabled: async function (idUser, enabled) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + `/community-enabled/${idUser}/${enabled}`,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },
}

export { API };
export default APIrequest