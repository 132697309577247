import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

export default class ShareLinks extends Component {

    render() {
        const { startToken, watchToken } = this.props;

        const startStreamUrl = `${process.env.REACT_APP_STREAM_DOMAIN}/start-stream/${startToken}`;
        const watchStreamUrl = `${process.env.REACT_APP_STREAM_DOMAIN}/${watchToken}`;

        return (
            <div className="stream-content">
                <div className="title-4">Stream successfully created. Share details below</div>
                <br/>
                { startToken && <>
                    <div className="title-5">URL to start stream</div>
                    <div className="text-1">{startStreamUrl}</div>
                    <br/>
                </>}
                <div className="title-5">URL to watch stream</div>
                <div className="text-1">{watchStreamUrl}</div>
                <br/>
                <Link to="/streams">
                    <Button 
                        className="stream-create-btn"
                        variant="outlined" 
                        color="primary" 
                        onClick={() => {}}
                    >
                        Go to streams list
                    </Button>
                </Link>
            </div>
        )
    }
}
