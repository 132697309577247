import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/news.css';

// components
import UploadPhoto from "components/upload photo/UploadPhoto";
import NewsWithNotification from "./CreateNewsWithNotification";

// libs
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import 'react-image-crop/dist/ReactCrop.css';

// imgs 
import DEFAULT_IMG_COVER from 'imgs/cover_autograph@2x.png';

function truncate(string, maxLength) {
    if (string.length > maxLength)
        return string.substring(0, maxLength) + '...';
    else
        return string;
};

const newsStatus = [
    {
        value: 'ENABLED',
        label: 'Enable',
    },
    {
        value: 'DISABLED',
        label: 'Disable',
    },
    {
        value: 'DELETED',
        label: 'Delete',
    },
];

const newsType = [
    {
        value: 'NEW_PERSON',
        label: 'New person',
    },
    {
        value: 'NEW_CATEGORY',
        label: 'New category',
    },
    {
        value: 'UPDATES',
        label: 'Updates',
    },
];

const newsLanguages = [
    'en',
    'ru',
    'uk'
]

class News extends Component {

    constructor(props) {
        super(props);
        this.editNews = this.editNews.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.editNewsReq = this.editNewsReq.bind(this);

        this.state = {
            about: "",
            expressAvailable: false,
            expressRate: 0,
            imageLink: null,
            videoLink: '',
            videoType: null,
            newsStatus: "ENABLED",
            productType: "VIDEO_GREETING",
            standardRate: 0,
            idProduct: '',
            userID: this.props.userID ? this.props.userID : '',
            operationType: this.props.operationType,
            news: [],
            mode: 'viewNews',
            editCoverMode: false,
            title: '',
            text: '',
            newsLang: 'en',
            files: [
                {
                    source: "index.html",
                    options: {
                        type: "local"
                    }
                }
            ]
        };

        this.getNews();
    }

    handleChange = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;
        this.setState({
            [name]: value,
        });
    };

    handleUserLang = (userLang) => {
        this.setState({ languages: userLang })
    }

    handleChangeCoverMode(event) {
        this.setState({
            editCoverMode: event
        })
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    async getNews () {
        try {
            let response = await APIrequest.get_news();
            this.setState({
                news: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    async editNewsReq() {
        let data = {
            entityStatus: this.state.entityStatus,
            type: this.state.newsType,
            title: this.state.title,
            text: this.state.text,
            languages: [this.state.newsLang],
        };
        if (this.state.idNews && this.state.idNews.length > 0)
            data.idNews = this.state.idNews;
        if (this.state.imageLink)
            data.imageLink = this.state.imageLink

        let errors = false;
        if (this.state.videoLink && this.state.videoLink.length > 0) {
            data.video = {};
            const youtubeID = extractVideoID(this.state.videoLink);
            if (youtubeID) {
                data.video.link = youtubeID;
                data.video.type = 'YOUTUBE';
            }
            else if (this.state.videoLink.includes("https://s3.eu-west-2.amazonaws.com/payphone-storage/ad-video/")) {
                data.video.link = this.state.videoLink;
                data.video.type = 'DIRECT';
            }
            else {
                errors = true;
                alert('Video link should be from youtube or uploaded to "s3.eu-west-2.amazonaws.com/payphone-storage"');
            }

            function extractVideoID(url) {
                var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                var match = url.match(regExp);
                if (match && match[7].length === 11) {
                    return match[7];
                } else {
                    return false
                }
            }
        }

        if (!errors) {
            try {
                await APIrequest.save_news(data);
                alert("News successful edited");
                this.setState({
                    mode: 'viewNews'
                });
                this.getNews();
            } catch (error) {
                console.log(error)
                alert("Network error. Try again later")
            }
        }
    }

    async uploadImg(formData) {
        formData.append('info', JSON.stringify({
            type: 'NEWS_COVER'
        }));
        try {
            const response = await APIrequest.upload_some_image(formData);
            this.setState({
                imageLink: response.data.result,
                editCoverMode: false
            });
            alert("img uploaded! plz, finish updating product info and press submit");
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    editNews(productNews) {
        this.setState({
            mode: 'editNews',
        });
        if (productNews) {
            this.setState({
                imageLink: productNews.imageLink,
                entityStatus: productNews.entityStatus,
                newsType: productNews.type,
                title: productNews.title,
                text: productNews.text,
                idNews: productNews.idNews,
                newsLang: productNews.languages[0]
            })

            if (productNews.video) {
                this.setState({
                    videoLink: productNews.video.link,
                    videoType: productNews.video.type,
                })
            }
        }
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 50000000) {
                alert("Max video size is 50 mb!")
            }
            else {
                const data = new FormData();
                data.append('file', e.target.files[0]);
                data.append('filename', 'video-ad');
                this.uploadVideo(data);
            }
        }
    };

    async uploadVideo(data) {
        try {
            const response = await APIrequest.upload_ad_video(data);
            this.setState({
                videoLink: response.data.result,
                videoType: 'DIRECT'
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {

        return (
            <div className="news">
                {this.state.mode === "newWithNotification" ? 
                    <NewsWithNotification />
                    :
                    null
                }
                {this.state.mode === "editNews" ? 
                    <div className="news-container">
                        <div className="news-content flex-col">
                            <span className="news-text">Create fresh news</span>
                            <TextField
                                select
                                label="Select news type"
                                value={this.state.newsType}
                                onChange={this.handleChange('newsType')}
                                margin="normal"
                                variant="outlined"
                            >
                                {newsType.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Select news language"
                                value={this.state.newsLang}
                                onChange={this.handleChange('newsLang')}
                                margin="normal"
                                variant="outlined"
                            >
                                {newsLanguages.map(lang => (
                                    <MenuItem key={lang} value={lang}>
                                        {lang}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Title"
                                value={this.state.title}
                                onChange={this.handleChange("title", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Text"
                                value={this.state.text}
                                onChange={this.handleChange("text", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Paste link to video"
                                value={this.state.videoLink}
                                onChange={this.handleChange("videoLink", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <div className="upload-video-text">Or upload video file</div>
                            <div className="upload-photo-container">
                                <div className="file-drop-area">
                                    <Button className="fake-btn" variant="contained" color="primary">
                                        Choose files
                                    </Button>
                                    <span className="file-msg">or drag and drop files here</span>
                                    <input className="file-input" type="file" onChange={this.onSelectFile} />
                                </div>
                            </div>
                            <TextField
                                select
                                label="Select product status"
                                value={this.state.entityStatus}
                                onChange={this.handleChange('entityStatus')}
                                margin="normal"
                                variant="outlined"
                            >
                                {newsStatus.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {this.state.editCoverMode ?
                                <UploadPhoto photoIsUploadedCallbak={this.uploadImg}/>
                                :
                                <div className="flex-col">
                                    <div className="product-cover-edit" onClick={this.handleChangeCoverMode.bind(this, true)}>
                                        <div className="product-cover-edit-label">Edit</div>
                                        <img src={this.state.imageLink ? this.state.imageLink : DEFAULT_IMG_COVER} alt="Product cover"/>
                                    </div>
                                    <Button className="register-new-user-btn" variant="contained" color="primary" onClick={this.editNewsReq}>
                                        Submit
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    null
                }
                {this.state.mode === "viewNews" ? 
                    <div className="news-container">
                        <div className="news-container-header">
                            <span className="news-text">Set up news</span>
                            <Button className="register-new-user-btn" variant="contained" color="primary" onClick={() => { this.setState({ mode: "newWithNotification"})}}>
                                Send news with notification
                            </Button>
                        </div>
                        {this.state.news.map(a => {
                            return <Card className="news-card">
                                <CardActionArea className="news-card-AA" onClick={this.editNews.bind(this, a)}>
                                    <CardMedia
                                        component="img"
                                        alt="Contemplative Reptile"
                                        height="280"
                                        image={a.imageLink ? a.imageLink : DEFAULT_IMG_COVER}
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {a.type}
                                        </Typography>
                                        <Typography component="p">
                                            Title: {a.title}
                                        </Typography>
                                        <Typography component="p">
                                            Text: {truncate(a.text, 120)}
                                        </Typography>
                                        <Typography component="p">
                                            News ID: {a.idNews}
                                        </Typography>
                                        <Typography component="p">
                                            Status: {a.entityStatus}
                                        </Typography>
                                        <Typography component="p">
                                            Created date: {a.createdDate}
                                        </Typography>
                                        <Typography component="p">
                                            Last modified date: {a.lastModifiedDate}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        })}
                        <Card className="news-card">
                            <CardActionArea className="news-card-AA" onClick={this.editNews.bind(this)}>
                                <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    height="280"
                                    image={DEFAULT_IMG_COVER}
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Create new
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    : null
                }
            </div>
        )
    }
}

export default News;