import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/categories.css';

// libs
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import CardActionArea from '@material-ui/core/CardActionArea';

class Categories extends Component {

    constructor(props) {
        super(props);
        this.addCategory = this.addCategory.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.state = {
            categories: [],
            selectedCategory: null
        };

        const query = new URLSearchParams(this.props.location.search);
        this.idGroup = query.get("idGroup");
        this.getCategories(this.idGroup);
    }

    handleChange(name, value) {
        this.setState({
            [name]: value,
        });
    };

    handleChangeObjState = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;

        let newState = Object.assign({}, this.state[name[0]]);
        if (name.length === 2) {
            newState[name[1]] = value;
        }
        else if (name.length === 3) {
            newState[name[1]][name[2]] = value;
        }
        this.setState({
            selectedCategory: newState
        })
    };

    async getCategories() {
        try {
            const response = await APIrequest.get_categories_by_group_id(this.idGroup);
            this.setState({
                categories: response.data
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async deleteCategory(idCategory, name) {
        let data = {
            idCategory: idCategory,
            name: name,
        }
        try {
            await APIrequest.delete_category(data);
            alert("Category deleted!");
            this.setState({
                selectedCategory: null
            });
            this.getCategories();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async addCategory() {
        let data = this.state.selectedCategory;
        try {
            await APIrequest.add_category(data, this.idGroup);
            alert("Category changed!");
            this.setState({
                selectedCategory: null
            });
            this.getCategories();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {

        return (
            <div className="categories">
                <div className="page-header-container">
                    <Typography variant="h4" component="h2">
                        Categories
                    </Typography>
                </div>
                <div className="page-content">
                    {this.state.selectedCategory ? 
                        <div className="edit-category">
                            <TextField
                                label="Text"
                                value={this.state.selectedCategory.name}
                                onChange={this.handleChangeObjState(["selectedCategory", "name"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Order"
                                type="number"
                                value={this.state.selectedCategory.order}
                                onChange={this.handleChangeObjState(["selectedCategory", "order"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="En"
                                value={this.state.selectedCategory.localizationMap.en}
                                onChange={this.handleChangeObjState(["selectedCategory", "localizationMap", "en"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Ru"
                                value={this.state.selectedCategory.localizationMap.ru}
                                onChange={this.handleChangeObjState(["selectedCategory", "localizationMap", "ru"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Uk"
                                value={this.state.selectedCategory.localizationMap.uk}
                                onChange={this.handleChangeObjState(["selectedCategory", "localizationMap", "uk"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <Button className="edit-category-btn" variant="contained" color="primary" onClick={this.addCategory}>save</Button>
                            <Button className="edit-category-btn" variant="contained" color="secondary" onClick={this.deleteCategory.bind(this, this.state.selectedCategory.idCategory, this.state.selectedCategory.name)}>delete</Button>
                        </div>
                    :
                    <div className="categories-cards">
                        {this.state.categories.map( a => {
                            return <Card className="categories-card">
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {a.name}
                                    </Typography>
                                    <br/>
                                    <Typography color="textSecondary">
                                        Order: {a.order}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        ID: {a.idCategory}
                                    </Typography>
                                </CardContent>
                                <CardActions className="flex-col">
                                    <Button fullWidth size="small" onClick={this.handleChange.bind(this, "selectedCategory", a)}>Edit</Button>
                                    <Button fullWidth size="small" onClick={() => this.props.history.push('/categories/' + a.name + '?id=' + a.idCategory)}>Tags</Button>
                                    <Button fullWidth size="small" onClick={() => this.props.history.push('/placeholder/' + a.name + '?id=' + a.idCategory)}>Placeholder</Button>
                                </CardActions>
                            </Card>
                        })}
                        <Card className="categories-card">
                            <CardActionArea className="add-new-category-CAA" onClick={this.handleChange.bind(this, "selectedCategory", { name: '', localizationMap: { en: '', ru: '', uk: '' } })}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    Add new category
                                </Typography>
                            </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default Categories;