function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
	const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
	const width = (srcWidth * ratio).toFixed();
	const height = (srcHeight * ratio).toFixed();
	return { width: width, height: height };
}

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}

const resizeImage = (file, options, callback) => {
	var reader = new FileReader();
	reader.onload = function (e) {
		var image = new Image();
		image.src = e.target.result;
		image.onload = function () {
			const { height: originalHeight, width: originalWidth } = this;
			const { maxHeight, maxWidth, max } = options;

			const { width, height } = calculateAspectRatioFit(
				originalWidth,
				originalHeight,
				maxWidth || max,
				maxHeight || max
			);

			const canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;

			const ctx = canvas.getContext("2d");

			ctx.drawImage(image, 0, 0, width, height);

			const dataURL = canvas.toDataURL(file.type, 0.8);
			const convertedFile = dataURLtoFile(dataURL, file.name);

			URL.revokeObjectURL(image.src);
			callback(convertedFile);
		};
	};

	reader.readAsDataURL(file);
};

export default resizeImage;
