import React, { useState } from "react";
import { md5, resizeImage } from "functions";

// libs
import { Button, CircularProgress } from "@material-ui/core";
import Snackbar from "components/common/snackbar/Snackbar";
import { safeKey } from "../../functions";

function ExecuteBtn(props) {
	const {
		extensions,
		maxFileSize,
		operationType,
		fileType,
		setAnswerMsg,
		product,
		modal = {},
		answerLimits,
	} = props;
	const [isLoading, setIsLoading] = useState(false);
    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
    const [fileName, setFileName] = useState(null);
	const [snackbarText, setSnackbarText] = useState("");

	const valideFileSize = (file) => {
		return file.size <= maxFileSize.b;
	};

	const showSendScreen = (file, md5) => {
		const formData = new FormData();
        formData.append(fileType, file, file.name);
        setFileName(file.name);
		setAnswerMsg({
			id: product.idTransaction,
			message:
				modal.message ||
				"Product.Autograph.Create.TextField.Comment.Placeholder",
			title: modal.title || file.name,
			type: operationType,
			limits: answerLimits || { min: 0 },
			answer: {
				file: formData,
				md5: md5,
			},
		});
		setIsLoading(false);
	};

	const getMd5 = (file) => {
		md5(file, "file").then(
			(md5) => showSendScreen(file, md5),
			(error) => showError("Upload Media File Error")
		);
	};

	const filePicked = async (event) => {
		try {
			setIsLoading(true);
			const file = event.target.files[0];
			// todo: refactor to .then() syntax
			if (!valideFileSize(file)) {
				showError("File Size too big");
				return false;
			}

			if (fileType === "image") {
				resizeImage(file, { max: 2000 }, (resizedFile) => {
					getMd5(resizedFile);
				});
			} else getMd5(file);
		} catch (error) {
			showError("Upload Media File Error");
		}
	};

	const resetInputValue = (event) => {
		// allow to pick same file multiple times
		event.target.value = "";
	};

	const showError = (errorText) => {
		setIsLoading(false);
		setSnackbarText(errorText);
		setSnackbarIsOpen(true);
	};

	return (
		<>
			{isLoading ? (
				<button className="ppc-btn_fw ppc-btn_trans mb_8">
					<CircularProgress className="cir-prog_white" size={24} />
				</button>
			) : (
                <Button variant="contained" color="primary" style={{ padding: '0px' }}>
                    <label style={{ width: "100%", padding: "6px 16px" }}>
                        <input
                            hidden
                            type="file"
                            accept={extensions}
                            onChange={filePicked}
                            onClick={resetInputValue}
                        ></input>
                        {fileName || "Pick file"}
                    </label>
                </Button>
			)}
			<Snackbar
				isOpen={snackbarIsOpen}
				handleClose={() => setSnackbarIsOpen(false)}
				text={snackbarText}
				autoHideDuration={5000}
			/>
		</>
	);
}

export default function ExecuteVGABtn(props) {
	const { productType } = safeKey(() => props.product.object.product) || {};
    const { type, product, btnText } = props;

	const displayCond = (() => {
		return product.status === "IN_PROGRESS" && product.operationType === "PRODUCT_REQUEST"
	})();

	if (!displayCond) return false;

	if (type === "send-response") {
		const maxFileSize = {
			b: Math.pow(1024, 3),
			hr: "1 GB",
		};
		return (
			<ExecuteBtn
				maxFileSize={maxFileSize}
				extensions="video/*"
				fileType="video"
				operationType="send-reaction"
				btnText={"Execute"}
				modal={{
					message: "Chat.Reaction.Title",
					title: "Record.Reaction.Publish.Content",
				}}
				answerLimits={{ min: 0, disableInput: true }}
				{...props}
			/>
		);
	}

	switch (productType) {
		case "VIDEO_GREETING": {
			const maxFileSize = {
				b: Math.pow(1024, 3),
				hr: "1 GB",
			};
			return (
				<ExecuteBtn
					maxFileSize={maxFileSize}
					extensions="video/*"
					fileType="video"
					operationType="execute-vg-order"
					btnText={btnText || "Product.ChooseVideo.Button"}
					{...props}
				/>
			);
		}
		case "AUTOGRAPH": {
			const maxFileSize = {
				b: Math.pow(1024, 2) * 150,
				hr: "150 Mb",
			};
			return (
				<ExecuteBtn
					maxFileSize={maxFileSize}
					extensions="image/*, .jpeg, .jpg, .png"
					fileType="image"
					operationType="execute-autograph-order"
					btnText={btnText || "Common.ChoosePhoto"}
					{...props}
				/>
			);
		}
		default:
			return null;
	}
}
