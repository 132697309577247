import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/createPayment.css';

// libs
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';

export default class CreatePayment extends Component {

    state = {
        type: 'recharge',
        idUser: '',
        amount: 0,
    };

    handleChange = key => event => {
		this.setState({ [key]: event.target.value });
	};

    handleChangeRadio = event => {
        this.setState({ type: event.target.value });
    };

    async createPayment() {
        try {
            const data = {
                type: this.state.type,
                idUser: this.state.idUser,
                amount: this.state.amount,
            }
            await APIrequest.create_payment(data);
            alert("Success!")
        } catch (error) {
            console.log(error);
            alert("Error! Request failed")
        }
    }

    render() {
        return (
            <div className="create-payment">
                <div className="content">
                    <div className="title-5 mb_16">Create Payment</div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup
                            aria-label="type"
                            name="type"
                            value={this.state.type}
                            onChange={this.handleChangeRadio}
                        >
                            <FormControlLabel value="RECHARGE" control={<Radio />} label="RECHARGE" />
                            <FormControlLabel value="WITHDRAW" control={<Radio />} label="WITHDRAW" />
                        </RadioGroup>
                    </FormControl>
                    <TextField
                        label="User ID"
                        onChange={this.handleChange('idUser', 'value')}
                        value={this.state.idUser}
                    />
                    <TextField
                        label="Amount"
                        value={this.state.amount}
                        onChange={this.handleChange("amount", "value")}
                        margin="normal"
                        inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            maxLength: 12
                        }} //eslint-disable-line
                    />
                    <Button variant="contained" color="primary" onClick={() => this.createPayment()}>Apply</Button>
                </div>
            </div>
        )
    }
}
