import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import UniCard from '../UniCard/UniCard';
import { Button } from '@material-ui/core';

export default class CompletedProducts extends Component {

    constructor(props) {
        super(props);
        this.getProducts = this.getProducts.bind(this);
        this.hideProduct = this.hideProduct.bind(this);

        this.state = {
            products: []
        }

        this.getProducts();
    }

    async getProducts(userID) {
        try {
            let data = {
                "page": 0,
                "size": null,
            }
            const response = await APIrequest.get_complete_products_short(data, this.props.userID);
            this.setState({
                products: response.data.items
            })
        } catch (error) {
            console.log(error);
        }
    }

    async hideProduct(idResponse) {
        try {
            await APIrequest.hide_complete_product(idResponse);
            this.getProducts();
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div className="users-scores">
                {this.state.products.map( product => 
                    <div className="completed-products-card users-score">
                        <UniCard obj={product}/>
                        <div className="media-container">
                            {product.productType === "VIDEO_GREETING" && 
                                <video width="380" height="380" controls>
                                    <source src={product.fileURL} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            }
                            {product.productType === "AUTOGRAPH" && 
                                <img src={product.fileURL} alt="error"/>
                            }
                        </div>
                        <Button style={{margin: '16px 16px 40px'}} onClick={() => this.hideProduct(product.idResponse)} variant="contained" color="secondary">
                            Hide
                        </Button>
                    </div>
                )}
            </div>
        )
    }
}