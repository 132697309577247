import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/addNewUser.css';

// components
import UploadPhoto from "components/upload photo/UploadPhoto";
import LangSelect from "./LangSelect";
import MultipleSelect from "./MultipleSelect";

// libs
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import 'react-image-crop/dist/ReactCrop.css';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import currencies from "json/currencies";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countriesObj = countries.getNames("en");
const countiesList = Object.entries(countriesObj).map(([key, value]) => {
	return { code: key, label: value };
});
const currenciesList = Object.entries(currencies).map(([key, value]) => {
	return { code: key, label: value.name };
});

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.createUser = this.createUser.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.handleCangeMultipleField = this.handleCangeMultipleField.bind(this);

        this.state = {
            name: "",
            email: "",
            languages: [],
            aboutMe: "",
            defaultCallRate: 0.5,
            defaultMessageRate: 0.5,
            order: 0,
            groupOrder: 0,
            type: '',
            work: '',
            operationType: this.props.operationType,
            categoriesList: [],
            selectedCategory: [],
            functionalSet: [],
            baseRating: 0,
            imageLink: "",
            nickname: "",
            referral: "",
            allGroups: [],
            mainGroup: null,
            selectedGroupsName: [],
            selectedGroupsIds: [],
            additionalEmails: [],
            phoneNumber: '',
            emailIsValid: true,
            nicknameIsValid: true,
            nameIsValid: true,
            aboutMeIsValid: true,
            defaultCallRateIsValid: true,
            defaultMessageRateIsValid: true,
            country: countiesList.find( country => country.code === "US"),
            currency: currenciesList.find( country => country.code === "USD"),
            charityInfoParticipant: 'false',
            charityInfoPercent: 0,
            charityInfoShowInProfile: 'false',
            charityInfoFunds: [],
            allCharityFunds: [],
            defaultCommissionBigGroup: 0,
            defaultCommissionMediumGroup: 0,
            defaultCommissionSmallGroup: 0,
            agentCommissionBigGroup: 0,
            agentCommissionMediumGroup: 0,
            agentCommissionSmallGroup: 0,
            agentCallCommission: 0,
            agentMessageCommission: 0,
            personalBigPercent: 0,
            personalMediumPercent: 0,
            personalSmallPercent: 0,
        };

        if(this.props.userInfo){
            const { userInfo } = this.props;
            this.state.userID = userInfo.idUser;
            this.state.name = userInfo.name;
            this.state.email = userInfo.email;
            this.state.order = userInfo.order;
            this.state.imageLink = userInfo.imageLink;
            this.state.baseRating = userInfo.baseRating;
            this.state.nickname = userInfo.nickname;
            this.state.referral = userInfo.referral;
            this.state.additionalEmails = userInfo.additionalEmails;
            this.state.phoneNumber = userInfo.phoneNumber;
            this.state.groupOrder = userInfo.groupOrder;
            this.state.country = countiesList.find( country => country.code === userInfo.country);
            this.state.currency = currenciesList.find( country => country.code === userInfo.currency);

            if (userInfo.expertInfo) {
                this.state.languages = userInfo.expertInfo.languages;
                this.state.aboutMe = userInfo.expertInfo.aboutMe;
                this.state.work = userInfo.expertInfo.work;
                this.state.mainGroup = userInfo.expertInfo.mainGroup;
                this.state.selectedGroupsIds = userInfo.expertInfo.group;
                this.state.selectedCategory = userInfo.expertInfo.category;
            }

            if (userInfo.settings) {
                this.state.defaultCallRate = userInfo.settings.defaultCallRate;
                this.state.defaultMessageRate = userInfo.settings.defaultMessageRate;
                this.state.functionalSet = userInfo.settings.functionalSet;
            }

            if (userInfo.charityInfo) {
                this.state.charityInfoParticipant = String(userInfo.charityInfo.participant);
                this.state.charityInfoPercent = userInfo.charityInfo.percent;
                this.state.charityInfoShowInProfile = String(userInfo.charityInfo.showInProfile);
                this.state.charityInfoFunds = userInfo.charityInfo.funds;
            }

            if (userInfo.agentInfo) {
                this.state.defaultCommissionBigGroup = userInfo.agentInfo.defaultCommissionBigGroup;
                this.state.defaultCommissionMediumGroup = userInfo.agentInfo.defaultCommissionMediumGroup;
                this.state.defaultCommissionSmallGroup = userInfo.agentInfo.defaultCommissionSmallGroup;
                this.state.agentCommissionBigGroup = userInfo.settings.agentCommissionBigGroup;
                this.state.agentCommissionMediumGroup = userInfo.settings.agentCommissionMediumGroup;
                this.state.agentCommissionSmallGroup = userInfo.settings.agentCommissionSmallGroup;
                this.state.agentCallCommission = userInfo.settings.agentCallCommission;
                this.state.agentMessageCommission = userInfo.settings.agentMessageCommission;
                this.state.personalBigPercent = userInfo.settings.personalBigPercent;
                this.state.personalMediumPercent = userInfo.settings.personalMediumPercent;
                this.state.personalSmallPercent = userInfo.settings.personalSmallPercent;
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.operationType !== prevProps.operationType) {
            this.setState({
                operationType: this.props.operationType
            })
        }
    }

    componentDidMount() {
        this.getCategories();
        this.getGroups();
        this.getCharityFunds();
    }

    handleChange = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;
        if (name === "charityInfoFunds") value = [value];

        this.setState({
            [name]: value,
        });
    };

    handleChangeAutocomplete(name, value) {
        this.setState({
            [name]: value,
        });
    }

    handleCangeMultipleField = (key, value) => {
        if (key === 'selectedGroupsName') {
            const selectedGroupsIds = value.map( groupName => {
                return this.state.allGroups.find(group => group.name === groupName).idGroup
            })
            this.setState({ [key]: value, selectedGroupsIds: selectedGroupsIds });
        }
        else this.setState({ [key]: value });
    }

    groupIdsToName() {
        if (!this.state.selectedGroupsIds) return null
        const selectedGroupsName = this.state.selectedGroupsIds.map( groupId => {
            return this.state.allGroups.find(group => group.idGroup === groupId).name
        })
        this.setState({ selectedGroupsName: selectedGroupsName });
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    validateNickname(nick) {
        var re = /^[.a-z0-9_-]+$/;
        if (nick.length < 3 || nick.length > 20) return false
        return re.test(nick);
    }

    async createUser() {
        let data = {
            name: this.state.name,
            email: this.state.email,
            baseRating: this.state.baseRating,
            imageLink: this.state.imageLink,
            nickname: this.state.nickname,
            referral: this.state.referral,
            expertInfo: {
                work: this.state.work,
                category: this.state.selectedCategory,
                languages: this.state.languages,
                aboutMe: this.state.aboutMe,
                mainGroup: this.state.mainGroup === 'none' ? null : this.state.mainGroup,
                group: this.state.selectedGroupsIds,
            },
            settings: {
                functionalSet: this.state.functionalSet,
                defaultCallRate: Number(this.state.defaultCallRate),
                defaultMessageRate: Number(this.state.defaultMessageRate),
                agentCommissionBigGroup: Number(this.state.agentCommissionBigGroup),
                agentCommissionMediumGroup: Number(this.state.agentCommissionMediumGroup),
                agentCommissionSmallGroup: Number(this.state.agentCommissionSmallGroup),
                agentCallCommission: Number(this.state.agentCallCommission),
                agentMessageCommission: Number(this.state.agentMessageCommission),
                personalBigPercent: Number(this.state.personalBigPercent),
                personalMediumPercent: Number(this.state.personalMediumPercent),
                personalSmallPercent: Number(this.state.personalSmallPercent),
            },
            charityInfo: {
                participant: this.state.charityInfoParticipant === "true",
                percent: this.state.charityInfoPercent,
                showInProfile: this.state.charityInfoShowInProfile === "true",
                funds: this.state.charityInfoFunds,
            },
            agentInfo: {
                defaultCommissionBigGroup: Number(this.state.defaultCommissionBigGroup),
                defaultCommissionMediumGroup: Number(this.state.defaultCommissionMediumGroup),
                defaultCommissionSmallGroup: Number(this.state.defaultCommissionSmallGroup),
            }
        };
        if (this.state.order !== 0 || this.state.order !== '')
            data.order = this.state.order;

        if (this.state.groupOrder !== 0 || this.state.groupOrder !== '')
            data.groupOrder = this.state.groupOrder; 

        if (this.state.phoneNumber !== 0 || this.state.phoneNumber !== '')
            data.phoneNumber = this.state.phoneNumber;

        if (this.state.additionalEmails && this.state.additionalEmails.length) {
            if (Array.isArray(this.state.additionalEmails)) data.additionalEmails = this.state.additionalEmails;
            else if (typeof this.state.additionalEmails === "string") data.additionalEmails = this.state.additionalEmails.split(',');
        }
        if (this.state.country && this.state.country.code) {
            data.country = this.state.country.code;
        }
        if (this.state.currency && this.state.currency.code) {
            data.currency = this.state.country.code;
        }
        
        let errors = false;

        // validate specific fields
        let validateField = key => {
            if (key === 'email' && !this.validateEmail(data.email)) {
                this.setState({ emailIsValid: false})
                alert("Please, enter valid email");
                errors = true;
            }
            else if (key === 'email')
                this.setState({ emailIsValid: true})
            
            if (key === 'nickname' && !this.validateNickname(data.nickname)) {
                this.setState({ nicknameIsValid: false})
                alert("Please, enter valid nickname. Allowed symbols: a-z , '-', '_' , '.'. Nickname length should be > 2 symbols and < 21");
                errors = true;
            }
            else if (key === 'nickname')
                this.setState({ nicknameIsValid: true})
        };

        const checkField = key => {
            // check if field is required
            if (this.props.requiredFields.indexOf(key) > -1) {
                if ((this.state[key].length === 0)) {
                    this.setState({ [`${key}IsValid`]: false})
                    alert(key + " is required field")
                    errors = true;
                }
                else this.setState({ [`${key}IsValid`]: true});
                validateField(key);
            }
            else if (this.state[key] && this.state[key].length !== 0) {
                validateField(key);
            }
        }

        const checkFields = data => {
            Object.entries(data).forEach(([key, value]) => {
                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    checkFields(value);
                }
                else checkField(key);
            }, this);
        }

        checkFields(data);

        if (!errors) {
            try {
                if (this.props.operationType === "addNewUser") {
                    let response = await APIrequest.create_user(data);
                    this.setState({
                        userID: response.data.idUser,
                        operationType: 'photoUpload'
                    });
                    alert("user is created. plz upload profile pic");
                }
                else if (this.props.operationType === "editUser") {
                    let response = await APIrequest.update_user(data, this.state.userID);
                    if (Number(data.order) === 0 || data.order === '')
                        await APIrequest.clear_user_fields(['order'], this.state.userID);
                    alert("User is updated successful");
                    this.props.userUpdatedCallback();
                    const redirectLink = "/user/" + response.data.idUser;
                    this.props.history.push(redirectLink);
                }

                if (this.state.mainGroup === 'none') this.deleteMainGroup();
            } catch (error) {
                console.log(error)
                if (error.response && error.response.data && error.response.data.message)
                    alert(error.response.data.message)
                else
                    alert("Network error. Try again later")
            }
        }
    }

    async deleteMainGroup() {
        try {
            await APIrequest.clear_user_fields(["expertInfo.mainGroup"], this.state.userID);
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async uploadImg(formData) {
        try {
            const response = await APIrequest.profile_image(formData, this.state.userID);
            alert("img uploaded!");
            this.props.userUpdatedCallback();
            const redirectLink = "/user/" + response.data.idUser;
            this.props.history.push(redirectLink);
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async getCategories() {
        try {
            const response = await APIrequest.get_categories();
            let categoriesList = response.data.map( a => {
                return a.name
            })
            this.setState({
                categoriesList: categoriesList
            })
        } catch (error) {
            console.log(error)
        }
    }

    async getGroups() {
        try {
            const response = await APIrequest.get_groups();
            this.setState({
                allGroups: response.data
            }, () => this.groupIdsToName());
        } catch (error) {
            console.log(error)
        }
    }

    async getCharityFunds() {
        try {
            const response = await APIrequest.get_charity_funds();
            this.setState({
                allCharityFunds: response.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {

        return (
            <div className="add-user-table">
                <div className="user-table-header-container">
                    <span className="user-table-header">
                        {(() => {
                            switch (this.state.operationType) {
                                case "editUser":
                                    return "Edit user info"
                                case "addNewUser":
                                    return "Add new user"
                                case "photoUpload":
                                    return "Upload user profile photo"
                                default:
                                    break;
                            }
                        })()}
                    </span>
                </div>
                <div className="add-new-user">
                    {this.state.operationType === "photoUpload" ? 
                        <UploadPhoto photoIsUploadedCallbak={this.uploadImg} type={process.env.REACT_APP_ADULT_TYPE === 'true' ? "flexibleAspect" : undefined }/>
                        :
                        <div className="add-new-user-form flex-col">
                            <TextField
                                label="Name"
                                value={this.state.name}
                                onChange={this.handleChange("name", "value")}
                                margin="none"
                                variant="outlined"
                                error={!this.state.nameIsValid}
                            />
                            <TextField
                                label="Email"
                                value={this.state.email}
                                onChange={this.handleChange("email", "value")}
                                margin="normal"
                                variant="outlined"
                                error={!this.state.emailIsValid}
                                helperText={this.state.emailIsValid ? '' : 'Invalid email'}
                            />
                            <TextField
                                label="Additional emails"
                                value={String(this.state.additionalEmails)}
                                onChange={this.handleChange("additionalEmails", "value")}
                                margin="normal"
                                variant="outlined"
                                helperText='Seperate by "," without spaces f.e. - "email1@g.com,email2@g.com,email3@g.com"'
                            />
                            <TextField
                                multiline
                                label="About me"
                                value={this.state.aboutMe}
                                onChange={this.handleChange("aboutMe", "value")}
                                margin="normal"
                                variant="outlined"
                                error={!this.state.aboutMeIsValid}
                            />
                            <TextField
                                label="Work"
                                value={this.state.work}
                                onChange={this.handleChange("work", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Profile image link"
                                value={this.state.imageLink}
                                onChange={this.handleChange("imageLink", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Order"
                                value={this.state.order}
                                onChange={this.handleChange("order", "value")}
                                type="number"
                                helperText="Leave this field blank or equal to 0, to delete order value for this user"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Group order"
                                value={this.state.groupOrder}
                                onChange={this.handleChange("groupOrder", "value")}
                                type="number"
                                helperText="Leave this field blank or equal to 0, to delete order value for this user"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Default call rate"
                                value={this.state.defaultCallRate}
                                onChange={this.handleChange("defaultCallRate", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                                error={!this.state.defaultCallRateIsValid}
                            />
                            <TextField
                                label="Default message rate"
                                value={this.state.defaultMessageRate}
                                onChange={this.handleChange("defaultMessageRate", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                                error={!this.state.defaultMessageRateIsValid}
                            />
                            <TextField
                                label="Base rating"
                                value={this.state.baseRating}
                                onChange={this.handleChange("baseRating", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Phone number"
                                value={this.state.phoneNumber}
                                onChange={this.handleChange("phoneNumber", "value")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Nickname"
                                value={this.state.nickname}
                                onChange={this.handleChange("nickname", "value")}
                                margin="normal"
                                variant="outlined"
                                error={!this.state.nicknameIsValid}
                                helperText={this.state.nicknameIsValid ? '' : 'Invalid field'}
                            />
                            <TextField
                                label="Referral"
                                value={this.state.referral}
                                onChange={this.handleChange("referral", "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <Autocomplete
                                options={countiesList}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.label}
                                    </React.Fragment>
                                )}
                                value={this.state.country}
                                onChange={(event, newValue) => {
                                    this.handleChangeAutocomplete('country', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Country"
                                    variant="outlined"
                                    margin="normal"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                                )}
                            />
                            <Autocomplete
                                options={currenciesList}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.label}
                                    </React.Fragment>
                                )}
                                value={this.state.currency}
                                onChange={(event, newValue) => {
                                    this.handleChangeAutocomplete('currency', newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Currency"
                                    variant="outlined"
                                    margin="normal"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                                )}
                            />
                            <LangSelect selectedLangs={this.state.languages} onLangChange={this.handleCangeMultipleField.bind(this, 'languages')}/>
                            {this.state.categoriesList && this.state.categoriesList.length > 0 ? 
                                <MultipleSelect label="Select categories" selected={this.state.selectedCategory} fullList={this.state.categoriesList} onLangChange={this.handleCangeMultipleField.bind(this, 'selectedCategory')} />
                            : null }
                            <InputLabel style={{ marginTop: '16px' }} htmlFor="select-multiple-checkbox">Main group</InputLabel>
                            {this.state.allGroups && this.state.allGroups.length > 0 &&
                                <FormControl>
                                    <Select
                                        value={this.state.mainGroup}
                                        onChange={this.handleChange('mainGroup', 'value')}
                                        input={<Input id="select-multiple-checkbox" />}
                                        renderValue={selected => (
                                            <div>
                                                {   
                                                    this.state.mainGroup === "none" ?
                                                    "None" :
                                                    this.state.allGroups.find( group => group.idGroup === this.state.mainGroup).name
                                                }
                                            </div>
                                        )}
                                    >   
                                        <MenuItem value="none">
                                            <ListItemText primary="None" />
                                        </MenuItem>
                                        {this.state.allGroups.map(group => (
                                            <MenuItem key={group.idGroup} value={group.idGroup}>
                                                <ListItemText primary={group.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            {/* {(this.state.allGroups && this.state.allGroups.length > 0 && this.state.selectedGroupsName && this.state.selectedGroupsName.length > 0) && */}
                                <MultipleSelect 
                                    label="Select groups" 
                                    selected={this.state.selectedGroupsName} 
                                    fullList={this.state.allGroups.map( group => group.name )} 
                                    onLangChange={this.handleCangeMultipleField.bind(this, 'selectedGroupsName')} 
                                />
                            {/* } */}
                            <MultipleSelect label="Select functional set" selected={this.state.functionalSet} fullList={['call', 'message']} onLangChange={this.handleCangeMultipleField.bind(this, 'functionalSet')} />
                            <div className="title-5 mt_16">Charity info</div>
                            <FormControl variant="outlined" margin="normal">
                                <InputLabel>Participant</InputLabel>
                                <Select
                                    value={this.state.charityInfoParticipant}
                                    onChange={this.handleChange('charityInfoParticipant', 'value')}
                                    label="Participant"
                                >
                                <MenuItem value={'true'}>True</MenuItem>
                                <MenuItem value={'false'}>False</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label="Percent"
                                value={this.state.charityInfoPercent}
                                onChange={this.handleChange("charityInfoPercent", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <FormControl variant="outlined" margin="normal">
                                <InputLabel>Show in profile</InputLabel>
                                <Select
                                    value={this.state.charityInfoShowInProfile}
                                    onChange={this.handleChange('charityInfoShowInProfile', 'value')}
                                    label="Show in profile"
                                >
                                <MenuItem value={'true'}>True</MenuItem>
                                <MenuItem value={'false'}>False</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" margin="normal">
                                <InputLabel>Fund</InputLabel>
                                <Select
                                    value={this.state.charityInfoFunds}
                                    onChange={this.handleChange('charityInfoFunds', 'value')}
                                    label="Fund"
                                >
                                    {this.state.allCharityFunds.map( fund => {
                                        return <MenuItem key={fund.idFund} value={fund.idFund}>{fund.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <div className="title-5 mt_16">Agent info</div>
                            <TextField
                                label="defaultCommissionBigGroup"
                                value={this.state.defaultCommissionBigGroup}
                                onChange={this.handleChange("defaultCommissionBigGroup", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="defaultCommissionMediumGroup"
                                value={this.state.defaultCommissionMediumGroup}
                                onChange={this.handleChange("defaultCommissionMediumGroup", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="defaultCommissionSmallGroup"
                                value={this.state.defaultCommissionSmallGroup}
                                onChange={this.handleChange("defaultCommissionSmallGroup", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <div className="title-5 mt_16">Settings</div>
                            <TextField
                                label="agentCommissionBigGroup"
                                value={this.state.agentCommissionBigGroup}
                                onChange={this.handleChange("agentCommissionBigGroup", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="agentCommissionMediumGroup"
                                value={this.state.agentCommissionMediumGroup}
                                onChange={this.handleChange("agentCommissionMediumGroup", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="agentCommissionSmallGroup"
                                value={this.state.agentCommissionSmallGroup}
                                onChange={this.handleChange("agentCommissionSmallGroup", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                label="agentCallCommission"
                                value={this.state.agentCallCommission}
                                onChange={this.handleChange("agentCallCommission", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="agentMessageCommission"
                                value={this.state.agentMessageCommission}
                                onChange={this.handleChange("agentMessageCommission", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />

                            <TextField
                                label="personalBigPercent"
                                value={this.state.personalBigPercent}
                                onChange={this.handleChange("personalBigPercent", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="personalMediumPercent"
                                value={this.state.personalMediumPercent}
                                onChange={this.handleChange("personalMediumPercent", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="personalSmallPercent"
                                value={this.state.personalSmallPercent}
                                onChange={this.handleChange("personalSmallPercent", "value")}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <Button className="register-new-user-btn" variant="contained" color="primary" onClick={this.createUser}>
                                Submit
                            </Button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default EditUser;