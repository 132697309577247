import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/stream.css';

// libs
import GoogleLogin from 'react-google-login';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import * as moment from 'moment';
import { Link } from 'react-router-dom';
import ShareLinks from './ShareLinks';

const promoTypes = [
    {
        value: 'private',
        label: 'Private',
    },
    {
        value: 'public',
        label: 'Public',
    },
    {
        value: 'unlisted',
        label: 'Unlisted',
    },
]

export default class Stream extends Component {

    constructor(props) {
        super(props);

        this.state = {
            broadcastTitle: "",
            broadcastDescription: "",
            streamTitle: "",
            watchToken: "",
            policy: "public",
            forKids: false,
            scheduledStart: new Date(),
            defaultThumbnailURL: "",
            mediumThumbnailURL: "",
            highThumbnailURL: "",
            mode: "create",
            userId: "",
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleChangeCheckbox = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    responseGoogle = response => {
        this.sendCode(response.code)
    }

    async sendCode(code) {
        try {
            const data = {
                result: code,
            };
            const response = await APIrequest.send_code(data);
            console.log(response);
        } catch (e) {
            console.log(e);
        }
    }

    async addBroadcast() {
        try {
            const { broadcastTitle, broadcastDescription, streamTitle, watchToken, policy, forKids, scheduledStart, defaultThumbnailURL, mediumThumbnailURL, highThumbnailURL, userId } = this.state;
            const data = {
                userId: userId,
                broadcastTitle: broadcastTitle,
                broadcastDescription: broadcastDescription,
                streamTitle: streamTitle,
                watchToken: watchToken,
                policy: policy,
                forKids: forKids,
                scheduledStart: moment(scheduledStart).format('x'),
                defaultThumbnail: {
                    url: defaultThumbnailURL,
                    width: 120,
                    height: 90,
                },
                mediumThumbnail: {
                    url: mediumThumbnailURL,
                    width: 320,
                    height: 180,
                },
                highThumbnail: {
                    url: highThumbnailURL,
                    width: 480,
                    height: 360,
                },
            };
            const response = await APIrequest.add_broadcast(data);
            if (response.status === 200) {
                this.setState({
                    mode: "created",
                    watchToken: response.data.watchToken,
                    startToken: response.data.startToken,
                })
            }
        } catch (e) {
            console.log(e);
            alert("Error!")
        }
    }

    render() {
        const { broadcastTitle, broadcastDescription, streamTitle, watchToken, policy, forKids, scheduledStart, defaultThumbnailURL, mediumThumbnailURL, highThumbnailURL, mode, startToken, userId } = this.state;
        
        return (
            <div className="create-stream">
                { mode === "create" &&  
                    <div className="stream-content">
                        <div className="title-3">Create stream</div>
                        <TextField
                            label="User ID"
                            onChange={this.handleChange('userId', 'value')}
                            value={userId}
                            margin="normal"
                        />
                        <TextField
                            label="Broadcast title"
                            onChange={this.handleChange('broadcastTitle', 'value')}
                            value={broadcastTitle}
                            margin="normal"
                        />
                        <TextField
                            label="Broadcast description"
                            onChange={this.handleChange('broadcastDescription', 'value')}
                            value={broadcastDescription}
                            margin="normal"
                        />
                        <TextField
                            label="Stream title"
                            onChange={this.handleChange('streamTitle', 'value')}
                            value={streamTitle}
                            margin="normal"
                        />
                        <TextField
                            label="Watch token"
                            onChange={this.handleChange('watchToken', 'value')}
                            value={watchToken}
                            margin="normal"
                        />
                        <TextField
                            select
                            label="Policy"
                            value={policy}
                            onChange={this.handleChange('policy')}
                            margin="normal"
                            variant="outlined"
                        >
                            {promoTypes.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormControlLabel
                            margin="normal"
                            control={
                                <Checkbox
                                    checked={forKids}
                                    onChange={this.handleChangeCheckbox('forKids')}
                                    value={forKids}
                                />
                            }
                            label="For kids"
                        />
                        <DateTimePicker 
                            value={scheduledStart} 
                            onChange={value => this.setState({ scheduledStart: value })} 
                            margin="normal"
                        />
                        <TextField
                            label="Default thumbnail URL. *120px:90px"
                            onChange={this.handleChange('defaultThumbnailURL', 'value')}
                            value={defaultThumbnailURL}
                            margin="normal"
                        />
                        <TextField
                            label="Medium thumbnail URL. *320px:180px"
                            onChange={this.handleChange('mediumThumbnailURL', 'value')}
                            value={mediumThumbnailURL}
                            margin="normal"
                        />
                        <TextField
                            label="High thumbnail URL. *480px:360px"
                            onChange={this.handleChange('highThumbnailURL', 'value')}
                            value={highThumbnailURL}
                            margin="normal"
                        />
                        <div className="mt_16">
                            <Button 
                                className="stream-create-btn"
                                variant="contained" 
                                color="primary" 
                                onClick={() => this.addBroadcast()}
                            >
                                Create
                            </Button>
                            { broadcastDescription === 'admin' && 
                                <GoogleLogin
                                    clientId="457577427403-vor19rj08f3m2kgeti7hq5eu5hqk5co9.apps.googleusercontent.com"
                                    buttonText="Login"
                                    onSuccess={this.responseGoogle}
                                    onFailure={() => alert("Error!")}
                                    accessType="offline"
                                    scope="profile email https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.upload"
                                    responseType="code"
                                    fetchBasicProfile={false}
                                />
                            }
                        </div>
                    </div>
                }
                { mode === "created" && 
                    <ShareLinks startToken={startToken} watchToken={watchToken} />
                }
            </div>
        )
    }
}
