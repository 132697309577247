import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import { Link } from 'react-router-dom';
import './css/user.css';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import * as moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from "@material-ui/core/TextField";

// imgs
import BackIcon from 'imgs/back.svg';
import DefaultProfileIcon from 'imgs/user-icon.svg';

// components
import BankDetails from './BankDetails';
import UploadPromoVideo from './UploadPromoVideo';
import TransLog from './trans log/TransLog';
import EditUser from "components/edit user/EditUser";
import MultipleSelect from "components/edit user/MultipleSelect";
import Products from "components/products/Products";
import Gallery from "components/gallery/Gallery";
import ForumHistoryLog from "./ForumHistoryLog";
import safeKey from 'functions/safeKey.js';
import TableRow from './profileSnippet/TableRow';
import TableSection from './profileSnippet/TableSection';
import UsersScore from './UsersScores';
import CompletedProducts from './CompletedProducts';
import LinkUsersToAgent from './LinkUsersToAgent';
import Subscriptions from './subscriptions/Subscriptions';
import SubscriptionsList from './subscription list/SubscriptionsList';
import UpgradeToClub from './upgrade to club/UpgradeToClub';
import ClubVideoBlock from './club video block/ClubVideoBlock';

class User extends Component {

    constructor(props) {
        super(props);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.generateUserInfoTable = this.generateUserInfoTable.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deleteFromBlacklist = this.deleteFromBlacklist.bind(this);
        this.closeSnackBar = this.closeSnackBar.bind(this);
        this.getAllStoredUserInfo = this.getAllStoredUserInfo.bind(this);
        this.sendDeviceLogs = this.sendDeviceLogs.bind(this);
        this.handleOpenDialogDeleteUser = this.handleOpenDialogDeleteUser.bind(this);
        this.confrimUser = this.confrimUser.bind(this);
        this.blockUser = this.blockUser.bind(this);
        this.userUpdatedCallback = this.userUpdatedCallback.bind(this);
        this.handleCangeMultipleField = this.handleCangeMultipleField.bind(this);
        this.getKey = this.getKey.bind(this);
        this.makeAgent = this.makeAgent.bind(this);
        this.hideFromCatalog = this.hideFromCatalog.bind(this);
        this.canCreateAuction = this.canCreateAuction.bind(this);

        let mode = 'profile';
        if (APIrequest.get_url_token(this.props.location.pathname) === 'add-new-user') {
            mode = 'registerUser';
        }
        else if (this.props.location.pathname.split('/')[1] === "upgrade-to-expert") {
            mode = 'upgradeToExpert';
            this.tagIdsToName(safeKey(() => this.props.upgradeInfo.tags), 'newTags');
            this.getCategories();
        }
        else if (this.props.location.pathname.split('/')[1] === "update-expert-info") {
            mode = 'updateExpertInfo';
            this.tagIdsToName(safeKey(() => this.props.updateInfo.expertInfo.tags), 'newTags');
            this.getCategories();
        }

        this.state = {
            userInfo: {},
            userInfoTable: null,
            filteredTransLogs: null,
            dialogIsOpen: false,
            userWalletTable: null,
            deviceIdsTable: null,
            blacklistIdsTable: null,
            snackbarIsOpen: false,
            dialogType: '',
            linkForUserLogFile: '',
            menuIsOpen: null,
            anchorEl: null,
            mode: mode,
            categoriesList: [],
            expertInfoTable: null,
            selectedCategory: this.props.upgradeInfo ? this.props.upgradeInfo.category : [],
            rejectReason: this.props.upgradeInfo && this.props.upgradeInfo.reason ? this.props.upgradeInfo.reason : "Sorry, but we can not confirm your expert status. You have not provided enough reason to upgrade your profile. Try to connect social networks by which we will be able to evaluate your expertise and identity.",
            oldTags: [],
            newTags: [],
            tableSections: [],
            allGroups: [],
            charityInfoTable: null,
            groupNames: [],
            fundsName: [],
        };

        this.keyCount = 0;
    }

    componentDidMount() {
        this.getUserInfo();
        this.getGroups();
    };

    handleChange = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;
        this.setState({
            [name]: value,
        });
    };

    handleOpenMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    getKey(){
        return this.keyCount++;
    };

    handleOpenDialog = () => {
        this.setState({ dialogIsOpen: true });
    };

    handleCloseDialog = () => {
        this.setState({ dialogIsOpen: false });
    };

    handleCangeMultipleField = (key, value) => {
        this.setState({ [key]: value });
    }

    closeSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ snackbarIsOpen: false });
    };

    handleOpenDialogDeleteUser() {
        this.setState({
            dialogType: 'deleteUser',
            dialogIsOpen: true
        })
    }

    async getUserInfo() {
        let userID = APIrequest.get_url_token(this.props.location.pathname);
        try {
            let response = await APIrequest.get_user_full_by_id(userID);
            this.setState({
                userInfo: response.data
            })
            this.generateUserInfoTable(response.data);
            if (this.state.mode === 'updateExpertInfo') this.tagIdsToName(safeKey(() => response.data.expertInfo.tags), 'oldTags');
            if (response.data.expertInfo && response.data.expertInfo.group) {
                this.getGroupNames(response.data.expertInfo.group);
            }
            if (response.data.charityInfo && response.data.charityInfo.funds) {
                this.getCharutyFundsNames({result: response.data.charityInfo.funds});
            }
        } catch (error) {
            console.log(error)
        }
    }

    async getGroupNames(ids) {
        try {
            let response = await APIrequest.get_groups_by_ids(ids);
            this.setState({
                groupNames: response.data.map( group => group.localizedString )
            })
        } catch (error) {
            console.log(error);
        }
    }

    async getCharutyFundsNames(ids) {
        try {
            let response = await APIrequest.get_charity_funds_by_ids(ids);
            this.setState({
                fundsName: response.data.map( fund => fund.name )
            })
        } catch (error) {
            console.log(error);
        }
    }

    async getAllStoredUserInfo() {
        let userID = APIrequest.get_url_token(this.props.location.pathname);
        try {
            let response = await APIrequest.get_full_info(userID);
            this.setState({
                linkForUserLogFile: response,
                dialogType: 'sendAllDataToUser',
                dialogIsOpen: true
            })
        } catch (error) {
            console.log(error)
        }
    }

    async sendDeviceLogs(deviceID) {
        try {
            await APIrequest.get_logs_for_device(deviceID.idDevice);
            this.setState({
                snackbarIsOpen: true
            })
        } catch (error) {
            console.log(error)
        }
    }

    async deleteUser() {
        let userID = APIrequest.get_url_token(this.props.location.pathname);
        try {
            await APIrequest.delete_user_admin(userID);
            alert("Success!");
            this.handleCloseDialog();
            this.props.history.push('/search');
        } catch (error) {
            console.log(error)
        }
    }

    async deleteDevice(deviceID) {
        try {
            await APIrequest.remove_device_admin(deviceID.idDevice);
            alert("Success!");
            this.getUserInfo();
        } catch (error) {
            console.log(error)
        }
    }

    async confrimUser(isConfirmed) {
        let userID = APIrequest.get_url_token(this.props.location.pathname);
        this.handleCloseMenu();
        try {
            await APIrequest.confirm_user(userID, isConfirmed);
            alert("Success!");
            this.getUserInfo();
        } catch (error) {
            console.log(error)
        }
    }

    async deleteFromBlacklist(userID) {
        let data = {
            "result": userID
        };

        try {
            await APIrequest.remove_user_from_blacklist(data);
            this.setState({
                snackbarIsOpen: true
            })
            this.getUserInfo();
        } catch (error) {
            console.log(error)
        }
    }

    async verifyCorporateEmail() {
        try {
            await APIrequest.verify_corporate_email_manual(this.state.userInfo.idUser);
            this.setState({
                snackbarIsOpen: true
            })
            this.getUserInfo();
        } catch (error) {
            console.log(error)
        }
    }

    async blockUser(isBlocked) {
        let userID = APIrequest.get_url_token(this.props.location.pathname);
        this.handleCloseMenu();
        try {
            await APIrequest.block_user(userID, isBlocked);
            this.setState({
                snackbarIsOpen: true
            })
            this.getUserInfo();
        } catch (error) {
            console.log(error)
        }
    }

    async getCategories() {
        try {
            const response = await APIrequest.get_categories();
            let categoriesList = response.data.map(a => {
                return a.name
            })
            this.setState({
                categoriesList: categoriesList
            })
        } catch (error) {
            console.log(error)
        }
    }

    async confirmUpgrade(isApproved) {
        let data = {
            "idRequest": this.props.upgradeInfo.idRequest,
            "status": "APPROVED",
            "category": this.state.selectedCategory,
        }
        if (!isApproved) {
            data.status = "REJECTED";
            data.reason = this.state.rejectReason;
        }
        try {
            const response = await APIrequest.confirm_upgrade(data);
            if (response.status === 200) {
                this.props.approvedCallback();
                alert('Success!');
            }
        }
        catch (error) {
            alert('Error! Try again');
            console.log(error)
        }
    }

    async getGroups() {
        try {
            const response = await APIrequest.get_groups();
            this.setState({
                allGroups: response.data
            });
        } catch (error) {
            console.log(error)
        }
    }

    generateUserInfoTable(data) {
        let rows = [];
        for (var key in data) {
            let row = null;
            if (data.hasOwnProperty(key) && data[key] && key !== 'name' && key !== 'imageLink' && key !== 'gallery' && key !== 'devices') { 
                if (key === 'authorizationDevices') {
                    let deviceIds = data[key];
                    let deviceIdsRow = null;
                    let deviceIdsTable = [];
                    for (let i in deviceIds) {
                        deviceIdsRow =
                            <div key={this.getKey()} className="user-profile-row user-profile-row-device-id">
                                <div className="user-profile-col user-profile-col-device-id">
                                    <span>{deviceIds[i].locale ? 'Locale - ' + String(deviceIds[i].locale) : null}</span>
                                    <span>{deviceIds[i].model ? 'Model - ' + String(deviceIds[i].model) : null}</span>
                                    <span>{deviceIds[i].osVersion ? 'OS Version - ' + String(deviceIds[i].osVersion) : null}</span>
                                    <span>{deviceIds[i].idDevice ? 'Device ID - ' + String(deviceIds[i].idDevice) : null}</span>
                                    <span>{deviceIds[i].lastAddr ? 'Last address - ' + String(deviceIds[i].lastAddr) : null}</span>
                                </div>
                                <div className="user-profile-col user-profile-col-device-id">
                                    <span className="user-profile-col-device-id-btn user-profile-col-device-id-btn-delete" onClick={this.deleteDevice.bind(this, deviceIds[i])}>delete</span>
                                    <span className="user-profile-col-device-id-btn user-profile-col-device-id-btn-logs" onClick={this.sendDeviceLogs.bind(this, deviceIds[i])}>send device logs to my email</span>
                                </div>
                            </div>
                        deviceIdsTable.push(deviceIdsRow);
                    }
                    this.setState({
                        deviceIdsTable: deviceIdsTable
                    })
                }
                else if (key === 'blacklistIds') {
                    let blacklistIDs = data[key];
                    let blacklistIdsRow = null;
                    let blacklistIdsTable = [];
                    for (let i in blacklistIDs) {
                        blacklistIdsRow =
                            <div className="user-profile-row user-profile-row-device-id">
                                <div className="user-profile-col user-profile-col-device-id">
                                    <span>{blacklistIDs[i] ? String(blacklistIDs[i]) : null}</span>
                                </div>
                                <div className="user-profile-col user-profile-col-device-id-btn">
                                    <span onClick={this.deleteFromBlacklist.bind(this, blacklistIDs[i])}>delete</span>
                                </div>
                            </div>
                        blacklistIdsTable.push(blacklistIdsRow);
                    }
                    this.setState({
                        blacklistIdsTable: blacklistIdsTable
                    })
                }
                else if (key === 'expertInfo') {
                    let expertInfo = data[key];
                    let expertRow = null;
                    let expertTable = [];
                    expertTable = Object.entries(expertInfo).map(([key, value]) => {
                        if (!value) return null
                        return <TableRow key={this.getKey()} title={key} value={expertInfo[key]}/>
                    });
                    if (expertInfo.corporateEmail) { // to refactor: move corporate email to seperate section & don't handle expert info seperatly]
                        expertRow =
                            <div className="user-profile-row user-profile-row-device-id">
                                <div className="user-profile-col user-profile-col-device-id">
                                    <span>{expertInfo.corporateEmail}</span>
                                </div>
                                <div className="user-profile-col user-profile-col-device-id-btn">
                                    {expertInfo.confirmedCorporateEmail ? null :
                                        <span onClick={this.verifyCorporateEmail}>Confirm</span>
                                    }
                                </div>
                            </div>
                        expertTable.push(expertRow);
                    }
                    this.setState({
                        expertInfoTable: expertTable
                    })
                }
                else if (typeof data[key] === 'object' && data[key] && !Array.isArray(data[key])) {
                    const newSection = <TableSection key={this.getKey()} title={key}>
							{Object.entries(data[key]).map(([property, value]) => {
								return <TableRow key={this.getKey()} title={property} value={value} />;
							})}
						</TableSection>

                    if (key === 'userWallet') {
                        this.setState({
                            userWalletTable: [newSection]
                        })
                    }
                    else if (key === 'charityInfo') {
                        this.setState({
                            charityInfoTable: [newSection]
                        })
                    }
                    this.setState({
                        tableSections: [...this.state.tableSections, newSection]
                    })
                }
                else {
                    row = <TableRow key={key} title={key} value={data[key]}/>
                }
                rows.push(row);
            }
        }
        this.setState({
            userInfoTable: rows
        })
    }

    userUpdatedCallback(mode = "profile") {
        this.getUserInfo();
        if (mode) {
            this.setState({
                mode: 'profile'
            })
        }
    }

    async saveChangeRequest(data, isApproved) {
        data.status = isApproved ? "APPROVED" : "REJECTED";
        try {
            await APIrequest.save_change_request(data);
            this.props.approvedCallback();
            alert('Success!');
        }
        catch (error) {
            alert('Error! Try again');
            console.log(error)
        }
    }

    async tagIdsToName(ids, key) {
        try {
            const response = await APIrequest.get_tags_by_ids(ids);
            this.setState({
                [key]: response.data.map(tag => { return tag.localizedString })
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    async makeAgent(isAgent) {
        try {
            const response = await APIrequest.make_agent(this.state.userInfo.idUser, isAgent);
            if (response.status === 200) {
                alert("Success");
                this.getUserInfo();
            } else alert("Error")
        }
        catch (error) {
            alert("Error")
            console.log(error)
        }
    }

    async hideFromCatalog(hide) {
        if (hide) {
            try {
                const response = await APIrequest.hide_user_from_catalog(this.state.userInfo.idUser);
                if (response.status === 200) {
                    alert("Success");
                    this.getUserInfo();
                } else alert("Error")
            }
            catch (error) {
                alert("Error")
                console.log(error)
            }
        }
        else {
            alert("To make user visible in catalog add him to a group");
        }
    }

    async canCreateAuction(canCreate) {
        try {
            const response = await APIrequest.can_create_auction(this.state.userInfo.idUser, canCreate);
            if (response.status === 200) {
                alert("Success");
                this.getUserInfo();
            } else alert("Error")
        }
        catch (error) {
            alert("Error")
            console.log(error)
        }
    }

    async enableCommunity(enable) {
        try {
            const response = await APIrequest.community_enabled(this.state.userInfo.idUser, enable);
            if (response.status === 200) {
                alert("Success");
                this.getUserInfo();
            } else alert("Error")
        }
        catch (error) {
            alert("Error")
            console.log(error)
        }
    }

    render() {
        const { anchorEl } = this.state;

        return (
            <div className="user">
                <div className="user-container">
                    <div className="back-to-search-container">
                        <Link to="/search" className="back-to-search">
                            <img src={BackIcon} alt="back" />
                            Back to search
                        </Link>
                    </div>
                    <div className="user-content">
                        <div className="user-profile">
                            <div className="profile-menu">
                                <IconButton
                                    aria-label="More"
                                    aria-owns={anchorEl ? 'long-menu' : null}
                                    aria-haspopup="true"
                                    onClick={this.handleOpenMenu}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleCloseMenu}
                                >
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'profile' }) }}>
                                        Transaction logs
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'editUser' }) }}>
                                        Edit user
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'productEdit' }) }}>
                                        Edit user products
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'bankDetailsEdit' }) }}>
                                        Edit user bank details
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'photoUpload' }) }}>
                                        Change profile photo
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'uploadPromoVideo' }) }}>
                                        Promo video
                                    </MenuItem>
                                    {/* <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'gallery' }) }}>
                                        Edit user gallery
                                    </MenuItem> */}
                                    {/* <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'forumHistory' }) }}>
                                        Forum activity
                                    </MenuItem> */}
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'scores' }) }}>
                                        User's scores
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'completedProducts' }) }}>
                                        Completed products
                                    </MenuItem>
                                    <MenuItem onClick={this.handleOpenDialogDeleteUser}>
                                        Delete user
                                    </MenuItem>
                                    <MenuItem onClick={this.getAllStoredUserInfo}>
                                        Send all stored data to user
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'subscriptions' }) }}>
                                        Subscriptions
                                    </MenuItem>
                                    <MenuItem onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'subscriptions-list' }) }}>
                                        Subscriptions list
                                    </MenuItem>
                                    {this.state.userInfo.role === "EXPERT" && !!!this.state.userInfo.expertInfo.clubId &&
                                        <MenuItem  onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'upgradeToClub' }) }}>
                                            Upgrade to club
                                        </MenuItem>
                                    }
                                    {!!this.state.userInfo.expertInfo && !!this.state.userInfo.expertInfo.clubId &&
                                        <MenuItem  onClick={() => { this.handleCloseMenu(); this.setState({ mode: 'club-video-block' }) }}>
                                            Club video block
                                        </MenuItem>
                                    }
                                    {this.state.userInfo.role === "EXPERT" ?
                                        <MenuItem onClick={this.confrimUser.bind(this, false)}>
                                            Revoke user confirmation
                                        </MenuItem>
                                        :
                                        <MenuItem onClick={this.confrimUser.bind(this, true)}>
                                            Confirm user
                                        </MenuItem>
                                    }
                                    {this.state.userInfo.status === "BLOCKED" ?
                                        <MenuItem onClick={this.blockUser.bind(this, false)}>
                                            Unblock user
                                        </MenuItem>
                                        :
                                        <MenuItem onClick={this.blockUser.bind(this, true)}>
                                            Block user
                                        </MenuItem>
                                    }
                                    {this.state.userInfo.role === "AGENT" ?
                                        <div>
                                            <MenuItem onClick={() => this.makeAgent(false)}>
                                                Revoke agent status
                                            </MenuItem>
                                            <MenuItem onClick={() => this.setState({ mode: "linkUsersToAgent" })}>
                                                Link users to agent
                                            </MenuItem>
                                        </div>
                                        :
                                        <MenuItem onClick={() => this.makeAgent(true)}>
                                            Make agent
                                        </MenuItem>
                                    }
                                    {this.state.userInfo.expertInfo && this.state.userInfo.expertInfo.group && this.state.userInfo.expertInfo.group.length ?
                                        <MenuItem onClick={() => this.hideFromCatalog(true)}>
                                            Hide from catalog
                                        </MenuItem>
                                        :
                                        <MenuItem onClick={() => this.hideFromCatalog(false)}>
                                            Show in catalog
                                        </MenuItem>
                                    }
                                    {this.state.userInfo.auctionInfo && 
                                        !!this.state.userInfo.auctionInfo.canCreateAuction ?
                                            <MenuItem onClick={() => this.canCreateAuction(false)}>
                                                Disallow to create auctions 
                                            </MenuItem>
                                            :
                                            <MenuItem onClick={() => this.canCreateAuction(true)}>
                                                Allow to create auction
                                            </MenuItem>
                                    }
                                    {this.state.userInfo.settings && this.state.userInfo.settings.communityEnabled ? 
                                        <MenuItem onClick={() => this.enableCommunity(false)}>
                                            Disable community
                                        </MenuItem>
                                        :
                                        <MenuItem onClick={() => this.enableCommunity(true)}>
                                            Enable community
                                        </MenuItem>
                                    }
                                </Menu>
                            </div>
                            <div className="user-profile-img-container">
                                <img alt="user" src={this.state.userInfo && this.state.userInfo.imageLink ? this.state.userInfo.imageLink : DefaultProfileIcon} className="user-profile-img" />
                            </div>
                            <div className="user-profile-name">
                                {this.state.userInfo ? this.state.userInfo.name : null}
                            </div>
                            <div className="user-profile-work">
                                {this.state.userInfo ? safeKey(() => this.state.userInfo.expertInfo.work) : null}
                            </div>
                            {this.state.userInfoTable}
                            {this.state.userWalletTable && this.state.userWalletTable.length > 0 ? this.state.userWalletTable : <div>No data</div>}
                            <div className="user-wallet-header">Expert info</div>
                            {this.state.expertInfoTable && this.state.expertInfoTable.length > 0 ? this.state.expertInfoTable : <div>No data</div>}
                            <TableRow title="groupsName" value={this.state.groupNames}/>
                            {this.state.charityInfoTable && this.state.charityInfoTable.length > 0 ? this.state.charityInfoTable : <div>No data</div>}
                            <TableRow title="fundsName" value={this.state.fundsName}/>
                            {this.state.tableSections.map( section => { return section })}
                            <div className="user-wallet-header">User's devices ID</div>
                            {this.state.deviceIdsTable && this.state.deviceIdsTable.length > 0 ? this.state.deviceIdsTable : 'No data'}
                            <div className="user-wallet-header">Blacklist</div>
                            {this.state.blacklistIdsTable && this.state.blacklistIdsTable.length > 0 ? this.state.blacklistIdsTable : <div>Blacklist is empty</div>}
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                open={this.state.snackbarIsOpen}
                                autoHideDuration={5000}
                                onClose={this.closeSnackBar}
                                ContentProps={{
                                    'aria-describedby': 'message-id',
                                }}
                                message={<span id="message-id">Success!</span>}
                                action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        onClick={this.closeSnackBar}
                                    >
                                        <CloseIcon />
                                    </IconButton>,
                                ]}
                            />
                            <div className="delete-user-btn-container">
                                <Dialog
                                    open={this.state.dialogIsOpen}
                                    onClose={this.handleCloseDialog}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {this.state.dialogType === 'deleteUser' ?
                                            'Delete user'
                                            :
                                            'Link for downloading all user data'
                                        }
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {this.state.dialogType === 'deleteUser' ?
                                                <span>Are you sure you want to delete this user?</span>
                                                :
                                                <a href={this.state.linkForUserLogFile} download>
                                                    {this.state.linkForUserLogFile}
                                                </a>
                                            }
                                        </DialogContentText>
                                    </DialogContent>
                                    {this.state.dialogType === 'deleteUser' ?
                                        <DialogActions>
                                            <Button onClick={this.handleCloseDialog} color="primary">
                                                Cancel
                                            </Button>
                                            <Button onClick={this.deleteUser} color="secondary" autoFocus>
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                        :
                                        <DialogActions>
                                            <Button onClick={this.handleCloseDialog} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    }
                                </Dialog>
                            </div>
                        </div>
                        {(() => {
                            switch (this.state.mode) {
                                case "registerUser":
                                    return <EditUser history={this.props.history} operationType="addNewUser" requiredFields={['name', 'email', 'aboutMe', 'defaultCallRate', 'defaultMessageRate', 'languages']} userUpdatedCallback={this.userUpdatedCallback} />;
                                case "editUser":
                                    return <EditUser history={this.props.history} operationType="editUser" userInfo={this.state.userInfo} requiredFields={[]} userUpdatedCallback={this.userUpdatedCallback} />;
                                case "photoUpload":
                                    return <EditUser history={this.props.history} operationType="photoUpload" userInfo={this.state.userInfo} requiredFields={[]} userUpdatedCallback={this.userUpdatedCallback} />;
                                case "uploadPromoVideo":
                                    return <UploadPromoVideo idUser={APIrequest.get_url_token(this.props.location.pathname)} onLoaded={() => this.setState({ mode: 'profile' })}/>
                                case "productEdit":
                                    return <Products history={this.props.history} userID={APIrequest.get_url_token(this.props.location.pathname)} userUpdatedCallback={this.userUpdatedCallback} />;
                                case "bankDetailsEdit":
                                    return <BankDetails bankDetails={this.state.userInfo.bankDetails} userID={APIrequest.get_url_token(this.props.location.pathname)}/>;
                                case "scores":
                                    return <UsersScore userID={APIrequest.get_url_token(this.props.location.pathname)} />;
                                case "completedProducts":
                                    return <CompletedProducts userID={APIrequest.get_url_token(this.props.location.pathname)} />;
                                case "subscriptions":
                                    return <Subscriptions user={this.state.userInfo} userUpdatedCallback={this.userUpdatedCallback} />;
                                    case "subscriptions-list":
                                    return <SubscriptionsList user={this.state.userInfo} />;
                                case "gallery":
                                    return <Gallery history={this.props.history} user={this.state.userInfo} userUpdatedCallback={this.userUpdatedCallback} />;
                                case "linkUsersToAgent":
                                    return <LinkUsersToAgent agent={this.state.userInfo} userUpdatedCallback={(mode) => this.userUpdatedCallback(mode)} />;
                                case "profile":
                                    return <TransLog />
                                case "upgradeToClub":
                                    return <UpgradeToClub user={this.state.userInfo} onSubmit={this.userUpdatedCallback} />
                                case "club-video-block":
                                    return <ClubVideoBlock user={this.state.userInfo} onSubmit={this.userUpdatedCallback} />
                                case "upgradeToExpert":
                                    const ui = this.props.upgradeInfo;
                                    return <div className="user-table user-table-expert">
                                        <div className="user-table-header-container">
                                            <span className="user-table-header">Upgrade request details</span>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Request ID</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{ui.idRequest}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Created date</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{moment(ui.createdDate).format('DD MMMM YYYY HH:mm')}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Last modified date</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{moment(ui.lastModifiedDate).format('DD MMMM YYYY HH:mm')}</span>
                                            </div>
                                        </div>
                                        { ui.group && 
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Groups</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{String(ui.group.map( groupId => safeKey(() => this.state.allGroups.find( group => { return group.idGroup === groupId }).name) )).replace(/,/g, ', ')}</span>
                                            </div>
                                        </div>
                                        }
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Categories</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{String(ui.category).replace(/,/g, ', ')}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Tags</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{String(this.state.newTags).replace(/,/g, ', ')}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Additional Info</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{ui.additionalInfo}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Category proposed</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{ui.proposedCategory}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Corporate email</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{ui.corporateEmail}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Facebook URL</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <a href={ui.facebookURL} target="_blank" rel="noopener noreferrer" className="user-table-col-val">{ui.facebookURL}</a>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Instagram URL</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <a href={ui.instagramURL} target="_blank" rel="noopener noreferrer" className="user-table-col-val">{ui.instagramURL}</a>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Instagram ID</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{ui.instagramId}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Linked URL</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <a href={ui.linkedInURL} target="_blank" rel="noopener noreferrer" className="user-table-col-val">{ui.linkedInURL}</a>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">LinkedIn ID</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <span className="user-table-col-val">{ui.linkedInId}</span>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Medium URL</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                <a href={ui.mediumURL} target="_blank" rel="noopener noreferrer" className="user-table-col-val">{ui.mediumURL}</a>
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col-transaction">
                                                <span className="user-table-col-val">Youtube URL</span>
                                            </div>
                                            <div className="user-table-col user-table-col-operation">
                                                {ui.youtubeURLs ? ui.youtubeURLs.map(url => {
                                                    return <a href={url} target="_blank" rel="noopener noreferrer" className="user-table-col-val">{url}</a>
                                                }) : null}
                                            </div>
                                        </div>
                                        <div className="user-table-row">
                                            {this.state.categoriesList && this.state.categoriesList.length > 0 ?
                                                <MultipleSelect label="Select categories" selected={this.state.selectedCategory} fullList={this.state.categoriesList} onLangChange={this.handleCangeMultipleField.bind(this, 'selectedCategory')} />
                                                : null}
                                        </div>
                                        <div className="user-table-row">
                                            <TextField
                                                label="Reject reason"
                                                value={this.state.rejectReason}
                                                onChange={this.handleChange("rejectReason", "value")}
                                                margin="none"
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                            />
                                        </div>
                                        {ui.status === "WAITING" &&
                                            <div className="user-table-row user-table-row-btns">
                                                <Button onClick={this.confirmUpgrade.bind(this, false)} variant="outlined" color="secondary" className="experts-table-btn">
                                                    Deny
                                                </Button>
                                                <Button onClick={this.confirmUpgrade.bind(this, true)} variant="outlined" color="primary" className="experts-table-btn">
                                                    Approve
                                                </Button>
                                            </div>
                                        }
                                    </div>;
                                case "updateExpertInfo":
                                    const updateInfo = this.props.updateInfo;
                                    return <div className="user-table user-table-expert">
                                        <div className="user-table-header-container">
                                            <span className="user-table-header">Update expert info request details</span>
                                        </div>
                                        <div className="user-table-row">
                                            <div className="user-table-col user-table-col_05">
                                                <span className="user-table-col-val">Key</span>
                                                <span className="user-table-col-subval">Model</span>
                                            </div>
                                            <div className="user-table-col">
                                                <span className="user-table-col-val">New value</span>
                                                <span className="user-table-col-subval">Old value</span>
                                            </div>
                                        </div>
                                        {Object.entries(updateInfo).map(([key, value]) => {
                                            if (value && key === 'expertInfo') {
                                                return Object.entries(value).map(([EIkey, EIvalue]) => {
                                                    let oldEIval = null;
                                                    if (this.state.userInfo.expertInfo) oldEIval = this.state.userInfo.expertInfo[EIkey];
                                                    if (EIvalue && EIkey.includes('Date')) {
                                                        return <div className="user-table-row">
                                                            <div className="user-table-col user-table-col_05">
                                                                <span className="user-table-col-val">{EIkey}</span>
                                                                <span className="user-table-col-subval">expertInfo</span>
                                                            </div>
                                                            <div className="user-table-col">
                                                                <span className="user-table-col-val">{moment(EIvalue).format('DD MMMM YYYY HH:mm')}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    else if (EIvalue && EIkey.includes('URL') && oldEIval !== EIvalue) {
                                                        return <div className="user-table-row">
                                                            <div className="user-table-col user-table-col_05">
                                                                <span className="user-table-col-val">{EIkey}</span>
                                                                <span className="user-table-col-subval">expertInfo</span>
                                                            </div>
                                                            <div className="user-table-col">
                                                                <a className="user-table-col-val" href={EIvalue} target="_blank" rel="noopener noreferrer">{String(EIvalue)}</a>
                                                                {this.state.userInfo.expertInfo ?
                                                                    <a className="user-table-col-subval" href={this.state.userInfo.expertInfo[EIkey]} target="_blank" rel="noopener noreferrer">{this.state.userInfo.expertInfo[EIkey]}</a>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    }
                                                    else if (EIvalue && EIkey === 'tags') {
                                                        return <div className="user-table-row">
                                                            <div className="user-table-col user-table-col_05">
                                                                <span className="user-table-col-val">{EIkey}</span>
                                                                <span className="user-table-col-subval">old value</span>
                                                            </div>
                                                            <div className="user-table-col">
                                                                <span className="user-table-col-val">{String(this.state.newTags).replace(/,/g, ', ')}</span>
                                                                <span className="user-table-col-subval">{String(this.state.oldTags).replace(/,/g, ', ')}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    else if (EIvalue && EIkey === 'category') {
                                                        return <div className="user-table-row">
                                                            <div className="user-table-col user-table-col_05">
                                                                <span className="user-table-col-val">{EIkey}</span>
                                                                <span className="user-table-col-subval">old value</span>
                                                            </div>
                                                            <div className="user-table-col">
                                                                <span className="user-table-col-val">{String(EIvalue).replace(/,/g, ', ')}</span>
                                                                <span className="user-table-col-subval">{this.state.userInfo.expertInfo ? String(this.state.userInfo.expertInfo[EIkey]).replace(/,/g, ', ') : null}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    else if (EIvalue && oldEIval !== EIvalue) {
                                                        return <div className="user-table-row">
                                                            <div className="user-table-col user-table-col_05">
                                                                <span className="user-table-col-val">{EIkey}</span>
                                                                <span className="user-table-col-subval">expertInfo</span>
                                                            </div>
                                                            <div className="user-table-col">
                                                                <span className="user-table-col-val">{String(EIvalue)}</span>
                                                                <span className="user-table-col-subval">{this.state.userInfo.expertInfo ? this.state.userInfo.expertInfo[EIkey] : null}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    else return null
                                                })
                                            }
                                            else if (key === 'originalName') {
                                                return null
                                            }
                                            else if (value && value !== this.state.userInfo[key]) {
                                                return <div className="user-table-row">
                                                    <div className="user-table-col user-table-col_05">
                                                        <span className="user-table-col-val">{key}</span>
                                                    </div>
                                                    <div className="user-table-col">
                                                        <span className="user-table-col-val">{value}</span>
                                                        <span className="user-table-col-subval">{this.state.userInfo[key]}</span>
                                                    </div>
                                                </div>
                                            }
                                            else return null
                                        })}
                                        {updateInfo.status === "WAITING" ?
                                            <div className="user-table-row user-table-row-btns">
                                                <Button onClick={this.saveChangeRequest.bind(this, updateInfo, false)} variant="outlined" color="secondary" className="experts-table-btn">
                                                    Deny
                                                </Button>
                                                <Button onClick={this.saveChangeRequest.bind(this, updateInfo, true)} variant="outlined" color="primary" className="experts-table-btn">
                                                    Approve
                                                </Button>
                                            </div>
                                            : null}
                                    </div>;
                                case "forumHistory":
                                    return <div className="user-table">
                                        <div className="user-table-header-container">
                                            <span className="user-table-header">Forum history log</span>
                                        </div>
                                        {this.state.userInfo.idUser ?
                                            <ForumHistoryLog user={this.state.userInfo} history={this.props.history} />
                                            : null}
                                    </div>;
                                default:
                                    break;
                            }
                        })()}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(User)
