import React, { Component } from 'react';
import TableRow from '../userPage/profileSnippet/TableRow';
import { Button } from '@material-ui/core';
import APIrequest from '../../APIcalls/APIrequest';

export default class StreamItem extends Component {

    async deleteStream() {
        try {
            const { stream: { id }, onDelete } = this.props;
            const response = await APIrequest.delete_stream(id);
            alert("Success")
            onDelete(response.data);
        } catch (error) {
            alert("Error");
            console.log(error);
        }
    }

    render() {
        const { stream } = this.props;

        return (
            <div className="stream-item text-1">
                {Object.entries(stream).map(([key, value]) => {
                    return <TableRow key={key} title={key} value={value} />
                })}
                <br/>
                <Button variant="outlined" color="secondary" onClick={this.deleteStream.bind(this)}>Delete</Button>
            </div>
        )
    }
}
