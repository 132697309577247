import React, { Component } from "react";
import APIrequest from "APIcalls/APIrequest";
import "./css/products-update.css";
import compareArrays from "functions/compareArrays";

// redux
import * as updateNavCounters from "redux/actions/NavCountersActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// libs
import * as moment from "moment";
import Button from "@material-ui/core/Button";
import { DatePicker } from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";

// imgs
import RefreshIcon from "imgs/refresh.svg";

class Experts extends Component {
	constructor(props) {
		super(props);
		this.generateTableRows = this.generateTableRows.bind(this);
		this.saveChangeRequest = this.saveChangeRequest.bind(this);
		this.getTableData = this.getTableData.bind(this);

		this.state = {
			tableRowsUpdate: [],
			mode: "table",
			usersStatus: "IN_REVIEW",
            startDate: null,
            endDate: moment(),
            selectedRequest: null,
		};
	}

	componentDidMount() {
		this.getTableData();
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
		this.getTableData();
	};

    handleChangeDate = name => event => {
        this.setState({ [name]: event });
    };

	getTableData() {
		this.getChangeRequests();
	}

	generateTableRows(records) {
		let rows = [];
		records.forEach(a => {
			let row = (
				<div className="experts-table-row" key={a.idProduct}>
					<div className="experts-table-col experts-table-col-user">
						<div className="experts-table-col-user-text">
							<span className="experts-table-col-val">{a.ownerName}</span>
							<span className="experts-table-col-subval">{a.idProduct}</span>
						</div>
					</div>
					<div className="experts-table-col experts-table-col-time">
						<span className="experts-table-col-val">{a.productType}</span>
						<span className="experts-table-col-subval">{a.name}</span>
					</div>
					<div className="experts-table-col experts-table-col-time">
						<span className="experts-table-col-val">{a.lastModifiedDate ? moment(a.lastModifiedDate).format("DD MMMM YYYY HH:mm") : "No data availiable"}</span>
						<span className="experts-table-col-subval">{a.productStatus}</span>
					</div>
					<div className="experts-table-col experts-table-col-btns">
						{a.productStatus === "IN_REVIEW" ? (
							<div>
								<Button
									onClick={() => this.saveChangeRequest(a.idProduct, true)}
									variant="outlined"
									color="primary"
									className="experts-table-btn"
								>
									Approve
								</Button>
								<Button
									onClick={() => { this.setState({ selectedRequest: a })}}
									variant="outlined"
									color="secondary"
									className="experts-table-btn"
								>
									Deny
								</Button>
                                <Button 
                                    onClick={() => { this.setState({ selectedRequest: a })}}
                                    variant="outlined" color="default" className="experts-table-btn">
                                    Details
                                </Button>
							</div>
						) : null}
					</div>
				</div>
			);
			rows.push(row);
		});
        this.setState({
            tableRowsUpdate: rows
        });
	}

    async getChangeRequests() {
		let data = {
			result: this.state.usersStatus
		};
		if (this.state.usersStatus === "ALL") data = {};

        if (this.state.startDate) data.startDate = this.state.startDate;
        if (this.state.endDate) data.endDate = this.state.endDate;

		try {
			let response = await APIrequest.get_products_review(data);
			this.generateTableRows(response.data);
		} catch (error) {
			console.log(error);
		}
	}

	async saveChangeRequest(id, isApproved, rejectReason = null) {
        let data = {
            idProduct: id
        };
        data.status = isApproved ? "APPROVED" : "REJECTED";
        if (rejectReason) data.rejectReason = rejectReason;
		try {
			await APIrequest.save_product_review_result(data);
			this.getTableData();
            this.getUpgradeRequestsCount();
			alert("Success!");
		} catch (error) {
			alert("Error! Try again");
			console.log(error);
		}
	}

    async getUpgradeRequestsCount() {
        try {
            const response = await APIrequest.get_product_change_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ productUpdates: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

	render() {
		return ( this.state.selectedRequest ?
            <SelectedRequest 
                productUpdates={this.state.selectedRequest}
                onClick={(id, reslut, rejectReason) => {
                    this.setState({ selectedRequest: null });
                    this.saveChangeRequest(id, reslut, rejectReason);
                }}
            /> 
            :
			<div className="products-update">
                <div className="experts-container">
                    <div className="experts-header-container">
                        <span className="experts-header">Product updates</span>
                        <img
                            onClick={() => {
                                this.getChangeRequests();
                            }}
                            src={RefreshIcon}
                            alt="refresh"
                        />
                    </div>
                    <div className="experts-status-btns">
                        <Button
                            onClick={() => {
                                this.setState({ usersStatus: "IN_REVIEW" }, () => this.getTableData());
                            }}
                            variant="contained"
                            color="primary"
                            className="experts-table-btn"
                        >
                            IN REVIEW
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({ usersStatus: "REJECTED" }, () => this.getTableData());
                            }}
                            variant="contained"
                            color="secondary"
                            className="experts-table-btn"
                        >
                            Rejected
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({ usersStatus: "ALL" }, () => this.getTableData());
                            }}
                            variant="contained"
                            color="default"
                            className="experts-table-btn"
                        >
                            All
                        </Button>
                        <div className="experts_date-picker flex-col">
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">Start date</div>
                                <DatePicker value={this.state.startDate} onChange={this.handleChangeDate('startDate')} />
                            </div>
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">End date</div>
                                <DatePicker value={this.state.endDate} onChange={this.handleChangeDate('endDate')} />
                            </div>
                            <Button style={{marginTop: '14px'}} variant="contained" color="primary" onClick={this.getTableData}>Apply</Button>
                        </div>
                    </div>
                    <div className="experts-table">
                        <div className="experts-table-row experts-table-row-header">
                            <div className="experts-table-col experts-table-col-user experts-table-col-user-header">
                                <div className="experts-table-col-user-text">
                                    <span className="experts-table-col-val">User Name</span>
                                    <span className="experts-table-col-subval">Product ID</span>
                                </div>
                            </div>
                            <div className="experts-table-col experts-table-col-time">
                                <span className="experts-table-col-val">Product type</span>
                                <span className="experts-table-col-subval">Product name</span>
                            </div>
                            <div className="experts-table-col experts-table-col-time">
                                <span className="experts-table-col-val">Date</span>
                                <span className="experts-table-col-subval">Status</span>
                            </div>
                            <div className="experts-table-col experts-table-col-btns"></div>
                        </div>
                        {this.state.tableRowsUpdate}
                    </div>
                </div>
            </div>			
		);
	}
};

class SelectedRequest extends Component {

    state = {
        rejectReason: ""
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    highlightRow(oldValue, newValue) {
        if (newValue) {
            if (Array.isArray(newValue)) {
                return oldValue !== newValue
            }
            return oldValue !== newValue
        }
        else return false
    }

    rejectHandle(id) {
        const { rejectReason } = this.state;
        const { onClick } = this.props;
        if (rejectReason === "") alert("rejectReason is required field");
        else onClick(id, false, rejectReason);
    }

    render() {
        const { rejectReason } = this.state;
        const { onClick, productUpdates: newProduct } = this.props;
        const isOldProduct = !!newProduct.visibleProduct;

        return <div className="product-update-container">
            <div className="product-update-details">
                <div className="title-5">{newProduct.productType}</div>
                <div className="title-5">{newProduct.ownerName}</div>
                <div className="caption">{newProduct.idOwner}</div>
                <div className="pud-table" style={isOldProduct ? {gridTemplateColumns: 'auto 1fr 1fr'} : {gridTemplateColumns: 'auto 1fr'}}>
                    <div className="pud-col"></div>
                    { isOldProduct && <div className="pud-col">Old value</div>}
                    <div className="pud-col">New value</div>
                    <CompareRow rowKey="name" newProduct={newProduct} />
                    <CompareRow rowKey="about" newProduct={newProduct} />
                    <CompareRow rowKey="shortAbout" newProduct={newProduct} />
                    <CompareRow rowKey="fileDescription" newProduct={newProduct} />
                    <CompareRow rowKey="sellType" newProduct={newProduct} />
                    <CompareRow rowKey="fileURL" newProduct={newProduct} type="link" />
                    <CompareRow rowKey="image" newProduct={newProduct} type="link" />
                    <CompareRow rowKey="coverImage" newProduct={newProduct} type="link" />
                    <CompareRow rowKey="video" newProduct={newProduct} type="link" />
                    <CompareRow rowKey="gallery" newProduct={newProduct} type="gallery" />
                    <CompareRow rowKey="startDate" newProduct={newProduct.auctionSettings} type="date"/>
                    <CompareRow rowKey="endDate" newProduct={newProduct.auctionSettings} type="date"/>
                    <CompareRow rowKey="startBid" newProduct={newProduct.auctionSettings}/>
                </div>
                <TextField
                    label="Reject reason"
                    value={rejectReason}
                    onChange={this.handleChange("rejectReason", "value")}
                    margin="none"
                    variant="outlined"
                    fullWidth
                    multiline
                />
                <div className="pud-btns">
                    <Button
                        onClick={() => onClick(newProduct.idProduct, true)}
                        variant="outlined"
                        color="primary"
                        className="experts-table-btn"
                    >
                        Approve
                    </Button>
                    <Button
                        onClick={() => this.rejectHandle(newProduct.idProduct)}
                        variant="outlined"
                        color="secondary"
                        className="experts-table-btn"
                    >
                        Deny
                    </Button>
                </div>
            </div>
        </div>
    }
}

const CompareRow = (props) => {
    const { rowKey: key, newProduct, type } = props;
    if (!newProduct) return null;
    const oldProduct = newProduct ? newProduct.visibleProduct : null;
    const colContent = (value) => {
        if (!value || (Array.isArray(value) && value.length === 0)) return null
        switch (type) {
            case "date":
                return <span>{moment(value).format("LLLL")}</span>
            case "link":
                return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
            case "gallery":
                return <LinksList links={value}/>
            default:
                return value
        }
    }
    const newValue = colContent(newProduct[key]);
    const oldValue = !!(oldProduct && oldProduct[key]) ? colContent(oldProduct[key]) : null;
    const compareRule = (() => {
        if (oldProduct) {
            if (Array.isArray(newProduct[key]))
                return compareArrays(newProduct[key], oldProduct[key]);
            return newProduct[key] !== oldProduct[key];
        }
        return true
    })();

    return <>
        <div className="pud-col" style={compareRule ? {backgroundColor: `yellow`} : {}}>{key}</div>
        { oldProduct && <div className="pud-col">{oldValue || '-'}</div>}
        <div className="pud-col">{newValue || oldValue || '-'}</div>
    </>
}

function LinksList(props) {
    const { links } = props;
    return links.map( link => {
        return <div><a href={link} target="_blank" rel="noopener noreferrer">
            {link}
        </a></div>
    })
}

function mapStateToProps(state) {
	return {
        navCounters: state.navCounters,
	};
}

function mapDispatchToProps(dispatch) {
	return {
        updateNavCounters: bindActionCreators(updateNavCounters, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Experts);
