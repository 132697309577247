import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React, { useState } from 'react'
import APIrequest from '../../../APIcalls/APIrequest';

export default function UpgradeToClub({user, onSubmit}) {
    const initialAbout = (user.expertInfo && user.expertInfo.aboutMe) || "";
    const [name, setName] = useState(user.name);
    const [about, setAbout] = useState(initialAbout);
    const [order, setOrder] = useState(user.order);
    const [communityEnabled, setCommunityEnabled] = useState(true);

    const handleChangeName = (event) => {
        setName(event.target.value);
    }

    const handleChangeAbout = (event) => {
        setAbout(event.target.value);
    }

    const handleChangeOrder = (event) => {
        setOrder(event.target.value);
    }

    const handleChangeComEnabled = (event) => {
        setCommunityEnabled(event.target.checked);
    };

    const upgradeHandle = async () => {
        try {
            if (!!user.expertInfo.clubId) {
                alert("Already club profile!");
                return false;
            }
            const data = {
                about: about,
                communityEnabled: communityEnabled,
                idAdmin: user.idUser,
                imageLink: user.imageLink,
                name: name,
                order: order,
            }
            const res = await APIrequest.save_club(data);
            if (res.status === 200) {
                alert("Success!");
                onSubmit();
            }
        } catch (error) {
            alert("error")
            console.log(error);
        }
    }

    return (
       <div className="user-table">
            <div className="user-table-header-container">
                <span className="user-table-header">
                    Upgrade to club
                </span>
            </div>
            <div className="flex-col" style={{margin: '16px 16px 40px'}}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={handleChangeName}
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="About"
                    value={about}
                    onChange={handleChangeAbout}
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Order"
                    value={order}
                    onChange={handleChangeOrder}
                    margin="normal"
                    variant="outlined"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={communityEnabled}
                        onChange={handleChangeComEnabled}
                        name="communityEnabled"
                        color="primary"
                    />
                    }
                    label="Community Enabled"
                />
                <Button style={{marginTop: '16px'}} onClick={upgradeHandle} variant="contained" color="primary">
                    Submit
                </Button>
            </div>
        </div>
    )
}
