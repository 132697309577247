import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import Button from "@material-ui/core/Button";
import { DatePicker } from '@material-ui/pickers';

export default class FundsStats extends Component {

    constructor(props) {
        super(props);
        this.getCharityStats = this.getCharityStats.bind(this);

        this.state = {
            startDate: null,
            endDate: null,
            fundsInfo: [],
            usersInfo: [],
            totalIncome: 0
        }
        this.getCharityStats();
    }

    handleChange = name => event => {
        this.setState({ [name]: event });
    };

    async getCharityStats() {
        try {
            const data = {
                page: 0,
                size: null
            };

            if (this.state.startDate) data.startDate = this.state.startDate;
            if (this.state.endDate) data.endDate = this.state.endDate;

            const response = await APIrequest.get_charity_stats(data);
            this.setState({
                fundsInfo: response.data.fundsInfo,
                usersInfo: response.data.usersInfo,
                totalIncome: response.data.totalIncome
            })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div className="funds-stats">
                <div className="funds-stats_header">
                    <div className="date-pickers">
                        <div className="flex-col">
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">Start date</div>
                                <DatePicker value={this.state.startDate} onChange={this.handleChange('startDate')} />
                            </div>
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">End date</div>
                                <DatePicker value={this.state.endDate} onChange={this.handleChange('endDate')} />
                            </div>
                            <Button style={{marginTop: '14px'}} variant="contained" color="primary" onClick={this.getCharityStats}>Apply</Button>
                        </div>
                    </div>
                    <div className="title-1">Total income: {this.state.totalIncome.toFixed(2)}$</div>
                </div>
                <div className="funds-table">
                    <div className="funds-table-item">idFund</div>
                    <div className="funds-table-item">name</div>
                    <div className="funds-table-item">imageURL</div>
                    <div className="funds-table-item">description</div>
                    <div className="funds-table-item">sum, $</div>
                    { this.state.fundsInfo.map( fund => {
                        let fundRow = [];
                        Object.entries(fund.charityFundDTO).forEach(([key, value]) => {
                            if (key === 'imageURL') fundRow.push(<div className="funds-table-item"><img className="funds-table-img" src={value} alt="fund"/></div>)
                            else fundRow.push(<div className="funds-table-item">{value}</div>)
                        })
                        fundRow.push(<div className="funds-table-item">{fund.sum.toFixed(2)}</div>)
                        return fundRow;
                    })}
                </div>
                <div className="funds-table">
                    <div className="funds-table-item">idUser</div>
                    <div className="funds-table-item">name</div>
                    <div className="funds-table-item">charityName</div>
                    <div className="funds-table-item">percent, %</div>
                    <div className="funds-table-item">sum, $</div>
                    { this.state.usersInfo.map( user => {
                        return Object.entries(user).map(([key, value]) => {
                            if (key === 'sum') return <div className="funds-table-item">{value.toFixed(2)}</div>
                            else return<div className="funds-table-item">{value}</div>
                        })
                    })}
                </div>
            </div>
        )
    }
}
