import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import UniCard from '../UniCard/UniCard';

export default class Transaction extends Component {

    constructor(props) {
        super(props);
        this.getBankDetails = this.getBankDetails.bind(this);

        this.state = {
            datails: {}
        };

        this.getBankDetails(this.props.match.params.id);
    }

    async getBankDetails(idUser) {
        try {
            const response = await APIrequest.get_bank_details(idUser);
            this.setState({
                datails: response.data
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {
        return (
            <div className="transaction">
                <UniCard obj={this.state.datails}/>
            </div>
        )
    }
}
