import { Button, TextField } from '@material-ui/core';
import React, { Component } from 'react'
import APIrequest from '../../APIcalls/APIrequest';
import ExecutePhysicalBtn from './ExecutePhysicalBtn';
import ExecuteVGABtn from './ExecuteVGABtn';

export default class ExecuteBtn extends Component {

    constructor(props) {
        super(props);
        this.executeBtnHandler = this.executeBtnHandler.bind(this);
        this.sellVideoGreeting = this.sellVideoGreeting.bind(this);

        this.state = {
            answerMsg: {},
            msgInputVal: "",
            executeMode: false,
            uploadPercent: null,
            isFetching: false,
        }
    }

    handleChange = key => event => {
		this.setState({ [key]: event.target.value });
	};

    formExecuteVGAdata() {
		const { answerMsg, msgInputVal } = this.state;
		let data = answerMsg.answer.file;
		const details = {
			idTransaction: answerMsg.id,
			text: msgInputVal,
			md5: answerMsg.answer.md5,
		};
		data.append("details", JSON.stringify(details));
		const uploadPercent = (percent) => {
            if (percent === 100)
                this.setState({ uploadPercent: "Submited!" });
            else this.setState({ uploadPercent: `${percent}%` });
		};
		return [data, uploadPercent];
    }
    
    executeBtnHandler() {
        try {
            this.setState({ isFetching: true });
            const productType = this.props.product.object.product.productType;
            switch (productType) {
                case "VIDEO_GREETING":
                    this.sellVideoGreeting();
                    break;
                case "AUTOGRAPH":
                    this.sellAutograph();
                    break;
                case "PHYSICAL":
                    this.sellPhysical();
                    break;
                default:
                    break;
            }
        } catch (error) {
            this.setState({ isFetching: false });
            alert("error")
            console.log(error); 
        }
    }

    async sellVideoGreeting() {
        try {
            const [data, uploadPercent] = this.formExecuteVGAdata();
            const idUser = this.props.product.partnerId;
            const response = await APIrequest.sell_video_greeting(data, idUser, uploadPercent);
            if (response.status === 200) {
                this.setState({ uploadPercent: "Submited!" });
            }
            this.setState({ isFetching: false });
        } catch (error) {
            this.setState({ isFetching: false });
            alert("error");
            console.log(error);
        }
    }

    async sellAutograph() {
        try {
            const [data, uploadPercent] = this.formExecuteVGAdata();
            const idUser = this.props.product.partnerId;
            const response = await APIrequest.sell_autograph(data, idUser, uploadPercent);
            if (response.status === 200)
                alert("Success!")
            this.setState({ isFetching: false });
        } catch (error) {
            this.setState({ isFetching: false });
            alert("error");
            console.log(error);
        }
    }

    async sellPhysical() {
        try {
            const [data, uploadPercent] = this.formExecuteVGAdata();
            const idUser = this.props.product.partnerId;
            const response = await APIrequest.sell_physical(data, idUser, uploadPercent);
            if (response.status === 200)
                alert("Success!")
            this.setState({ isFetching: false });
        } catch (error) {
            this.setState({ isFetching: false });
            alert("error");
            console.log(error);
        }
    }

    render() {
        const { product } = this.props;
        const { executeMode, answerMsg, msgInputVal, uploadPercent, isFetching } = this.state;
        const executeBtnDisabled = !!!answerMsg.answer || isFetching;

        return product.status === "IN_PROGRESS" && product.operationType === "PRODUCT_REQUEST" ? (
            <div className="execute-container">
                { executeMode ?
                    <>
                        <ExecuteVGABtn
                            product={product}
                            setAnswerMsg={(data) => {this.setState({ answerMsg: data})}}
                        />
                        <ExecutePhysicalBtn
                            product={product}
                            setAnswerMsg={(data) => {this.setState({ answerMsg: data})}}
                        />
                        <TextField
                            className="answer-input"
                            rows="2"
                            label="Answer"
                            value={msgInputVal}
                            variant="outlined"
                            multiline
                            onChange={this.handleChange('msgInputVal', 'value')}
                        />
                        <Button disabled={executeBtnDisabled} onClick={this.executeBtnHandler} variant="contained" color="secondary">{uploadPercent || "Submit"}</Button>
                    </>
                :
                    <Button style={{ width: '200px' }} onClick={() => this.setState({ executeMode: true })} variant="contained" color="primary">Execute</Button>
                }
            </div>
        ) : null
    }
}
