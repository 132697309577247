import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/gallery.css';

// components
import UploadPhoto from "components/upload photo/UploadPhoto";

// libs
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import ReactCrop from 'react-image-crop';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import 'react-image-crop/dist/ReactCrop.css';

// imgs 
import DEFAULT_IMG_COVER from 'imgs/cover_autograph@2x.png';

class Gallery extends Component {

    constructor(props) {
        super(props);
        this.uploadImg = this.uploadImg.bind(this);

        this.state = {
            image: null,
            mode: 'viewProducts',
            editCoverMode: false,
            gallery: this.props.user.gallery ? this.props.user.gallery : [null, null, null, null],
            selectedImage: null
        };
    }

    handleChangeCoverMode(event) {
        this.setState({
            editCoverMode: event
        })
    }

    async uploadImg(formData) {
        formData.append('info', JSON.stringify({
            type: 'ADULT_GALLERY'
        }));
        try {
            const response = await APIrequest.upload_some_image(formData);
            let newGalleryArray = this.state.gallery;
            newGalleryArray[this.state.selectedImage] = response.data.result;
            let data = {
                gallery: newGalleryArray,
            }
            await APIrequest.update_user(data, this.props.user.idUser);
            alert("img uploaded! plz, finish updating gallery info and press submit");
            this.setState({
                // image: response.data.result,
                editCoverMode: false,
                selectedImage: null,
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {
        console.log(this.props.user.gallery);

        return (
            <div className="add-user-table">
                <div className="user-table-header-container">
                    <span className="user-table-header">
                        Set up products
                    </span>
                </div>
                <div className="add-new-user">
                    <div className="add-new-user-form flex-col">
                        {this.state.editCoverMode ?
                            <UploadPhoto photoIsUploadedCallbak={this.uploadImg} type="flexibleAspect"/>
                            :
                            <div className="flex-col">
                                {this.state.gallery.map( (photo, index) => {
                                    return <div className="gallery-cover-edit" onClick={() => this.setState({ selectedImage: index }, () => this.handleChangeCoverMode(true)) }>
                                        <div className="gallery-cover-edit-label">Edit</div>
                                        <img src={photo ? photo : DEFAULT_IMG_COVER} alt="gallery cover" />
                                    </div>
                                })}
                                <Button className="register-new-user-btn" variant="contained" color="primary" onClick={this.props.userUpdatedCallback}>
                                    Submit
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Gallery;