import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import UniCard from '../UniCard/UniCard';
import './css/usersScore.css';
import { Button } from '@material-ui/core';

export default class UsersScores extends Component {

    constructor(props) {
        super(props);
        this.getScores = this.getScores.bind(this);
        this.deleteScore = this.deleteScore.bind(this);

        this.state = {
            scores: []
        }

        this.getScores();
    }

    async getScores(userID) {
        try {
            const response = await APIrequest.get_scores(this.props.userID);
            this.setState({
                scores: response.data
            })
        } catch (error) {
            console.log(error);
        }
    }

    async deleteScore(scoreID) {
        try {
            await APIrequest.hide_score(scoreID);
            this.getScores();
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div className="users-scores">
                {this.state.scores.map( score => 
                    <div className="users-score">
                        <UniCard obj={score}/>
                        <Button style={{margin: '16px 16px 40px'}} onClick={() => this.deleteScore(score.idScore)} variant="contained" color="secondary">
                            Hide
                        </Button>
                    </div>
                )}
            </div>
        )
    }
}