import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import UploadPhoto from "components/upload photo/UploadPhoto";
import './css/funds.css';

// libs
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import CardActionArea from '@material-ui/core/CardActionArea';

// imgs
import DEFAULT_IMG_COVER from 'imgs/cover_autograph@2x.png';

class Funds extends Component {

    constructor(props) {
        super(props);
        this.addCharityFund = this.addCharityFund.bind(this);
        this.deleteCharityFund = this.deleteCharityFund.bind(this);
        this.uploadImg = this.uploadImg.bind(this);

        this.state = {
            funds: [],
            selectedFund: null,
            imageLink: null
        };
        this.getCharityFunds();
    }

    handleChange(name, value) {
        this.setState({
            [name]: value,
        });
    };

    handleChangeObjState = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;

        let newState = Object.assign({}, this.state[name[0]]);
        if (name.length === 2) {
            newState[name[1]] = value;
        }
        else if (name.length === 3) {
            newState[name[1]][name[2]] = value;
        }
        this.setState({
            selectedFund: newState
        })
    };

    async getCharityFunds() {
        try {
            const response = await APIrequest.get_charity_funds();
            this.setState({
                funds: response.data
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async deleteCharityFund(fondId) {
        let data = {
            "idFund": "string"
        }

        try {
            await APIrequest.delete_charity_fund(data);
            alert("Category deleted!");
            this.setState({
                selectedFund: null
            });
            this.getCategories();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async addCharityFund() {
        let data = this.state.selectedFund;
        data.imageURL = this.state.imageLink;
        
        try {
            await APIrequest.add_charity_fund(data);
            alert("Fund changed!");
            this.setState({
                selectedFund: null
            });
            this.getCharityFunds();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async uploadImg(formData) {
        formData.append('info', JSON.stringify({
            type: 'FUND'
        }));
        try {
            const response = await APIrequest.upload_some_image(formData);
            this.setState({
                imageLink: response.data.result,
                editCoverMode: false
            });
            alert("img uploaded! plz, finish updating fund info and press save");
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {

        return (
            <div className="funds">
                <div className="page-header-container">
                    <Typography variant="h4" component="h2">
                        Funds
                    </Typography>
                </div>
                <div className="page-content">
                    {this.state.selectedFund ? 
                        <div className="edit-fund">
                            <TextField
                                label="Text"
                                value={this.state.selectedFund.name}
                                onChange={this.handleChangeObjState(["selectedFund", "name"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Name En"
                                value={this.state.selectedFund.localizedName.en}
                                onChange={this.handleChangeObjState(["selectedFund", "localizedName", "en"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Name Ru"
                                value={this.state.selectedFund.localizedName.ru}
                                onChange={this.handleChangeObjState(["selectedFund", "localizedName", "ru"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Name Uk"
                                value={this.state.selectedFund.localizedName.uk}
                                onChange={this.handleChangeObjState(["selectedFund", "localizedName", "uk"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Description En"
                                value={this.state.selectedFund.localizedDescription.en}
                                onChange={this.handleChangeObjState(["selectedFund", "localizedDescription", "en"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Description Ru"
                                value={this.state.selectedFund.localizedDescription.ru}
                                onChange={this.handleChangeObjState(["selectedFund", "localizedDescription", "ru"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Description Uk"
                                value={this.state.selectedFund.localizedDescription.uk}
                                onChange={this.handleChangeObjState(["selectedFund", "localizedDescription", "uk"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            {this.state.editCoverMode ?
                                <UploadPhoto photoIsUploadedCallbak={this.uploadImg} />
                                :
                                <div className="flex-col">
                                    <div className="product-cover-edit" onClick={() => this.setState({ editCoverMode: true })}>
                                        <div className="product-cover-edit-label">Edit</div>
                                        <img src={this.state.imageLink ? this.state.imageLink : DEFAULT_IMG_COVER} alt="Product cover" />
                                    </div>
                                </div>
                            }
                            <Button className="edit-fund-btn" variant="contained" color="primary" onClick={this.addCharityFund}>save</Button>
                            <Button className="edit-fund-btn" variant="contained" color="secondary" onClick={this.deleteCharityFund.bind(this, this.state.selectedFund.idFund)}>delete</Button>
                        </div>
                    :
                    <div className="funds-cards">
                        {this.state.funds.map( a => {
                            return <Card className="funds-card">
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {a.name}
                                    </Typography>
                                    <br/>
                                    <Typography color="textSecondary">
                                        Description: {a.localizedDescription.en}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        ID: {a.idFund}
                                    </Typography>
                                </CardContent>
                                <CardActions className="flex-col">
                                    <Button fullWidth size="small" onClick={() => this.setState({ selectedFund: a, imageLink: a.imageURL })}>Edit</Button>
                                </CardActions>
                            </Card>
                        })}
                        <Card className="funds-card">
                            <CardActionArea 
                                className="add-new-fund-CAA" 
                                onClick={() => this.setState({ 
                                    selectedFund: { 
                                        "name": "", 
                                        "localizedName": { "ru": '', "en": '', "uk": '' }, 
                                        "imageURL": null,
                                        "localizedDescription": { "ru": '', "uk": '', "en": '' }, 
                                    },
                                    imageLink: null
                                })}
                            >
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    Add new fund
                                </Typography>
                            </CardContent>
                            </CardActionArea>
                        </Card>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export default Funds;