import React from 'react';
import "./css/subscriptions.css";
import SubscriptionTile from './SubscriptionTile';
import safeKey from 'functions/safeKey.js';

export default function Subscriptions(props) {
    const { user, userUpdatedCallback } = props;
    const availableSubscriptions = safeKey(() => user.feedInfo.availableSubscriptions);


    return availableSubscriptions ? (
        <div className="user-table">
            <div className="user-table-header-container">
                <span className="user-table-header">Subscriptions</span>
            </div>
            <div className="user-subscriptions">
                {availableSubscriptions.map( sub => <SubscriptionTile sub={sub} idUser={user.idUser} userUpdatedCallback={userUpdatedCallback} /> )}
            </div>
        </div>
    ) : null;
}
