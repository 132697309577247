import React, { Component } from "react";
import APIrequest from "APIcalls/APIrequest";
import * as updateNavCounters from "redux/actions/NavCountersActions";
import "./css/withdraw.css";

// redux
import * as storeUserInfo from "redux/actions/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// libs
import * as moment from "moment";
import Button from "@material-ui/core/Button";

// imgs
import RefreshIcon from "imgs/refresh.svg";
import DefaultProfileIcon from "imgs/user-icon.svg";
import { Link } from "react-router-dom";

class Withdraw extends Component {
	constructor(props) {
		super(props);
		this.generateTableRows = this.generateTableRows.bind(this);
		this.approveTransaction = this.approveTransaction.bind(this);

		this.state = {
			transactionRows: null,
			status: "IN_PROGRESS"
		};
	}

	componentDidMount() {
		this.getWithdrawInfo();
	}

	generateTableRows(records) {
		let rows = [];
		records.forEach(a => {
			let partnerName = a.partnerName;
			if (a.partnerName === null && a.transactionInfo.currency === "USDT") {
				partnerName = "PayPhone account";
			}
			let row = (
				<Link to={'/withdraw/' + a.partnerId} className="withdraw-table-row" key={a.idTransaction}>
					<div className="withdraw-table-col withdraw-table-col-user">
						<img className="withdraw-table-usr-img" src={a.partnerImage ? a.partnerImage : DefaultProfileIcon} alt="wd" />
						<div className="withdraw-table-col-user-text">
							<span className="withdraw-table-col-val">{partnerName}</span>
							<span className="withdraw-table-col-subval">{a.partnerId}</span>
						</div>
					</div>
					<div className="withdraw-table-col withdraw-table-col-time">
						<span className="withdraw-table-col-val">{moment(a.transactionStartDate).format("DD MMMM YYYY HH:mm")}</span>
						<span className="withdraw-table-col-subval">{a.idTransaction}</span>
					</div>
					<div className="withdraw-table-col withdraw-table-col-sel-cur">
						<span className="withdraw-table-col-val">{a.transactionInfo.currency}</span>
						<span className="withdraw-table-col-subval">{a.transactionInfo.amount ? Number(a.transactionInfo.amount.toFixed(4)) : null}</span>
					</div>
					<div className="withdraw-table-col withdraw-table-col-usd">
						<span className="withdraw-table-col-val">{a.amount ? Number(a.amount.toFixed(4)) + " $" : null}</span>
					</div>
					{a.approvedPayment === true ? (
						<div className="withdraw-table-col">
							<span className="payment-approved">Payment approved</span>
							{a.transactionInfo.paymentId || a.transactionInfo.txId ? (
								<span className="withdraw-table-col-subval">
									paymentId: {a.transactionInfo.paymentId}
									<br />
									txId: {a.transactionInfo.txId}
								</span>
							) : null}
						</div>
					) : (
						<div className="withdraw-table-col withdraw-table-col-btns">
							<Button onClick={this.approveTransaction.bind(this, a.idTransaction, true)} variant="outlined" color="primary" className="withdraw-table-btn">
								Approve
							</Button>
							<Button onClick={this.approveTransaction.bind(this, a.idTransaction, false)} variant="outlined" color="secondary" className="withdraw-table-btn">
								Deny
							</Button>
						</div>
					)}
				</Link>
			);
			rows.push(row);
		});
		this.setState({
			transactionRows: rows
		});
	}

	async getWithdrawInfo() {
		let data = {
			result: this.state.status === "ALL" ? null : this.state.status
		};

		try {
			let response = await APIrequest.get_withdraw_requests(data);
			this.generateTableRows(response.data);
		} catch (error) {
			console.log(error);
		}
	}

	async approveTransaction(transactionID, decision) {
		try {
			await APIrequest.approve_withdraw(transactionID, decision);
			this.getWithdrawInfo();
            this.getWithdrawRequestsCount();
			alert("Success!");
		} catch (error) {
			alert("Error! Try again");
			console.log(error);
		}
	}

    async getWithdrawRequestsCount() {
        try {
            const response = await APIrequest.get_withdraw_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ withdraw: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

	render() {

		return (
			<div className="withdraw">
				<div className="withdraw-container">
					<div className="withdraw-header-container">
						<span className="withdraw-header">Withdraw info</span>
						<img onClick={this.getWithdrawInfo.bind(this)} src={RefreshIcon} alt="refresh"/>
					</div>
					<div className="status-btns">
						<Button
							onClick={() => {
								this.setState({ status: "COMPLETED" }, () => this.getWithdrawInfo());
							}}
							variant="contained"
							color="primary"
							className="experts-table-btn"
						>
							COMPLETED
						</Button>
						<Button
							onClick={() => {
								this.setState({ status: "ROLLBACK" }, () => this.getWithdrawInfo());
							}}
							variant="contained"
							style={{ backgroundColor: "#2fb87b", color: "#ffffff" }}
							className="experts-table-btn"
						>
							ROLLBACK
						</Button>
						<Button
							onClick={() => {
								this.setState({ status: "IN_PROGRESS" }, () => this.getWithdrawInfo());
							}}
							variant="contained"
							color="secondary"
							className="experts-table-btn"
						>
							IN_PROGRESS
						</Button>
						<Button
							onClick={() => {
								this.setState({ status: "ALL" }, () => this.getWithdrawInfo());
							}}
							variant="contained"
							color="default"
							className="experts-table-btn"
						>
							All
						</Button>
					</div>
					<div className="withdraw-table">
						<div className="withdraw-table-row withdraw-table-row-header">
							<div className="withdraw-table-col withdraw-table-col-user withdraw-table-col-user-header">
								<img className="withdraw-table-usr-img" alt="i"/>
								<div className="withdraw-table-col-user-text">
									<span className="withdraw-table-col-val">User Name</span>
									<span className="withdraw-table-col-subval">User ID</span>
								</div>
							</div>
							<div className="withdraw-table-col withdraw-table-col-time">
								<span className="withdraw-table-col-val">Transaction time</span>
								<span className="withdraw-table-col-subval">Transaction ID</span>
							</div>
							<div className="withdraw-table-col withdraw-table-col-sel-cur">
								<span className="withdraw-table-col-val">Selected currency</span>
								<span className="withdraw-table-col-subval">Amount</span>
							</div>
							<div className="withdraw-table-col withdraw-table-col-usd">
								<span className="withdraw-table-col-val">Amount in USD</span>
							</div>
							<div className="withdraw-table-col withdraw-table-col-btns"></div>
						</div>
						{this.state.transactionRows}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
        navCounters: state.navCounters,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
        updateNavCounters: bindActionCreators(updateNavCounters, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);
