import React, { Component } from 'react'
import APIrequest from 'APIcalls/APIrequest';
import productTypes from "configs/productTypes";

// libs
import { DatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TransLogRows from './TransLogRows';
import { Button, MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class TransLog extends Component {

    constructor(props) {
        super(props);
        this.getTransactionLog = this.getTransactionLog.bind(this);
        this.showMoreHandler = this.showMoreHandler.bind(this);
        this.applyDateFilterHandler = this.applyDateFilterHandler.bind(this);

        this.state = {
            transLogs: [],
            endDate: null,
            startDate: null,
            transLogProductType: "ALL",
            hideLoadMoreBtn: false,
        };

        this.page = 0;

        this.getTransactionLog();
    }

    handleChange = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;
        this.setState({
            [name]: value,
        });
    };

    handleChangeTransLogProductType = (event) => {
        const selectedProductType = event.target.value;
        this.page = 0;

        this.setState({
            transLogProductType: selectedProductType,
            transLogs: [],
            hideLoadMoreBtn: false,
        }, () => {
            this.getTransactionLog();
        });
    };

    showMoreHandler() {
        this.page += 1;
        this.getTransactionLog();
    }

    async getTransactionLog() {
        try {
            let userID = APIrequest.get_url_token(this.props.location.pathname);
            let data = {
                "page": this.page,
                "size": 40,
            }

            if (this.state.startDate) data.startDate = this.state.startDate;
            if (this.state.endDate) data.endDate = this.state.endDate;

            let response = await APIrequest.transaction_log(userID, data, this.state.transLogProductType);
            this.setState({ 
                transLogs: [...this.state.transLogs, ...response.data.items],
                hideLoadMoreBtn: this.page + 1 >= response.data.totalPages,
            });
        } catch (error) {
            console.log(error)
        }
    }

    applyDateFilterHandler() {
        this.page = 0;

        this.setState({
            transLogs: [],
            hideLoadMoreBtn: false,
        }, () => {
            this.getTransactionLog();
        });
    }

    render() {
        const { transLogProductType, transLogs, filteredTransLogs, hideLoadMoreBtn } = this.state;

        return (
            <div className="user-table">
                <div className="user-table-header-container">
                    <span className="user-table-header">Transaction logs</span>
                    <FormControl className="product-type-picker" variant="outlined">
                        <InputLabel>Product type</InputLabel>
                        <Select
                            value={transLogProductType}
                            onChange={this.handleChangeTransLogProductType}
                            label="Product type"
                        >
                            {productTypes.map( type => {
                                return <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <div className="date-picker">
                        <div className="flex-col">
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">Start date</div>
                                <DatePicker value={this.state.startDate} onChange={this.handleChange('startDate')} />
                            </div>
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">End date</div>
                                <DatePicker value={this.state.endDate} onChange={this.handleChange('endDate')} />
                            </div>
                            <Button style={{marginTop: '14px'}} variant="contained" color="primary" onClick={this.applyDateFilterHandler}>Apply</Button>
                        </div>
                    </div>
                </div>
                <div className="user-table-row user-table-row-header">
                    <div className="user-table-col user-table-col-transaction">
                        <span className="user-table-col-val">Amount</span>
                        <span className="user-table-col-subval">Transaction ID</span>
                    </div>
                    <div className="user-table-col user-table-col-operation">
                        <span className="user-table-col-val">Type</span>
                        <span className="user-table-col-subval">Operation Status</span>
                    </div>
                    <div className="user-table-col user-table-col-direction">
                        <span className="user-table-col-val">Direction</span>
                        <span className="user-table-col-subval">Status</span>
                    </div>
                    <div className="user-table-col user-table-col-partner">
                        <span className="user-table-col-val">Partner Name</span>
                        <span className="user-table-col-subval">Partner ID</span>
                    </div>
                    <div className="user-table-col user-table-col-date">
                        <span className="user-table-col-val">Transaction start date</span>
                        <span className="user-table-col-subval">Transaction complete date</span>
                    </div>
                </div>
                <TransLogRows logs={filteredTransLogs || transLogs} />
                { !hideLoadMoreBtn && 
                    <div className="center">
                        <Button onClick={this.showMoreHandler}>Load more</Button>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(TransLog);
