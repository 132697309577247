const productTypes = [
    {
        value: 'null',
        label: 'None',
    },
    {
        value: 'ALL',
        label: 'All',
    },
    {
        value: 'CALL',
        label: 'Call',
    },
    {
        value: 'MESSAGE',
        label: 'Message',
    },
    {
        value: 'AUTOGRAPH',
        label: 'Autograph',
    },
    {
        value: 'VIDEO_GREETING',
        label: 'Video greeting',
    },
    {
        value: 'PHYSICAL',
        label: 'Physical',
    },
    {
        value: 'AUTO_REPLY',
        label: 'Auto reply',
    },
    {
        value: 'EVENT',
        label: 'Event',
    },
];

export default productTypes;