import React, { Component } from 'react';
import './css/promocodes.css';
import APIrequest from 'APIcalls/APIrequest';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import promoTypes from "configs/productTypes";

export default class AddPromocodeTile extends Component {

    state = {
        multi: false,
        code: "",
        expirationDate: moment(),
        amount: 0,
        relatedTo: "",
        limit: 0,
        min: 0,
        discount: 0,
        forBoth: false,
        type: 'null'
    }

    async addPromoCode() {
        try {
            const data = {
                multi: this.state.multi,
                code: this.state.code,
                amount: this.state.amount,
                expirationDate: moment(this.state.expirationDate).format('x'),
                relatedTo: this.state.relatedTo === "" ? null : this.state.relatedTo,
                limit: this.state.limit,
                min: this.state.min,
                discount: this.state.discount,
                forBoth: this.state.forBoth,
            }
            if (this.state.type !== 'null') data.type = this.state.type;
            await APIrequest.create_promo_code(data);
            alert("Promocode created!");
            this.props.addedCallback();
        } catch (error) {
            alert("Request failed")
        }
    }

    handleChange = name => event => {
        if (name === "limit" && event.target.value > 1) this.setState({ multi: true });

        if (name === "expirationDate") this.setState({ [name]: event });
        else this.setState({ [name]: event.target.value });
    };

    handleChangeCheckbox = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const { multi, code, expirationDate, amount, relatedTo, limit, min, discount, forBoth, type } = this.state;

        return <div className="promocode-tile">
            <div className="title-4 mb_16">Add new promocode</div>
            <div className="add-new-promo-fields">
                <TextField
                    select
                    label="Select promo type"
                    value={type}
                    onChange={this.handleChange('type')}
                    margin="normal"
                    variant="outlined"
                >
                    {promoTypes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Promo code"
                    onChange={this.handleChange('code', 'value')}
                    value={code}
                />
                <TextField
                    margin="normal"
                    label="Related to (paste expert ID)"
                    onChange={this.handleChange('relatedTo', 'value')}
                    value={relatedTo}
                />
                <TextField
                    disabled={discount > 0}
                    label="Amount, $"
                    onChange={this.handleChange('amount', 'value')}
                    value={amount}
                    margin="normal"
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                    }}
                />
                <TextField
                    disabled={amount > 0}
                    label="Discount, % (0-100)"
                    onChange={this.handleChange('discount', 'value')}
                    value={discount}
                    margin="normal"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 3 }}
                />
                <TextField
                    label="Limit"
                    onChange={this.handleChange('limit', 'value')}
                    value={limit}
                    margin="normal"
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                    }}
                />
                { (this.state.type === "ALL" || this.state.type === "CALL") && 
                    <TextField
                        label="Minutes"
                        onChange={this.handleChange('min', 'value')}
                        value={min}
                        margin="normal"
                        inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                        }}
                    />
                }
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={forBoth}
                            onChange={this.handleChangeCheckbox('forBoth')}
                            value={forBoth}
                        />
                    }
                    label="Charge this promo discount from expert"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={multi}
                            onChange={this.handleChangeCheckbox('multi')}
                            value={multi}
                        />
                    }
                    label="Is multi"
                />
                <DatePicker disablePast value={expirationDate} onChange={this.handleChange('expirationDate')} />
            </div>
            <div className="mt_16">
                <Button variant="contained" color="primary" onClick={() => this.addPromoCode()}>Create</Button>
            </div>
        </div>
    }
}