import React, { Component } from "react";
import safeKey from "functions/safeKey.js";
import { Link } from 'react-router-dom';

// libs
import * as moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// imgs

class ProductCard extends Component {
	render() {
		const { product } = this.props;

		return (
			<Card className="card">
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						<span className="title">{product.operationType}</span> <br />
                        status: {product.status} <br />
                        {product.from && <> from: <Link to={"/user/" + product.from.idUser}>{product.from.name}</Link> <br /> </>}
                        to: <Link to={"/user/" + product.to.idUser}>{product.to.name}</Link> <br />
                        amount: {product.amount} <br />
                        isRated: {String(product.isRated)} <br />
                        question: {safeKey(()=>product.object.question)} <br />
                        answer: {safeKey(()=>product.object.answer)} <br />
						Start: {moment(product.transactionStartDate).format("HH:mm MMMM DD, YYYY")} <br />
                        Complete: {moment(product.transactionCompleteDate).format("HH:mm MMMM DD, YYYY")} <br />
					</Typography>
				</CardContent>
			</Card>
		);
	}
}

export default ProductCard;
