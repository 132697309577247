import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import MomentUtils from '@date-io/moment';
import configureStore from 'redux/store/configureStore';
import Root from './routes/root';
export const store = configureStore();


ReactDOM.render(
    <Provider store={store}> 
        <BrowserRouter>  
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <MuiThemeProvider>
                    <Root />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

unregister();
// registerServiceWorker();
