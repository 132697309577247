import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/transactionsStats.css';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';

class TransactionsStats extends Component {

    constructor(props) {
        super(props);
        this.getTransactionStats = this.getTransactionStats.bind(this);
        this.loadReport = this.loadReport.bind(this);

        this.state = {
            stats: {},
            mode: 'init'
        };
    }

    async getTransactionStats() {
        try {
            const data = {
                page: 0,
                size: null,
            }

            if (this.props.date) {
                data.startDate = this.props.date.startDate;
                data.endDate = this.props.date.endDate;
            }

            const response = await APIrequest.get_transaction_stats(data);
            this.setState({
                stats: response.data,
                mode: 'report'
            })
        } catch (error) {
            console.log(error);
        }
    }

    loadReport(src = 'ref') {
        if (src === 'btn' || this.state.mode !== 'init') {
            this.setState({ mode: 'loading' });
            this.getTransactionStats();
        }
    }

    render() {

        return (
            <div className="trans-stats">
                {(() => { 
                    switch(this.state.mode) { 
                        case 'report': 
                            return <TransactionsStatsTable stats={this.state.stats} />; 
                        case 'init': 
                            return <Button style={{marginTop: '14px'}} variant="contained" color="primary" onClick={() => this.loadReport('btn')}>Load transactions report</Button>; 
                        case 'loading':
                        default: 
                            return <CircularProgress />; 
                    } 
                })()}
            </div>
        );
    }
}

class TransactionsStatsTable extends Component {

    render() {
        const { stats } = this.props;

        return(
            <div className="trans-stats_blank">
                <div className="trans-stats_table">
                    <div></div>
                    <div>Total</div>
                    <div>VIDEO_GREETING</div>
                    <div>PHYSICAL</div>
                    <div>AUTOGRAPH</div>
                    <div>AUTO_REPLY</div>
                    <div>message</div>
                    <div>call</div>
                    {/*  */}
                    <div>incomplete</div>
                    <div>{stats.totalIncompleteRequests}</div>
                    <div>{stats.productRequests.VIDEO_GREETING.incomplete}</div>
                    <div>{stats.productRequests.PHYSICAL.incomplete}</div>
                    <div>{stats.productRequests.AUTOGRAPH.incomplete}</div>
                    <div>-</div>
                    <div>{stats.callAndMessageRequests.message.incomplete}</div>
                    <div>{stats.callAndMessageRequests.call.incomplete}</div>
                    {/*  */}
                    <div>complete</div>
                    <div>{stats.totalCompleteRequests}</div>
                    <div>{stats.productRequests.VIDEO_GREETING.complete}</div>
                    <div>{stats.productRequests.PHYSICAL.complete}</div>
                    <div>{stats.productRequests.AUTOGRAPH.complete}</div>
                    <div>{stats.productRequests.AUTO_REPLY.complete}</div>
                    <div>{stats.callAndMessageRequests.message.complete}</div>
                    <div>{stats.callAndMessageRequests.call.complete}</div>
                    {/*  */}
                    <div>canceled</div>
                    <div>{stats.totalCanceledRequests}</div>
                    <div>{stats.productRequests.VIDEO_GREETING.canceled}</div>
                    <div>{stats.productRequests.PHYSICAL.canceled}</div>
                    <div>{stats.productRequests.AUTOGRAPH.canceled}</div>
                    <div>-</div>
                    <div>{stats.callAndMessageRequests.message.canceled}</div>
                    <div>{stats.callAndMessageRequests.call.canceled}</div>
                </div>
            </div>
        )
    }
}

export default TransactionsStats