import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import APIrequest from 'APIcalls/APIrequest';

export default class BankDetails extends Component {

    state={};

    componentDidMount() {
        this.setState(this.props.bankDetails)
    }

    componentDidUpdate(prevProps) {
        if (this.props.bankDetails !== prevProps.bankDetails) {
            this.setState(this.props.bankDetails)
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    async updateUser() {
        try {
            await APIrequest.update_user({ bankDetails: this.state}, this.props.userID);
            alert("User is updated successful");
        } catch (error) {
            
        }
    }

    render() {

        return (
            <div className="add-user-table">
                <div className="user-table-header-container">
                    <span className="user-table-header">
                        Bank Details
                    </span>
                </div>
                <div className="flex-col">
                    {Object.entries(this.state).map(([key, value]) => {
                        return key === "dateOfBirth" ? null : <TextField
                            key={key}
                            label={key}
                            value={this.state[key]}
                            onChange={this.handleChange(key, "value")}
                            margin="normal"
                            variant="outlined"
                        />
                    })}
                    <Button style={{margin: '16px 16px 40px'}} onClick={this.updateUser.bind(this)} variant="contained" color="primary">
                        Submit
                    </Button>
                </div>
            </div>
        )
    }
}
