import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/answer.css';
import safeKey from 'functions/safeKey.js'

// libs
import * as moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

// imgs

class commentCard extends Component {

    constructor(props) {
        super(props);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        this.state = {
        anchorEl: null
        };
    }

    async forumRemovePost() {
        try {
            await APIrequest.forum_remove_post('COMMENT', this.props.comment.idComment);
            this.props.updateCallback();
        } catch (error) {
        alert("Network error. Try again later")
        }
    }

    openMenu(event) {
        this.setState({
        anchorEl: event.currentTarget
        })
    }

    closeMenu(event) {
        this.setState({ anchorEl: null })
    }

    render() {
        const { comment } = this.props;

        return <Card className="comment-card">
            <CardHeader
                avatar={
                    comment.author.imageLink ? 
                        <img className="comment-author-imageLink" src={comment.author.imageLink} alt={comment.author.name}/>
                    :
                        <Avatar aria-label="recipe">
                            {comment.author.name.charAt(0).toUpperCase()}
                        </Avatar>
                }
                action={
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={this.openMenu}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={comment.author.name}
                subheader={moment(comment.created).format("HH:mm MMMM DD, YYYY")}
            />
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={() => this.closeMenu(false)}
            >
                <MenuItem onClick={() => { this.closeMenu(false); this.forumRemovePost() }}>Delete</MenuItem>
                {this.props.linkToTopic ?
                    <MenuItem onClick={() => this.props.history.push('/forum/topic?id=' + comment.idTopic + '&anchor=' + comment.idComment)}>Link to topic</MenuItem>
                : 
                    <MenuItem onClick={() => this.props.history.push('/user/' + comment.author.idUser)}>Author profile</MenuItem>
                }
                <MenuItem disabled onClick={() => this.closeMenu(false)}>Hide</MenuItem>
                <MenuItem disabled onClick={() => this.closeMenu(false)}>Edit</MenuItem>
            </Menu>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {this.props.showType ?
                        <span>Comment: </span>
                    : null}
                    <span className="comment-text">{comment.actualContent.text}</span> <br/>
                    Modified: {moment(comment.modified).format("HH:mm MMMM DD, YYYY")} <br/>
                    Content is modified: {String(comment.isContentModified)} <br/>
                    Status: {safeKey(() => comment.status)} <br />
                    ID: {safeKey(() => comment.idComment)}
                </Typography>
            </CardContent>
            <div className="card-footer">
                <div className="footer-counter">
                    <span className="count">{String(comment.totalLikes)}</span>
                    <FavoriteIcon />
                </div>
            </div>
        </Card>
    }
}

export default commentCard
