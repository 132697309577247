import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import { Link } from 'react-router-dom';
import './css/search.css';
import safeKey from "functions/safeKey";
import { escapeSpecialCharacters } from 'functions/escapeSpecialCharacters';
import validateEmail from 'functions/validateEmail';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import * as moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import SearchBar from 'material-ui-search-bar';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// imgs
import DefaultProfileIcon from 'imgs/user-icon.svg';

class Search extends Component {

    constructor(props) {
        super(props);
        this.generateTableRows = this.generateTableRows.bind(this);
        this.handleTermChange = this.handleTermChange.bind(this);

        this.state = {
            searchResultsTable: [],
            onlyExperts: true,
            searchTerm: "",
        };
        this.timeout = 0;
    }

    componentDidMount() {
        // this.getsearchInfo()
    }

    handleCheckboxChange = name => event => {
        this.setState({ [name]: event.target.checked });
        this.handleTermChange(this.state.searchTerm);
    };

    generateTableRows(users) {
        this.setState({
            searchResultsTable: users.map(user => {
                return <Link to={'/user/' + user.idUser} className="search-table-row" key={user.idUser}>
                        <div className="search-table-col search-table-col-user">
                            <img className="search-table-usr-img" src={user.imageLink ? user.imageLink : DefaultProfileIcon} alt="profile" />
                            <div className="search-table-col-user-text">
                                <span className="search-table-col-val">{user.name}</span>
                                <span className="search-table-col-subval">{user.idUser}</span>
                            </div>
                        </div>
                        <div className="search-table-col search-table-col-work">
                            <span className="search-table-col-val">{safeKey(() => user.expertInfo.work)}</span>
                        </div>
                        <div className="search-table-col search-table-col-time">
                            <span className="search-table-col-val">{moment(user.lastUpdatedDate).format('DD MMMM YYYY HH:mm')}</span>
                            <span className="search-table-col-subval">{moment(user.registrationDate).format('DD MMMM YYYY HH:mm')}</span>
                        </div>
                        <div className="search-table-col search-table-col-status">
                            <span className="search-table-col-val">{user.status}</span>
                        </div>
                    </Link>
                })
        })
    }

    async handleTermChange(term) {
        if (term.length > 2) {
            if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(async () => { // send req not more often than 400ms
                if (validateEmail(term)) {
                    let validFirstPartOfEmail = term.split('@')[0].split('.').join("").split('+')[0]; // replace '.' and '+' before '@'. cut all after '+'
                    term = validFirstPartOfEmail + '@' + term.split('@')[1];
                }
                let data = {
                    "result": escapeSpecialCharacters(term)
                }
                try {
                    let response = await APIrequest.find_users_by_query(data, this.state.onlyExperts);
                    this.generateTableRows(response.data);
                } catch (error) {
                    console.log(error)
                }
            }, 400); 
        }
        else this.generateTableRows([]);
    }

    render() {

        return (
            <div className="search">
                <div className="search-container">
                    <div className="search-header-container">
                        <span className="search-header">
                            Search PayPhone users
                        </span>
                        <SearchBar
                            value={this.state.searchTerm}
                            onRequestSearch={() => console.log('onRequestSearch')}
                            onChange={(term) => {
                                this.setState({ searchTerm: term });
                                this.handleTermChange(term);
                            }}
                            style={{
                                margin: '0 auto',
                                width: 500
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.onlyExperts}
                                    onChange={this.handleCheckboxChange('onlyExperts')}
                                    value="onlyExperts"
                                />
                            }
                            label="Only experts"
                        />
                    </div>
                    {this.state.searchResultsTable.length > 0 &&
                        <div className="search-table">
                            <div className="search-table-row search-table-row-header">
                                <div className="search-table-col search-table-col-user search-table-col-user-header">
                                    <img className="search-table-usr-img" alt="none" />
                                    <div className="search-table-col-user-text">
                                        <span className="search-table-col-val">User Name</span>
                                        <span className="search-table-col-subval">User ID</span>
                                    </div>
                                </div>
                                <div className="search-table-col search-table-col-work">
                                    <span className="search-table-col-val">Work</span>
                                </div>
                                <div className="search-table-col search-table-col-time">
                                    <span className="search-table-col-val">Last update date</span>
                                    <span className="search-table-col-subval">Registration date</span>
                                </div>
                                <div className="search-table-col search-table-col-status">
                                    <span className="search-table-col-val">Status</span>
                                </div>
                            </div>
                            {this.state.searchResultsTable}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
