import React, { Component } from 'react';
import './css/groups.css';
import APIrequest from 'APIcalls/APIrequest';

// libs
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import CardActionArea from '@material-ui/core/CardActionArea';

export default class Groups extends Component {

    constructor(props) {
        super(props);
        this.addGroup = this.addGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.state = {
            groups: [],
            selectedGroup: null
        };
        this.getGroups();
    }

    handleChangeObjState = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;

        let newState = Object.assign({}, this.state[name[0]]);
        if (name.length === 2) {
            newState[name[1]] = value;
        }
        else if (name.length === 3) {
            newState[name[1]][name[2]] = value;
        }
        this.setState({
            selectedGroup: newState
        })
    };

    async getGroups() {
        try {
            const response = await APIrequest.get_groups_full();
            this.setState({
                groups: response.data
            });
        } catch (error) {
            console.log(error)
        };
    }

    async addGroup() {
        try {
            const data = this.state.selectedGroup;
            await APIrequest.add_group(data);
            alert("Group changed!");
            this.setState({
                selectedGroup: null
            });
            this.getGroups();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async deleteGroup(idGroup) {
        try {
            const data = {
                idGroup: idGroup
            }
            await APIrequest.delete_group(data);
            alert("Group deleted!");
            this.setState({
                selectedGroup: null
            });
            this.getGroups();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {
        return (
            <div className="groups">
                <div className="page-header-container">
                    <Typography variant="h4" component="h2">
                        Groups
                    </Typography>
                </div>
                <div className="page-content">
                    {this.state.selectedGroup ? 
                        <div className="edit-group">
                            <TextField
                                label="Text"
                                value={this.state.selectedGroup.name}
                                onChange={this.handleChangeObjState(["selectedGroup", "name"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Order"
                                type="number"
                                value={this.state.selectedGroup.order}
                                onChange={this.handleChangeObjState(["selectedGroup", "order"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Image URL"
                                value={this.state.selectedGroup.image}
                                onChange={this.handleChangeObjState(["selectedGroup", "image"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="En"
                                value={this.state.selectedGroup.localizationMap.en}
                                onChange={this.handleChangeObjState(["selectedGroup", "localizationMap", "en"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Ru"
                                value={this.state.selectedGroup.localizationMap.ru}
                                onChange={this.handleChangeObjState(["selectedGroup", "localizationMap", "ru"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Uk"
                                value={this.state.selectedGroup.localizationMap.uk}
                                onChange={this.handleChangeObjState(["selectedGroup", "localizationMap", "uk"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <Button className="edit-group-btn" variant="contained" color="primary" onClick={this.addGroup}>save</Button>
                            <Button className="edit-group-btn" variant="contained" color="secondary" onClick={() => this.deleteGroup(this.state.selectedGroup.idGroup)}>delete</Button>
                        </div>
                    :
                        <div className="groups-cards">
                            {this.state.groups.map( group => {
                                return <Card className="groups-card">
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            {group.name}
                                        </Typography>
                                        <br/>
                                        <Typography color="textSecondary">
                                            Order: {group.order}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            ID: {group.idGroup}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className="flex-col">
                                        <Button fullWidth size="small" onClick={() => this.setState({"selectedGroup": group})}>Edit</Button>
                                        <Button fullWidth size="small" onClick={() => this.props.history.push('/categories?idGroup=' + group.idGroup)}>Categories</Button>
                                    </CardActions>
                                </Card>
                            })}
                            <Card className="groups-card">
                                <CardActionArea className="add-new-group-CAA" onClick={() => this.setState({"selectedGroup": { name: '', localizationMap: { en: '', ru: '', uk: '' } }})}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Add new group
                                    </Typography>
                                </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
