import React, { Component } from 'react';
import './css/UploadPhoto.css';

// libs
import Button from '@material-ui/core/Button';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';



class UploadPhoto extends Component {

    constructor(props) {
        super(props);
        this.uploadImg = this.uploadImg.bind(this);
        this.makeClientCrop = this.makeClientCrop.bind(this);

        this.state = {
            src: null,
            crop: {
                aspect: this.props.type === 'flexibleAspect' ? undefined : 1,
                width: 50,
                height: 50,
                x: 0,
                y: 0
            },
            operationType: 'selectFile'
        };
        this.fileName = 'newFile.jpeg';
    }

    operationTypeHandle = (type) => {
        this.setState({
            operationType: type,
        });
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            this.fileName = e.target.files[0].name;
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        this.operationTypeHandle('cropImage')
    };

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        // if (this.props.type === 'flexibleAspect') {
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height,
            );
        // } else {
        //     ctx.drawImage(
        //         image,
        //         pixelCrop.x,
        //         pixelCrop.y,
        //         pixelCrop.width,
        //         pixelCrop.height,
        //         0,
        //         0,
        //         800,
        //         800,
        //     );
        // }

        var dataURL = canvas.toDataURL('image/jpeg', 0.5);

        this.setState({
            croppedImage: dataURL
        })

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                this.fileName,
            );
            this.setState({ croppedImageUrl });
        }
    }

    uploadImg() {
        const dataURL = this.state.croppedImage;
        try {
            // Convert Base64 image to binary
            var file = this.dataURItoBlob(dataURL);
            const formData = new FormData();
            formData.append('file', file, this.fileName)
            this.props.photoIsUploadedCallbak(formData);
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <div className="upload-photo-container">
                {this.state.operationType === 'selectFile' ? 
                    <div className="file-drop-area">
                        <Button className="fake-btn" variant="contained" color="primary">
                            Choose files
                        </Button>
                        <span className="file-msg">or drag and drop files here</span>
                        <input className="file-input" type="file" onChange={this.onSelectFile} />
                    </div>
                : null}
                {this.state.operationType === 'cropImage' ? 
                    <div className="image-crop-container">
                        <div className="image-crop-content">
                            {src && (
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            )}
                            <Button className="crop-btn-submit" variant="contained" color="primary" onClick={this.operationTypeHandle.bind(this, 'submitCroppedImage')}>
                                Select this area
                            </Button>
                        </div>
                    </div>
                : null }
                {this.state.operationType === 'submitCroppedImage' ?
                    <div className="image-crop-container">
                        <div className="image-crop-content">
                            <div className="ReactCrop">
                            {croppedImageUrl && (<img alt="Crop" className="cropped-img" src={croppedImageUrl} />)}
                            </div>
                            <Button className="get-report-btn" variant="contained" color="primary" onClick={this.uploadImg}>
                                Submit photo upload
                            </Button>
                        </div>
                    </div>
                : null }
            </div>
        )
    }
}

export default UploadPhoto;