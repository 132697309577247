import React, { Component } from "react";
import { Link } from "react-router-dom";
import APIrequest from 'APIcalls/APIrequest';
import logoutUser from "functions/logout-user";
import "css/styles.css";

// redux
import * as storeUserInfo from "redux/actions/UserAction";
import * as updateNavCounters from "redux/actions/NavCountersActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// imgs
import PPlogo from "imgs/pp-logo.svg";
import LogoutIcon from "imgs/exit.svg";

class App extends Component {
	constructor(props) {
		super(props);
        this.getWithdrawRequestsCount = this.getWithdrawRequestsCount.bind(this);

        this.state = {
            counters: {
                exports: 0,
                withdraw: 0,
                productUpdates: 0,
                collaborationRequests: 0,
            }
        }

		this.userName = null;
        this.getWithdrawRequestsCount();
        this.getUpgradeRequestsCount();
        this.getUpdateProductsCount();
        this.getCollaborationRequestsCount();
	}

    componentDidUpdate(prevProps) {
        if (this.props.navCounters !== prevProps.navCounters) {
            this.setState({ counters: this.props.navCounters })
        }
    }

	componentWillMount() {
		this.checkForStoredUser();
	}

    async getWithdrawRequestsCount() {
        try {
            const response = await APIrequest.get_withdraw_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ withdraw: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

    async getUpgradeRequestsCount() {
        try {
            const response = await APIrequest.get_upgrade_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ experts: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

    async getUpdateProductsCount() {
        try {
            const response = await APIrequest.get_product_change_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ productUpdates: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

    async getCollaborationRequestsCount() {
        try {
            const response = await APIrequest.get_collaboration_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ collaborationRequests: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

	checkForStoredUser() {
		if (JSON.parse(localStorage.getItem("user")) !== null) {
			var localStorageUser = JSON.parse(localStorage.getItem("user"));
			const { storeUserInfo } = this.props.storeUserInfo;
			storeUserInfo(localStorageUser);
			this.userName = localStorageUser.name;
			if (this.props.history.location.pathname === "/") {
				this.props.history.push("/search");
			}
		} else {
			this.props.history.push("/login");
		}
	}

	logoutHandler() {
		logoutUser();
		this.checkForStoredUser();
	}

	render() {
		return (
			<div className="app">
				<header className="app-header">
					<div className="app-header-container">
						<div className="app-header-title-container">
							<img src={PPlogo} alt="PP" />
							<h1 className="app-title">
								PayPhone <br /> Admin dashboard
							</h1>
						</div>
						{process.env.REACT_APP_ADULT_TYPE === "true" ? (
							<div className="header-nav">
								<Link to="/search" className="header-nav-item">
									Search
								</Link>
								<Link to="/user/add-new-user" className="header-nav-item">
									Add new user
								</Link>
								<Link to="/categories" className="header-nav-item">
									Categories
								</Link>
							</div>
						) : (
							<div className="header-nav">
                                <span className="link-with-counter">
                                    <Link to="/withdraw" className="header-nav-item">
                                        Withdraw
                                    </Link>
                                    { (this.state.counters.withdraw !== 0) && <div className="link-with-counter_counter">{this.state.counters.withdraw}</div> }
                                </span>
								<Link to="/search" className="header-nav-item">
									Search
								</Link>
								<Link to="/user/add-new-user" className="header-nav-item">
									Add new user
								</Link>
                                <span className="link-with-counter">
                                    <Link to="/experts" className="header-nav-item">
                                        Experts
                                    </Link>
                                    { (this.state.counters.experts !== 0) && <div className="link-with-counter_counter">{this.state.counters.experts}</div> }
                                </span>
                                <span className="link-with-counter">
                                    <Link to="/products-update" className="header-nav-item">
                                        Products
                                    </Link>
                                    { (this.state.counters.productUpdates !== 0) && <div className="link-with-counter_counter">{this.state.counters.productUpdates}</div> }
                                </span>
								<Link to="/groups" className="header-nav-item">
									Groups
								</Link>
								<Link to="/transactions" className="header-nav-item">
									Transactions
								</Link>
                                <span className="link-with-counter">
                                    <Link to="/сollaboration" className="header-nav-item">
                                        Collaboration
                                    </Link>
                                    { (this.state.counters.collaborationRequests !== 0) && <div className="link-with-counter_counter">{this.state.counters.collaborationRequests}</div> }
                                </span>
								<span className="dropdown">
									<span className="dropdown-title">Other</span>
									<div className="dropdown-content">
										<Link to="/faq" className="header-nav-item">
											Faq
										</Link>
										<Link to="/news" className="header-nav-item">
											News
										</Link>
										<Link to="/funds" className="header-nav-item">
											Funds
										</Link>
                                        <Link to="/funds-stats" className="header-nav-item">
											Funds report
										</Link>
                                        <Link to="/orders" className="header-nav-item">
											User orders
										</Link>
                                        <Link to="/change-log" className="header-nav-item">
											Change log
										</Link>
                                        <Link to="/promocodes" className="header-nav-item">
                                            Promocodes
                                        </Link>
                                        <Link to="/create-payment" className="header-nav-item">
                                            Payment
                                        </Link>
                                        <Link to="/streams" className="header-nav-item">
                                            Streams
                                        </Link>
									</div>
								</span>
							</div>
						)}
						<div className="app-header-user-info-container">
							<span className="app-header-user-info">{this.userName ? this.userName : null}</span>
							<div className="logout-seperator" />
							<button className="app-header-logout-btn" onClick={this.logoutHandler}>
								Logout
								<img src={LogoutIcon} alt="logout" />
							</button>
						</div>
					</div>
				</header>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
        navCounters: state.navCounters,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
        updateNavCounters: bindActionCreators(updateNavCounters, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
