import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import { Link } from 'react-router-dom';
import './css/collaboration.css';
import UniCard from 'components/UniCard/UniCard';

// redux
import * as updateNavCounters from "redux/actions/NavCountersActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// libs
import * as moment from "moment";
import Button from "@material-ui/core/Button";
import { DatePicker } from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";

// imgs
import RefreshIcon from "imgs/refresh.svg";

class Collaboration extends Component {

    constructor(props) {
        super(props);

        this.state = {
			tableRowsUpdate: [],
			mode: "table",
			usersStatus: "WAITING",
            startDate: null,
            endDate: moment(),
            selectedRequest: null,
        }

        this.getCollaborationRequests();
    }

    handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
		this.getCollaborationRequests();
	};

    handleChangeDate = name => event => {
        this.setState({ [name]: event });
    };

    async getCollaborationRequests() {
        try {
            let data = {
                result: this.state.usersStatus
            };
            if (this.state.usersStatus === "ALL") data = {};

            if (this.state.startDate) data.startDate = this.state.startDate;
            if (this.state.endDate) data.endDate = this.state.endDate;

            const response = await APIrequest.get_collaboration_requests(data);
            this.generateTableRows(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    async saveChangeRequest(id, isApproved, rejectReason = null) {
		try {
            let data = {};
            if (rejectReason) data.result = rejectReason;

			await APIrequest.confirm_collaboration_request(id, isApproved, data);
			this.getCollaborationRequests();
            this.getCollaborationRequestsCount();
			alert("Success!");
		} catch (error) {
			alert("Error! Try again");
			console.log(error);
		}
	}

    async getCollaborationRequestsCount() {
        try {
            const response = await APIrequest.get_collaboration_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ collaborationRequests: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

    generateTableRows(records) {
		const rows = records.map(a => {
            return <div className="experts-table-row" key={a.id}>
                <div className="experts-table-col experts-table-col-user">
                    <div className="experts-table-col-user-text">
                        <Link to={`/user/${a.idPartner}`} className="experts-table-col-val">{a.partnerName}</Link>
                        <Link to={`/user/${a.idUser}`} className="experts-table-col-subval">{a.name}</Link>
                    </div>
                </div>
                <div className="experts-table-col experts-table-col-time">
                    <span className="experts-table-col-val">{a.email}</span>
                    <span className="experts-table-col-subval">{a.phoneNumber}</span>
                </div>
                <div className="experts-table-col experts-table-col-time">
                    <span className="experts-table-col-val">{a.lastModifiedDate ? moment(a.lastModifiedDate).format("DD MMMM YYYY HH:mm") : "No data availiable"}</span>
                    <span className="experts-table-col-subval">{a.status}</span>
                </div>
                <div className="experts-table-col experts-table-col-time">
                    <span className="experts-table-col-subval">{a.text}</span>
                </div>
                <div className="experts-table-col experts-table-col-btns">
                    {a.status === "WAITING" ? (
                        <div>
                            <Button
                                onClick={() => this.saveChangeRequest(a.id, true)}
                                variant="outlined"
                                color="primary"
                                className="experts-table-btn"
                                style={{ marginRight: '8px' }}
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={() => this.saveChangeRequest(a.id, false)}
                                variant="outlined"
                                color="secondary"
                                className="experts-table-btn"
                                style={{ marginRight: '8px' }}
                            >
                                Deny
                            </Button>
                            <Button 
                                onClick={() => { this.setState({ selectedRequest: a })}}
                                variant="outlined" color="default" className="experts-table-btn">
                                Details
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        }
		);
        this.setState({
            tableRowsUpdate: rows
        });
	}

    render() {
        const { selectedRequest } = this.state;

        return (
            selectedRequest ?
            <SelectedRequest 
                req={selectedRequest}
                onClick={(id, reslut, rejectReason) => {
                    this.setState({ selectedRequest: null });
                    this.saveChangeRequest(id, reslut, rejectReason);
                }}
            /> 
            :
            <div className="products-update">
                <div className="experts-container">
                    <div className="experts-header-container">
                        <span className="experts-header">Collaboration requests</span>
                        <img
                            onClick={() => {
                                this.getCollaborationRequests();
                            }}
                            src={RefreshIcon}
                            alt="refresh"
                        />
                    </div>
                    <div className="experts-status-btns">
                        <Button
                            onClick={() => {
                                this.setState({ usersStatus: "WAITING" }, () => this.getCollaborationRequests());
                            }}
                            variant="contained"
                            color="primary"
                            className="experts-table-btn"
                        >
                            Wating
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({ usersStatus: "APPROVED" }, () => this.getCollaborationRequests());
                            }}
                            variant="contained"
                            style={{ backgroundColor: "#2fb87b", color: "#ffffff" }}
                            className="experts-table-btn"
                        >
                            Approved
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({ usersStatus: "REJECTED" }, () => this.getCollaborationRequests());
                            }}
                            variant="contained"
                            color="secondary"
                            className="experts-table-btn"
                        >
                            Rejected
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({ usersStatus: "ALL" }, () => this.getCollaborationRequests());
                            }}
                            variant="contained"
                            color="default"
                            className="experts-table-btn"
                        >
                            All
                        </Button>
                        <div className="experts_date-picker flex-col">
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">Start date</div>
                                <DatePicker value={this.state.startDate} onChange={this.handleChangeDate('startDate')} />
                            </div>
                            <div className="date-picker-btn">
                                <div className="date-picker-btn_label">End date</div>
                                <DatePicker value={this.state.endDate} onChange={this.handleChangeDate('endDate')} />
                            </div>
                            <Button style={{marginTop: '14px'}} variant="contained" color="primary" onClick={this.getCollaborationRequests}>Apply</Button>
                        </div>
                    </div>
                    <div className="experts-table">
                        <div className="experts-table-row experts-table-row-header">
                            <div className="experts-table-col experts-table-col-user experts-table-col-user-header">
                                <div className="experts-table-col-user-text">
                                    <span className="experts-table-col-val">Partner name</span>
                                    <span className="experts-table-col-subval">User name</span>
                                </div>
                            </div>
                            <div className="experts-table-col experts-table-col-time">
                                <span className="experts-table-col-val">Email</span>
                                <span className="experts-table-col-subval">Phone number</span>
                            </div>
                            <div className="experts-table-col experts-table-col-time">
                                <span className="experts-table-col-val">Date</span>
                                <span className="experts-table-col-subval">Status</span>
                            </div>
                            <div className="experts-table-col experts-table-col-time">
                                <span className="experts-table-col-subval">Text</span>
                            </div>
                            <div className="experts-table-col experts-table-col-btns"></div>
                        </div>
                        {this.state.tableRowsUpdate}
                    </div>
                </div>
            </div>
        )
    }
}

class SelectedRequest extends Component {

    state = {
        rejectReason: "Sorry, but your collaboration request is not good enough or has some errors. Please contact our support if you have any questions."
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const { req, onClick } = this.props;

        return (
            <div className="collaboration">
                <UniCard obj={req}/>
                <TextField
                    label="Reject reason"
                    value={this.state.rejectReason}
                    onChange={this.handleChange("rejectReason", "value")}
                    variant="outlined"
                    multiline
                    fullWidth
                />
                <div className="pud-btns">
                    <Button
                        onClick={() => onClick(req.id, true)}
                        variant="outlined"
                        color="primary"
                        className="experts-table-btn"
                    >
                        Approve
                    </Button>
                    <Button
                        onClick={() => onClick(req.id, false, this.state.rejectReason)}
                        variant="outlined"
                        color="secondary"
                        className="experts-table-btn"
                    >
                        Reject
                    </Button>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
	return {
        navCounters: state.navCounters,
	};
}

function mapDispatchToProps(dispatch) {
	return {
        updateNavCounters: bindActionCreators(updateNavCounters, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Collaboration);