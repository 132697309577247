import React, { Component } from 'react'
import { TextField, Button } from '@material-ui/core';
import APIrequest from '../../APIcalls/APIrequest';
import ShareLinks from './ShareLinks';

export default class BindStream extends Component {

    state = {
        mode: "bind",
        id: "",
        watchToken: "",
        userId: "",
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    async bindBroadcast() {
        try {
            const response = await APIrequest.bind_broadcast(this.state.id, this.state.watchToken, this.state.userId);
            this.setState({
                watchToken: response.data.result,
                mode: "binded"
            })
        } catch (error) {
            console.log(error);
            alert("Error");
        }
    }

    render() {
        const { mode, id, watchToken, userId } = this.state;

        return (
            <div className="create-stream">
                { mode === "bind" &&  
                    <div className="stream-content">
                        <div className="title-3">Bind stream</div>
                        <TextField
                            label="User ID"
                            onChange={this.handleChange('userId', 'value')}
                            value={userId}
                            margin="normal"
                        />
                        <TextField
                            label="Youtube player id"
                            onChange={this.handleChange('id', 'value')}
                            value={id}
                            margin="normal"
                        />
                        <TextField
                            label="Watch token"
                            onChange={this.handleChange('watchToken', 'value')}
                            value={watchToken}
                            margin="normal"
                        />
                        <Button
                            className="stream-create-btn"
                            variant="contained" 
                            color="primary" 
                            onClick={() => this.bindBroadcast()}
                        >
                            Bind
                        </Button>
                    </div>
                }
                { mode === "binded" && 
                    <ShareLinks startToken={null} watchToken={watchToken} />
                }
            </div>
        )
    }
}
