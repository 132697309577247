import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { API } from "../../../APIcalls/APIrequest";
import getVideoThumbnail from "../../../functions/getVideoThumbnail";

export default function MediaPicker(props) {
	const { onFileLoaded } = props;
	const [isLoading, setIsLoading] = useState(false);
	const maxFileSize = {
		b: Math.pow(1024, 3) * 10,
		hr: "10 GB",
	};

	const setStartUploadStatus = () => {
		setIsLoading(true);
		setProgress(0);
	};

	const setProgress = (progress) => {
		onFileLoaded({
			status: "processing",
			progress: progress,
		});
	};

	const setUploadFailStatus = () => {
		onFileLoaded(null);
		setIsLoading(false);
		alert("Upload Media File Error");
	};

	const resetInputValue = (event) => {
		// allow to pick same file multiple times
		event.target.value = "";
	};

	const valideFileSize = (file) => {
		return file.size <= maxFileSize.b; // 1Gb
	};

	const filePicked = async (event) => {
		try {
			setStartUploadStatus();
			const file = event.target.files[0];
			if (!valideFileSize(file)) {
				alert(`Error. Max File Size ${maxFileSize.hr}`);
				return false;
			}
			const thumbnail = await getVideoThumbnail(file, 3);
			uploadVideo({ file, thumbnail });
		} catch (error) {
			setUploadFailStatus();
		}
	};

	const uploadVideo = async ({ file, thumbnail }) => {
		try {
			const formData = new FormData();
			formData.append("video", file, file.name);
			formData.append("contentType", "CLUB_VIDEO_BLOCK");
			thumbnail && formData.append("image", thumbnail);
			const response = await API.multipart("upload-video-content", formData, (percent) => setProgress(percent));
			if (response.status === 200) {
				const { videoURL, imageURL } = response.data;
				const fileInfo = {
					type: "video",
					videoPreviewURL: imageURL,
					videoURL: videoURL,
				};
				onFileLoaded(fileInfo);
			}
		} catch (error) {
			setUploadFailStatus();
		}
		setIsLoading(false);
	};

	return (
		<>
			{!isLoading && (
				<label className="icon_xs">
					<input hidden type="file" accept="video/*" onChange={filePicked} onClick={resetInputValue} />
					<div className="ppc-btn pick-video-file-btn">Add new video</div>
				</label>
			)}
		</>
	);
}

// styleName="pick-file-icon"
