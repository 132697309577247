import React, { Component } from 'react';
import APIrequest from "APIcalls/APIrequest";
import './css/change-log.css';
import UniCard from 'components/UniCard/UniCard';

export default class ChangeLog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            logs: []
        }

        this.getChangeLog();
    }

    async getChangeLog() {
        try {
            const response = await APIrequest.get_change_log();
            this.setState({ logs: response.data })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { logs } = this.state;

        return (
            <div className="change-log">
                {logs.map( log => <UniCard obj={log}/>)}
            </div>
        )
    }
}
