import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import landListObj from 'json/languages.json';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        minWidth: 760,
        maxWidth: 760,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class MultipleSelect extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        let selected = [];
        if (this.props.selected) {
            this.props.selected.forEach(a => {
                this.props.fullList.forEach(b => {
                    if (a === b)
                        selected.push(b)
                })
            })
        }
    
        this.state = {
            selected: selected,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected !== prevProps.selected && this.props.selected !== this.state.selected) {
            let selected = [];
            if (this.props.selected) {
                this.props.selected.forEach(a => {
                    this.props.fullList.forEach(b => {
                        if (a === b)
                            selected.push(b)
                    })
                })
            }
        
            this.setState({
                selected: selected,
            });
        }
    }

    handleChange = event => {
        this.setState({ selected: event.target.value });
        this.props.onLangChange(event.target.value.map(a => { return a }))
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-checkbox">{this.props.label}</InputLabel>
                    <Select
                        multiple
                        value={this.state.selected}
                        onChange={this.handleChange}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {this.props.fullList.map(lang => (
                            <MenuItem key={lang} value={lang}>
                                <Checkbox checked={(this.state.selected.findIndex(p => p === lang) > -1)} />
                                <ListItemText primary={lang} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>                
            </div>
        );
    }
}

MultipleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MultipleSelect);