import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import { escapeSpecialCharacters } from 'functions/escapeSpecialCharacters';

// imgs
import DefaultProfileIcon from 'imgs/user-icon.svg';
import { Button, TextField } from '@material-ui/core';
import validateEmail from 'functions/validateEmail';

export default class LinkUsersToAgent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkedUsersList: [],
            searchList: [],
        };

        this.getUsersProfiles();
    }

    componentDidUpdate(prevProps) {
        if (this.props.agent !== prevProps.agent) {
            this.getUsersProfiles();
        }
    }

    handleUsersList(idUser) {
        this.linkUserToAgent(idUser, true);
    }

    async handleTermChange(term) {
        if (term.length > 2) {
            if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(async () => { // send req not more often than 400ms
                if (validateEmail(term)) {
                    let validFirstPartOfEmail = term.split('@')[0].split('.').join("").split('+')[0]; // replace '.' and '+' before '@'. cut all after '+'
                    term = validFirstPartOfEmail + '@' + term.split('@')[1];
                }
                let data = {
                    "result": escapeSpecialCharacters(term)
                }
                try {
                    let response = await APIrequest.find_users_by_query(data, true);
                    this.setState({
                        searchList: response.data,
                    })
                } catch (error) {
                    console.log(error)
                }
            }, 400); 
        }
        else this.setState({
            searchList: [],
        })
    }

    async getUsersProfiles() {
        try {
            const { agent: { agentInfo: { persons } }} = this.props;
            const response = await APIrequest.get_users_by_ids(persons);
            this.setState({ linkedUsersList: response.data });
        } catch (error) {
            console.log(error);
        }
    }

    async linkUserToAgent(idUser, link) {
        try {
            const { agent: { idUser: idAgent }} = this.props;
            const data = {
                "idAgent": idAgent,
                "idUser": idUser,
                "link": link
            }
            const response = await APIrequest.link_user_to_agent(data);
            if (response.status === 200) {
                this.props.userUpdatedCallback(false);
                alert("Success!");
            }
            else {
                alert("Error!");
            }
        } catch (error) {
            alert("Error!");
            console.log(error);
        }
    }

    render() {
        const { linkedUsersList, searchList } = this.state;

        return (
            <div className="user-table user-table-expert">
                <div className="user-table-header-container">
                    <span className="user-table-header">Link users to agent</span>
                </div>
                <div className="user-table-row">
                    <div className="user-table-col user-table-col">
                        <span className="user-table-col-val">Name</span>
                        <span className="user-table-col-subval">id</span>
                    </div>
                    <div className="user-table-col">
                        <span className="user-table-col-val">Work</span>
                        <span className="user-table-col-subval">About me</span>
                    </div>
                </div>
                <UsersList 
                    usersList={linkedUsersList} 
                    btnColor="secondary"
                    btnText="Unlink"
                    onClick={(idUser) => this.linkUserToAgent(idUser, false)}
                />
                <TextField
                    label="Search for users to add"
                    value={this.state.searchTerm}
                    onChange={(event) => {
                        const term = event.target.value;
                        this.setState({ searchTerm: term });
                        this.handleTermChange(term);
                    }}
                    margin="normal"
                    style={{
                        margin: '15px 20px 17px',
                        width: '600px',
                    }}
                />
                <UsersList 
                    usersList={searchList} 
                    btnText="Link"
                    btnColor="primary"
                    onClick={(idUser) => this.linkUserToAgent(idUser, true)}
                />
            </div>
        )
    }
}

function UsersList(props) {
    const { usersList, btnColor, btnText, onClick } = props;

    return <div>
        {usersList.map( user => {
            return <div className="user-table-row" key={user.idUser}>
                <div className="table-col_with-img">
                    <img className="table-usr-img" src={user.imageLink ? user.imageLink : DefaultProfileIcon} alt="profile" />
                    <div className="user-table-col">
                        <span className="user-table-col-val">{user.name}</span>
                        <span className="user-table-col-subval">{user.idUser}</span>
                    </div>
                </div>
                <div className="user-table-col">
                    {!!user.expertInfo && 
                        <>
                            <span className="user-table-col-val">{user.expertInfo.work}</span>
                            <span className="user-table-col-subval">{user.expertInfo.aboutMe}</span>
                        </>
                    }
                </div>
                <div className="user-table-col_05">
                    <Button variant="contained" color={btnColor} onClick={() => onClick(user.idUser)} style={{ marginLeft: "8px" }}>{btnText}</Button>
                </div>
            </div>
        })}
    </div>
}
