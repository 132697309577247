import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';

class EditGallery extends Component {

    fileSelectedHandler = event => {
        const gallery = Object.values(event.target.files).map( async file => {
            return this.uploadImg(file);
        });

        Promise.all(gallery).then((completed) => {
            let { onUploaded, currentGallery } = this.props;
            if (!currentGallery) currentGallery = [];
            const newGallery = [...currentGallery, ...completed];
            onUploaded(newGallery);
        })
    }

    uploadImg = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('info', JSON.stringify({
                type: 'PRODUCT_GALLERY'
            }));
            const response = await APIrequest.upload_some_image(formData);

            return response.data.result;
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
            return null;
        }
    }

    deleteImage(src) {
        const { currentGallery, onUploaded } = this.props;
        const newGallery = currentGallery.filter(e => e !== src);
        onUploaded(newGallery);
    }

    render() {
        const { currentGallery } = this.props;

        return (
            <div>
                <div className="title-5">Current gallery</div>
                <div className="gallery-preview-container">
                    {currentGallery && currentGallery.map( (src, index) => 
                        <div className="gallery-preview" key={index}>
                            <img src={src} alt=""/>
                            <div className="gallery-preview_delete-btn text-2" onClick={() => this.deleteImage(src)}>Delete</div>
                            <div className="gallery-preview_filter"/>
                        </div>
                    )}
                </div>
                <div className="text-1">Select new imgs for gallery</div>
                <input type="file" onChange={this.fileSelectedHandler} multiple accept="image/*" />
            </div>
        );
    }
}

export default EditGallery;