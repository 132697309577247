import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import safeKey from 'functions/safeKey.js';
import './css/forum.css';

// libs
import * as moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

// imgs

class QuestionCard extends Component {

  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    this.state = {
      anchorEl: null
    };
  }

  async forumRemovePost() {
    try {
      await APIrequest.forum_remove_post('TOPIC', this.props.question.idTopic);
      this.props.updateCallback();
    } catch (error) {
      alert("Network error. Try again later")
    }
  }

  openMenu(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  closeMenu(event) {
    this.setState({ anchorEl: null })
  }

  render() {
    const { question } = this.props;

    return <Card className="question-card">
      <CardHeader
        avatar={
          question.author.imageLink ? 
            <img className="question-author-imageLink" src={question.author.imageLink} alt={question.author.name}/>
          :
            <Avatar aria-label="recipe">
              {question.author.name.charAt(0).toUpperCase()}
            </Avatar>
        }
        action={
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={this.openMenu}>
            <MoreVertIcon />
          </IconButton>
        }
        title={question.author.name}
        subheader={moment(question.created).format("HH:mm MMMM DD, YYYY")}
      />
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={() => this.closeMenu(false)}
      >
        <MenuItem onClick={() => { this.closeMenu(false); this.forumRemovePost() }}>Delete</MenuItem>
        {this.props.linkToTopic ?
          <MenuItem onClick={() => this.props.history.push('/forum/topic?id=' + question.idTopic)}>Link to topic</MenuItem>
        :
          <MenuItem onClick={() => this.props.history.push('/user/' + question.author.idUser)}>Author profile</MenuItem>
        }
        <MenuItem disabled onClick={() => this.closeMenu(false)}>Hide</MenuItem>
        <MenuItem disabled onClick={() => this.closeMenu(false)}>Edit</MenuItem>
      </Menu>
      {question.actualContent.imageURL ? 
        <img className="card-cover" src={question.actualContent.imageURL} alt="jj" />
      : null }
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {this.props.showType ?
            <span>Question: </span>
          : null}
          <span className="question-text">{question.actualContent.title}</span> <br/>
          Modified: {moment(question.modified).format("HH:mm MMMM DD, YYYY")} <br/>
          Content is modified: {String(question.isContentModified)} <br />
          Category: {safeKey(() => question.category.localizedString)} <br />
          Status: {safeKey(() => question.status)} <br/>
          ID: {safeKey(() => question.idTopic)}
        </Typography>
      </CardContent>
      <div className="card-footer">
      {/* <CardActions disablespacing="true"> */}
        {/* {question.totalResponses}
        <FavoriteIcon /> */}
        {/* <IconButton aria-label="share"> */}
        <div className="footer-counter">
          <span className="count">{String(question.totalResponses)}</span>
          <QuestionAnswerIcon />
        </div>
        {/* </IconButton> */}
        <IconButton
          onClick={() => this.props.history.push('/forum/topic?id=' + question.idTopic)}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      {/* </CardActions> */}
    </Card>
  }
}

export default QuestionCard
