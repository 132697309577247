import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import APIrequest from 'APIcalls/APIrequest';

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelectedArray = selectedItem.map(a => {
    return a.userID.indexOf(suggestion.userID) > -1;
  });
  const isSelected = isSelectedArray.indexOf(true) > -1;
  // const isSelected = (selectedItem.id || '').indexOf(suggestion.id) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.userID}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.object,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

class DownshiftMultiple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      inputValue: '',
      selectedItem: [],
    }
  }

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (selectedItem.label && selectedItem.label.length && !inputValue.length && keycode(event) === 'backspace') {
      this.setState({
        selectedItem: selectedItem.label.slice(0, selectedItem.label.length - 1),
      });
    }
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value }, () => {
      this.getSuggestions();
    });
  };

  handleChange = item => {
    let { selectedItem } = this.state;

    const isSelectedArray = selectedItem.map(a => {
      return a.userID.indexOf(item.userID) > -1;
    });

    if (isSelectedArray.indexOf(true) === -1) {
      selectedItem = [...selectedItem, item];
      this.props.onUsersListChange(item.userID); 
    }

    this.setState({
      inputValue: '',
      selectedItem,
    });
  };

  handleDelete = item => () => {
    this.props.onUsersListChange(item.userID); 

    this.setState(state => {
      const selectedItem = [...state.selectedItem];
      selectedItem.splice(selectedItem.indexOf(item), 1);
      return { selectedItem };
    });
  };

  async getSuggestions() {
    let value = this.state.inputValue;
    let userList = [];

    if (value.length === 24 && !/\s/.test(value)) {
      try {
        let response = await APIrequest.get_user_full_by_id(value);
        userList.push(
          { label: response.data.name, userID: response.data.idUser }
        )
      } catch (error) {
        console.log(error)
      }
    }
    else if (value.length > 2) {
      try {
        const { onlyExperts = false } = this.props;
        let data = {
          "result": value
        }
        let response = await APIrequest.find_users_by_query(data, onlyExperts);
        response.data.map(user => {
          userList.push(
            {label: user.name, userID: user.idUser}
          )
        })
      } catch (error) {
        console.log(error)
      }
    }
    this.setState({
      userList: userList
    })
  }

  render() {
    const { classes } = this.props;
    const { inputValue, selectedItem } = this.state;

    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
        className="autoselectUsers"
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex,
        }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  startAdornment: selectedItem.map(item => (
                    <Chip
                      key={item.userID}
                      tabIndex={-1}
                      label={item.label}
                      className={classes.chip}
                      onDelete={this.handleDelete(item)}
                    />
                  )),
                  onChange: this.handleInputChange,
                  onKeyDown: this.handleKeyDown,
                  placeholder: this.props.placeholder,
                }),
                label: this.props.label,
              })}
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {this.state.userList.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          )}
      </Downshift>
    );
  }
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    margin: '40px 0'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export default withStyles(styles)(DownshiftMultiple);
