import { Button, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { API } from '../../../APIcalls/APIrequest';
import MediaPicker from './MediaPicker';
import "../css/club-video-block.css";

export default function ClubVideoBlock({user}) {
    const [videos, setVideos] = useState([]);
    const [initial, setInitial] = useState(true);
    const [uplaodVideo, setUplaodVideo] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [order, setOrder] = useState("99999");

    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleChangeDescription = (event) => {
        setDescription(event.target.value);
    }

    const handleChangeOrder = (event) => {
        setOrder(event.target.value);
    }


    useEffect(() => {
        console.log(user);
        if (!!user.expertInfo && user.expertInfo.clubId && initial) {
            getVideoBlock();
            setInitial(false);
        }
    }, [user])

    const getVideoBlock = async () => {
        try {
            console.log({user});
            const data = {
                "page": 0,
                "size": 1000,
            }
            const res = await API.post(`video-block/${user.expertInfo.clubId}`, data);
            console.log(res);
            setVideos(res.data.items);
        } catch (error) {
            console.log(error);
        }
    } 

    const onFileLoaded = (file) => {
        console.log(file);
        setUplaodVideo(file);
    }

    const submitHandle = async () => {
        try {
            const data = {
                idClub: user.expertInfo.clubId,
                previewURL: uplaodVideo.videoPreviewURL,
                videoURL: uplaodVideo.videoURL,
                title,
                description,
                order,
            }
            await API.post("save-video", data);
            setOrder("");
            setDescription("");
            setTitle("");
            setUplaodVideo(null);
            alert("Success!");
            getVideoBlock();
        } catch (error) {
            alert("Error!")
            console.log(error);
        }
    }

    const deleteHandle = async (idElement) => {
        try {
            await API.delete(`delete-video/${idElement}`);
            alert("Success!");
            getVideoBlock();
        } catch (error) {
            alert("Error!")
            console.log(error);
        }
    }

    return (
        <div className="user-table">
            <div className="user-table-header-container">
                <span className="user-table-header">
                    Club Video Block
                </span>
            </div>
            <div className="club-video-block">
                <div className="add-new-video">
                    { !uplaodVideo && <MediaPicker onFileLoaded={onFileLoaded} /> }
                    { uplaodVideo && <div className="new-video-inputs">
                        <TextField
                            label="Title"
                            value={title}
                            onChange={handleChangeTitle}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Description"
                            value={description}
                            onChange={handleChangeDescription}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Order"
                            value={order}
                            onChange={handleChangeOrder}
                            margin="normal"
                            variant="outlined"
                        />
                    </div> }
                    { uplaodVideo && uplaodVideo.status === 'processing' && <div>
                        {uplaodVideo.progress} %
                    </div>}
                    { uplaodVideo && uplaodVideo.videoURL && !!title.length && !!description.length && !!order.length &&
                        <Button style={{marginTop: '16px'}} onClick={submitHandle} variant="contained" color="primary">
                            Submit
                        </Button> 
                    }
                </div>
                <div>
                    {videos.map(video => {
                        return <div key={video.idElement} className="video-element">
                            <div>Title: {video.title}</div>
                            <div>Description: {video.description}</div>
                            <div>Duration: {video.durationInSeconds} sec.</div>
                            <div>Element ID: {video.idElement}</div>
                            <div>Order: {video.order}</div>
                            <video width="320" height="200" controls src={video.videoURL} /> 
                            <div>
                                <Button style={{marginTop: '16px'}} onClick={() => deleteHandle(video.idElement)} variant="contained" color="secondary">
                                    Delete
                                </Button> 
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}
