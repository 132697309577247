import React, { Component } from "react";
import { Link } from "react-router-dom";
import APIrequest from "APIcalls/APIrequest";
import "./css/experts.css";
import User from "components/userPage/User";

// redux
import * as updateNavCounters from "redux/actions/NavCountersActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// libs
import * as moment from "moment";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { DatePicker } from '@material-ui/pickers';

// imgs
import RefreshIcon from "imgs/refresh.svg";

class Experts extends Component {
	constructor(props) {
		super(props);
		this.generateTableRows = this.generateTableRows.bind(this);
		this.getUpgradeRequests = this.getUpgradeRequests.bind(this);
		this.saveChangeRequest = this.saveChangeRequest.bind(this);
		this.getTableData = this.getTableData.bind(this);

		this.state = {
			tableRowsUpgrade: [],
			tableRowsUpdate: [],
			mode: "table",
			usersStatus: "WAITING",
			showUpgradeReq: true,
			showUpdateReq: true,
            startDate: null,
            endDate: moment(),
		};
	}

	componentDidMount() {
		this.getTableData();
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps && this.props.location.pathname === "/experts") {
			this.setState({
				mode: "table"
			});
		}
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
		this.getTableData();
	};

    handleChangeDate = name => event => {
        this.setState({ [name]: event });
    };

	getTableData() {
		if (this.state.showUpdateReq) this.getChangeRequests();
		if (this.state.showUpgradeReq) this.getUpgradeRequests();
	}

	generateTableRows(records, type) {
		let rows = [];
		records.forEach(a => {
			let row = (
				<div className="experts-table-row" key={a.idRequest || a.id}>
					<Link
						to={type === "Upgrade to expert" ? "/upgrade-to-expert/" + a.idUser : "/update-expert-info/" + a.idUser}
						onClick={() => {
							this.selectedUser = a;
							this.setState({ mode: type === "Upgrade to expert" ? "upgradeDetalies" : "updateDetalies" });
						}}
						className="experts-table-col experts-table-col-user"
					>
						<div className="experts-table-col-user-text">
							<span className="experts-table-col-val">{a.originalName || a.name}</span>
							<span className="experts-table-col-subval">{a.idUser}</span>
						</div>
					</Link>
					<div className="experts-table-col experts-table-col-time">
						<span className="experts-table-col-val">{type}</span>
						<span className="experts-table-col-subval">{a.status}</span>
					</div>
					<div className="experts-table-col experts-table-col-time">
						<span className="experts-table-col-val">{a.lastModifiedDate ? moment(a.lastModifiedDate).format("DD MMMM YYYY HH:mm") : "No data availiable"}</span>
						<span className="experts-table-col-subval">{a.proposedCategory ? "True" : "False"}</span>
					</div>
					<div className="experts-table-col experts-table-col-btns">
						{a.status === "WAITING" ? (
							<div>
								<Button
									onClick={type === "Upgrade to expert" ? this.confirmUpgrade.bind(this, a, true) : this.saveChangeRequest.bind(this, a, true)}
									variant="outlined"
									color="primary"
									className="experts-table-btn"
								>
									Approve
								</Button>
								<Button
									onClick={type === "Upgrade to expert" ? this.confirmUpgrade.bind(this, a, false) : this.saveChangeRequest.bind(this, a, false)}
									variant="outlined"
									color="secondary"
									className="experts-table-btn"
								>
									Deny
								</Button>
								<Link
									to={type === "Upgrade to expert" ? "/upgrade-to-expert/" + a.idUser : "/update-expert-info/" + a.idUser}
									onClick={() => {
										this.selectedUser = a;
										this.setState({ mode: type === "Upgrade to expert" ? "upgradeDetalies" : "updateDetalies" });
									}}
								>
									<Button variant="outlined" color="default" className="experts-table-btn">
										Details
									</Button>
								</Link>
							</div>
						) : null}
					</div>
				</div>
			);
			rows.push(row);
		});
		if (type === "Upgrade to expert") {
			this.setState({
				tableRowsUpgrade: rows
			});
		} else {
			this.setState({
				tableRowsUpdate: rows
			});
		}
	}

	async getUpgradeRequests() {
		let data = {
			result: this.state.usersStatus
		};
		if (this.state.usersStatus === "ALL") data = {};

        if (this.state.startDate) data.startDate = this.state.startDate;
        if (this.state.endDate) data.endDate = this.state.endDate;

		try {
			let response = await APIrequest.get_upgrade_requests(data);
			this.generateTableRows(response.data, "Upgrade to expert");
		} catch (error) {
			console.log(error);
		}
	}

	async getChangeRequests() {
		let data = {
			result: this.state.usersStatus
		};
		if (this.state.usersStatus === "ALL") data = {};

        if (this.state.startDate) data.startDate = this.state.startDate;
        if (this.state.endDate) data.endDate = this.state.endDate;

		try {
			let response = await APIrequest.get_change_requests(data);
			this.generateTableRows(response.data, "Personal info change");
		} catch (error) {
			console.log(error);
		}
	}

	async confirmUpgrade(data, isApproved) {
		if (!isApproved) {
			data.status = "REJECTED";
			data.reason =
				"Sorry, but we can not confirm your expert status. You have not provided enough reason to upgrade your profile. Try to connect social networks by which we will be able to evaluate your expertise and identity.";
		} else {
			data.status = "APPROVED";
		}
		try {
			await APIrequest.confirm_upgrade(data);
			this.getTableData();
            this.getUpgradeRequestsCount();
			alert("Success!");
		} catch (error) {
			alert("Error! Try again");
			console.log(error);
		}
	}

	async saveChangeRequest(data, isApproved) {
		data.status = isApproved ? "APPROVED" : "REJECTED";
		try {
			await APIrequest.save_change_request(data);
			this.getTableData();
            this.getUpgradeRequestsCount();
			alert("Success!");
		} catch (error) {
			alert("Error! Try again");
			console.log(error);
		}
	}

    async getUpgradeRequestsCount() {
        try {
            const response = await APIrequest.get_upgrade_requests_count();
            const { updateNavCounters } = this.props.updateNavCounters;
			updateNavCounters({ experts: response.data.result });
        } catch (error) {
            console.log(error);
        }
    }

	render() {
		return (
			<div>
				{(() => {
					switch (this.state.mode) {
						case "table":
							return (
								<div className="experts">
									<div className="experts-container">
										<div className="experts-header-container">
											<span className="experts-header">Experts</span>
											<img
												onClick={() => {
													this.getUpgradeRequests();
													this.getChangeRequests();
												}}
												src={RefreshIcon}
												alt="refresh"
											/>
										</div>
										<div className="experts-status-btns">
											<Button
												onClick={() => {
													this.setState({ usersStatus: "WAITING" }, () => this.getTableData());
												}}
												variant="contained"
												color="primary"
												className="experts-table-btn"
											>
												Wating
											</Button>
											<Button
												onClick={() => {
													this.setState({ usersStatus: "APPROVED" }, () => this.getTableData());
												}}
												variant="contained"
												style={{ backgroundColor: "#2fb87b", color: "#ffffff" }}
												className="experts-table-btn"
											>
												Approved
											</Button>
											<Button
												onClick={() => {
													this.setState({ usersStatus: "REJECTED" }, () => this.getTableData());
												}}
												variant="contained"
												color="secondary"
												className="experts-table-btn"
											>
												Rejected
											</Button>
											<Button
												onClick={() => {
													this.setState({ usersStatus: "ALL" }, () => this.getTableData());
												}}
												variant="contained"
												color="default"
												className="experts-table-btn"
											>
												All
											</Button>
											<span>Show upgrade requests</span>
											<Switch checked={this.state.showUpgradeReq} onChange={this.handleChange("showUpgradeReq")} value="showUpgradeReq" />
											<span>Show update requests</span>
											<Switch checked={this.state.showUpdateReq} onChange={this.handleChange("showUpdateReq")} value="showUpdateReq" color="primary" />
                                            <div className="experts_date-picker flex-col">
                                                <div className="date-picker-btn">
                                                    <div className="date-picker-btn_label">Start date</div>
                                                    <DatePicker value={this.state.startDate} onChange={this.handleChangeDate('startDate')} />
                                                </div>
                                                <div className="date-picker-btn">
                                                    <div className="date-picker-btn_label">End date</div>
                                                    <DatePicker value={this.state.endDate} onChange={this.handleChangeDate('endDate')} />
                                                </div>
                                                <Button style={{marginTop: '14px'}} variant="contained" color="primary" onClick={this.getTableData}>Apply</Button>
                                            </div>
                                        </div>
										<div className="experts-table">
											<div className="experts-table-row experts-table-row-header">
												<div className="experts-table-col experts-table-col-user experts-table-col-user-header">
													<div className="experts-table-col-user-text">
														<span className="experts-table-col-val">User Name</span>
														<span className="experts-table-col-subval">User ID</span>
													</div>
												</div>
												<div className="experts-table-col experts-table-col-time">
													<span className="experts-table-col-val">Request type</span>
													<span className="experts-table-col-subval">Status</span>
												</div>
												<div className="experts-table-col experts-table-col-time">
													<span className="experts-table-col-val">Date</span>
													<span className="experts-table-col-subval">Proposed a category</span>
												</div>
												<div className="experts-table-col experts-table-col-btns"></div>
											</div>
											{this.state.showUpgradeReq ? this.state.tableRowsUpgrade : null}
											{this.state.showUpdateReq ? this.state.tableRowsUpdate : null}
										</div>
									</div>
								</div>
							);
						case "upgradeDetalies":
							return (
								<User
									location={this.props.location}
									upgradeInfo={this.selectedUser}
									approvedCallback={() => {
										this.setState({ mode: "table" });
										this.getTableData();
									}}
								/>
							);
						case "updateDetalies":
							return (
								<User
									location={this.props.location}
									updateInfo={this.selectedUser}
									approvedCallback={() => {
										this.setState({ mode: "table" });
										this.getTableData();
									}}
								/>
							);
						default:
							break;
					}
				})()}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
        navCounters: state.navCounters,
	};
}

function mapDispatchToProps(dispatch) {
	return {
        updateNavCounters: bindActionCreators(updateNavCounters, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Experts);
