import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default class PromocodeUsersModal extends Component {
    render() {
        const { open, users, onClose } = this.props;

        return <Dialog 
            open={open} 
            onClose={onClose} 
            aria-labelledby="alert-dialog-title" 
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Users who used this promocode</DialogTitle>
            <DialogContent>
                { users ? 
                    users.map((user, index) => { 
                        return <DialogContentText key={index}>{user}</DialogContentText>
                    }) 
                    : 
                    'None'
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    }
}