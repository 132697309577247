import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import CommentCard from './CommentCard';
import './css/answer.css';
import safeKey from 'functions/safeKey.js';

// libs
import Scroll from 'react-scroll';
import * as moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DoneIcon from '@material-ui/icons/Done';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

// imgs

var Element = Scroll.Element;

class answerCard extends Component {

    constructor(props) {
        super(props);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        this.state = {
            anchorEl: null
        };
    }

    async forumRemovePost() {
        try {
            await APIrequest.forum_remove_post('ANSWER', this.props.answer.idAnswer);
            this.props.updateCallback();
        } catch (error) {
            alert("Network error. Try again later")
        }
    }

    openMenu(event) {
        this.setState({
            anchorEl: event.currentTarget
        })
    }

    closeMenu(event) {
        this.setState({ anchorEl: null })
    }

    render() {
        const { answer } = this.props;

        return <Card className="answer-card">
            <CardHeader
                avatar={
                    answer.author.imageLink ?
                        <img className="answer-author-imageLink" src={answer.author.imageLink} alt={answer.author.name} />
                        :
                        <Avatar aria-label="recipe">
                            {answer.author.name.charAt(0).toUpperCase()}
                        </Avatar>
                }
                action={
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={this.openMenu}>
                        <MoreVertIcon />
                    </IconButton>
                }
                title={answer.author.name}
                subheader={moment(answer.created).format("HH:mm MMMM DD, YYYY")}
            />
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={() => this.closeMenu(false)}
            >
                <MenuItem onClick={() => { this.closeMenu(false); this.forumRemovePost() }}>Delete</MenuItem>
                {this.props.linkToTopic ?
                    <MenuItem onClick={() => this.props.history.push('/forum/topic?id=' + answer.idTopic + '&anchor=' + answer.idAnswer)}>Link to topic</MenuItem>
                    :
                    <MenuItem onClick={() => this.props.history.push('/user/' + answer.author.idUser)}>Author profile</MenuItem>
                }
                <MenuItem disabled onClick={() => this.closeMenu(false)}>Hide</MenuItem>
                <MenuItem disabled onClick={() => this.closeMenu(false)}>Edit</MenuItem>
            </Menu>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {this.props.showType ?
                        <span>Answer: </span>
                        : null}
                    <span className="answer-text">{answer.actualContent.text}</span> <br />
                    Modified: {moment(answer.modified).format("HH:mm MMMM DD, YYYY")} <br />
                    Content is modified: {String(answer.isContentModified)} <br />
                    Status: {safeKey(() => answer.status)} <br />
                    ID: {safeKey(() => answer.idAnswer)}
                </Typography>
            </CardContent>
            <div className="card-footer">
                <div className="footer-counter">
                    <span className="count">{String(answer.totalLikes)}</span>
                    <FavoriteIcon />
                </div>
                <div className="footer-counter">
                    <span className="count">{String(answer.totalComments)}</span>
                    <QuestionAnswerIcon />
                </div>
                {answer.isApprovedByTopicAuthor ?
                    <div className="footer-counter">
                        <span className="count">Is approved by topic author</span>
                        <DoneIcon />
                    </div>
                    : null}
            </div>
            {answer.comments ?
                <div className="comment-card-container">
                    {answer.comments.map((comment) => {
                        return <Element name={comment.idComment} key={comment.idComment} style={{ width: '100%' }}>
                            <CommentCard comment={comment} updateCallback={this.props.updateCallback} history={this.props.history} />
                        </Element>
                    })}
                </div>
                : null}
        </Card>
    }
}

export default answerCard
