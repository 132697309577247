import React, { Component } from 'react';
import "./css/UniCard.css";

// libs
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TableRow from '../userPage/profileSnippet/TableRow';

export default class UniCard extends Component {

    renderObject(obj) {
        return Object.entries(obj).map(([key, value]) => {
            if (typeof value === 'object' && value && !Array.isArray(value)) {
                return <div key={key} style={{marginLeft: '20px'}}>
                    <div className="subtitle">{key}</div>
                    {this.renderObject(value)}
                </div>
            }
            return <TableRow key={key} title={key} value={value}/>
        });
    }

    render() {
        return (
            <div className="uni-card">
                <Card className="card">
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="div">
                            {this.renderObject(this.props.obj)}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        )
    }
}
