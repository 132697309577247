import React, { Component } from "react";
import { Link } from 'react-router-dom';
import safeKey from "functions/safeKey.js";

// libs
import * as moment from "moment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import MenuItem from '@material-ui/core/MenuItem';

// imgs

class ProductCard extends Component {
	render() {
        const { product } = this.props;
        const idToName = {
            [product.from.idUser]: product.from.name,
            [product.to.idUser]: product.to.name,
        }

		return (
			<Card className="card">
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						<span className="title">{product.operationType}</span> <br />
                        status: {product.status} <br />
                        {product.from && <> from: <Link to={"/user/" + product.from.idUser}>{product.from.name}</Link> <br /> </>}
                        to: <Link to={"/user/" + product.to.idUser}>{product.to.name}</Link> <br />
                        amount: {product.amount} <br />
                        isRated: {String(product.isRated)} <br />
                        productType: {safeKey(()=>product.object.product.productType)} <br />
                        recipientName: {safeKey(()=>product.object.recipientName)} <br />
                        request text: {safeKey(()=>product.object.text)} <br />
						Start: {moment(product.transactionStartDate).format("HH:mm MMMM DD, YYYY")} <br />
                        Complete: {moment(product.transactionCompleteDate).format("HH:mm MMMM DD, YYYY")} <br />
                        {product.object.chat && (
                            <div>
                                <br/>
                                Chat:
                                {product.object.chat.map( message => {
                                    return <div>
                                        {idToName[message.idFrom]}: {message.text}
                                    </div>
                                })}
                            </div>
                        )}
					</Typography>
				</CardContent>
			</Card>
		);
	}
}

export default ProductCard;
