import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/transactions.css';

// libs
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { DatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// components
import ProductCard from "./ProductCard";
import ProductReqCard from "./ProductReqCard";
import MessageCard from "./MessageCard";
import CallCard from "./CallCard";
import ProductResponseCard from "./ProductResponseCard";
import TransactionsStats from './TransactionsStats';

// imgs
import RefreshIcon from "imgs/refresh.svg";

const filterOptions = [
    {
        value: "ALL",
        label: "Show all",
    },
    {
        value: "COMPLETED",
        label: "Show completed",
    },
    {
        value: "IN_PROGRESS",
        label: "Show in progress",
    },
    {
        value: "REACTIONS",
        label: "Show reactions",
    },
    {
        value: "ROLLBACK",
        label: "Rollback",
    },
    {
        value: "EXPIRE_SOON",
        label: "Expire soon",
    },
]

class Transactions extends Component {

    constructor(props) {
        super(props);
        this.getLogs = this.getLogs.bind(this);
        this.transactionsStatsRef = React.createRef();

        this.state = {
            logs: [],
            page: 0,
			lastPage: 1,
            endDate: null,
            startDate: null,
            filterOption: "ALL",
            filteredLogs: null,
        };

        this.getLogs();
    }

    componentDidUpdate(prevProps, prevState) {
		if (prevState.page !== this.state.page) {
			this.getLogs();
		}
	}

    async getLogs() {
        try {
            const data = {
                page: this.state.page,
                size: 30,
            }

            if (this.state.startDate) data.startDate = this.state.startDate;
            if (this.state.endDate) data.endDate = this.state.endDate;

            const response = await APIrequest.admin_history_log(data, this.state.filterOption);
            this.setState({
                logs: response.data.items,
                lastPage: response.data.totalPages
            });
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    handleChangeFilter = (event) => {
        const selectedOption = event.target.value;
        this.setState({
            filterOption: selectedOption,
            page: 0,
        }, () => this.getLogs(selectedOption));
    };

    handlePage(step) {
        this.setState({
            page: this.state.page + step
        })
    }

    handleChange = name => event => {
        this.setState({ [name]: event });
    };

    render() {
        const { filterOption, logs: allLogs, filteredLogs } = this.state;
        const logs = filteredLogs || allLogs;
        
        return (<>
			<div className="transactions">
				<div className="transactions-container">
                    <div className="transactions-header-container">
						<span className="transactions-header">Transactions</span>
						<img onClick={() => this.getLogs()} src={RefreshIcon} alt="refresh" />
					</div>
                    <div className="flex">
                        <div>
                            <div className="flex-col">
                                <div className="date-picker-btn">
                                    <div className="date-picker-btn_label">Start date</div>
                                    <DatePicker value={this.state.startDate} onChange={this.handleChange('startDate')} />
                                </div>
                                <div className="date-picker-btn">
                                    <div className="date-picker-btn_label">End date</div>
                                    <DatePicker value={this.state.endDate} onChange={this.handleChange('endDate')} />
                                </div>
                                <Button style={{marginTop: '14px'}} variant="contained" color="primary" onClick={() => { try { this.getLogs(); this.transactionsStatsRef.current.loadReport(); } catch (error) {} }}>Apply</Button>
                            </div>
                            <div className="transactions-status-btns">
                                <div className="pages-controll">
                                    <MobileStepper
                                        style={{
                                            height: "56px"
                                        }}
                                        steps={this.state.lastPage}
                                        position="static"
                                        variant="text"
                                        activeStep={this.state.page}
                                        nextButton={
                                            <Button size="small" onClick={() => this.handlePage(1)} disabled={this.state.page === this.state.lastPage - 1}>
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" onClick={() => this.handlePage(-1)} disabled={this.state.page === 0}>
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <FormControl className="trans-logs-filter" variant="outlined">
                            <InputLabel>Filter</InputLabel>
                            <Select
                                value={filterOption}
                                onChange={this.handleChangeFilter}
                                label="Filter"
                            >
                                {filterOptions.map( type => {
                                    return <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
					<div className="cards">
						{logs.map(log => {
							return (() => {
								switch (log.operationType) {
									case "PRODUCT":
										return <ProductCard key={log.idTransaction} product={log} />;
									case "PRODUCT_REQUEST":
										return <ProductReqCard key={log.idTransaction} product={log} />;
                                    case "PRODUCT_RESPONSE":
										return <ProductResponseCard key={log.idTransaction} product={log} />;
									case "MESSAGE":
										return <MessageCard key={log.idTransaction} product={log} />;
									case "CALL":
										return <CallCard key={log.idTransaction} product={log} />;
									default:
										return null;
								}
							})();
						})}
					</div>
                    <div className="transactions-status-btns">
						<div className="pages-controll">
							<MobileStepper
								style={{
									height: "56px"
								}}
								steps={this.state.lastPage}
								position="static"
								variant="text"
								activeStep={this.state.page}
								nextButton={
									<Button size="small" onClick={() => this.handlePage(1)} disabled={this.state.page === this.state.lastPage - 1}>
										Next
										<KeyboardArrowRight />
									</Button>
								}
								backButton={
									<Button size="small" onClick={() => this.handlePage(-1)} disabled={this.state.page === 0}>
										<KeyboardArrowLeft />
										Back
									</Button>
								}
							/>
						</div>
					</div>
				</div>
			</div>
            <TransactionsStats ref={this.transactionsStatsRef} date={{ startDate: this.state.startDate, endDate: this.state.endDate }}/>
            </>
		);
    }
}

export default Transactions