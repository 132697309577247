import React from 'react';
import * as moment from 'moment';
import { Link } from 'react-router-dom';

export default function TransLogRows(props) {
    const { logs } = props;
    
    return logs.map( log =>
        <Link to={"/transaction/" + log.idTransaction} className="user-table-row" key={log.idTransaction}>
            <div className="user-table-col user-table-col-transaction">
                <span className="user-table-col-val">{Number(log.amount.toFixed(4))} $</span>
                <span className="user-table-col-subval">{log.idTransaction}</span>
            </div>
            <div className="user-table-col user-table-col-operation">
                <span className="user-table-col-val">{log.operationType}</span>
                <span className="user-table-col-subval">{log.operationStatus || log.productType}</span>
            </div>
            <div className="user-table-col user-table-col-direction">
                <span className="user-table-col-val">{log.direction}</span>
                <span className="user-table-col-subval">{log.status}</span>
            </div>
            <div className="user-table-col user-table-col-partner">
                <span className="user-table-col-val">{log.partnerName}</span>
                <span className="user-table-col-subval">{log.partnerId}</span>
            </div>
            <div className="user-table-col user-table-col-date">
                <span className="user-table-col-val">{moment(log.transactionStartDate).format('DD-MM-YYYY HH:mm')}</span>
                {log.transactionCompleteDate && <span className="user-table-col-subval">{moment(log.transactionCompleteDate).format('DD-MM-YYYY HH:mm')}</span>}
            </div>
        </Link>
    )
}
