import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/forum-history.css';
import AnswerCard from 'components/forum/AnswerCard';
import QuestionCard from 'components/forum/QuestionCard';
import CommentCard from 'components/forum/CommentCard';

// libs

// imgs

class ForumHistoryLog extends Component {
  
  constructor(props) {
    super(props);
    this.getUserPosts = this.getUserPosts.bind(this);

    this.state = {
        posts: [],
        topic: {
            actualContent: {
            title: ''
            }
        }
    };

    // const query = new URLSearchParams(this.props.location.search);
    // this.topicID = query.get('id');

    this.getUserPosts();
  }

  async getUserPosts() {
    try {
      let response = await APIrequest.forum_get_user_posts(this.props.user.idUser);
      console.log(response);
      this.setState({
        posts: response.data
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  render() {

    return (
        <div className="user-profile-forum-log cards">
            {this.state.posts.map((post) => {
                switch (post.type) {
                    case 'ANSWER':
                        return <AnswerCard key={post.idAnswer} answer={post} updateCallback={() => this.getTopic(this.topicID)} history={this.props.history} linkToTopic showType/>
                    case 'TOPIC':
                        return <QuestionCard key={post.idTopic} question={post} updateCallback={this.getTopics} history={this.props.history} linkToTopic showType/>
                    case 'COMMENT':
                        return <CommentCard key={post.idComment} comment={post} updateCallback={this.props.updateCallback} history={this.props.history} linkToTopic showType/>
                    default:
                        return null;
                }
            })}
        </div>
    )
  }
}

export default ForumHistoryLog
