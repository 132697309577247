import React, { useState } from 'react'
import APIrequest from '../../../APIcalls/APIrequest';

// libs
import { Button, Card, CardContent, TextField, Typography } from '@material-ui/core'
import moment from "moment";

export default function SubscriptionTile(props) {
    const { 
        idUser, 
        sub, 
        sub: { 
            id, 
            createdDate, 
            lastModifiedDate, 
            name: initName, 
            // price: initPrice, 
            status, 
            about: initAbout 
        }, 
        userUpdatedCallback 
    } = props;
    // const [price, setPrice] = useState(initPrice);
    const [about, setAbout] = useState(initAbout);
    const [name, setName] = useState(initName);

    const updateSub = async () => {
        try {
            const data = {
                ...sub,
                idSubscription: id,
                // price,
                about,
                name
            }
            const response = await APIrequest.save_subscription(data, idUser);

            if (response.status === 200) {
                userUpdatedCallback();
                alert("Success!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const removeSub = async () => {
        try {
            const response = await APIrequest.remove_subscription(id, idUser);
            console.log(response);
            if (response.status === 200) {
                userUpdatedCallback()
                alert("Success!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Card className="subscription-tile">
            <CardContent>
                <Typography component="p">
                    ID: {id}
                </Typography>
                <Typography component="p">
                    Created date: {moment(createdDate).format("LLL")}
                </Typography>
                <Typography component="p">
                    Last Modified Date: {moment(lastModifiedDate).format("LLL")}
                </Typography>
                <Typography component="p">
                    Status: {status}
                </Typography>
                <TextField
                    fullWidth
                    label="Name"
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                />
                {/* <br/>
                <TextField
                    fullWidth
                    label="Price"
                    value={price}
                    onChange={(ev) => setPrice(ev.target.value)}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                /> */}
                <br/>
                <TextField
                    fullWidth
                    multiline
                    label="About"
                    value={about}
                    onChange={(ev) => setAbout(ev.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                    variant="outlined"
                />
                <div className="mt_16">
                    <Button style={{ marginRight: "16px" }} variant="contained" color="primary" onClick={updateSub}>Update subscription</Button>
                    {status === "ENABLED" && (
                        <Button variant="contained" color="secondary" onClick={removeSub}>Remove subscription</Button>
                    )}
                </div>
            </CardContent>
        </Card>
    )
}
