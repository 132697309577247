import React, { Component } from "react";
import APIrequest from "APIcalls/APIrequest";
import "./css/forum.css";
import QuestionCard from "./QuestionCard";

// libs
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

// imgs
import RefreshIcon from "imgs/refresh.svg";

class Forum extends Component {
	constructor(props) {
		super(props);
		this.getTopics = this.getTopics.bind(this);

		this.state = {
			age: "",
			categories: [],
			selectedCategory: "all",
			page: 0,
			lastPage: 1,
			questions: [],
			feed: "CATEGORIES_LATEST"
		};

		this.getTopics();
		this.getCategories();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.page !== this.state.page) {
			this.getTopics();
		}
	}

	handleChange = name => event => {
		let value = event;
		if (event.target && (event.target.value || event.target.value === "")) value = event.target.value;
		this.setState(
			{
				[name]: value
			},
			() => {
				if (name === "selectedCategory" || name === "feed") {
					this.getTopics();
				}
			}
		);
	};

	async getCategories() {
		try {
			const response = await APIrequest.get_forum_categories("en");
			this.setState({
				categories: response.data
			});
		} catch (error) {
			alert("Network error. Try again later");
		}
	}

	async getTopics() {
		let selectedCategory;
		if (this.state.selectedCategory === "all") selectedCategory = [];
		else selectedCategory = [this.state.selectedCategory.idCategory];
		let data = {
			page: this.state.page,
			size: 12,
			categories: selectedCategory
		};

		try {
			let response = await APIrequest.forum_get_topics(data, this.state.feed);
			// console.log(response.data.items);
			this.setState({
				questions: response.data.items,
				lastPage: response.data.totalPages
			});
		} catch (error) {
			console.log(error);
		}
	}

	handlePage(step) {
		this.setState({
			page: this.state.page + step
		});
	}

	render() {
		return (
			<div className="forum">
				<div className="forum-container">
					<div className="forum-header-container">
						<span className="forum-header">Forum</span>
						<img onClick={() => this.getTopics()} src={RefreshIcon} alt="refresh" />
					</div>
					<div className="forum-status-btns">
						<TextField
							select
							className="select-field"
							label="Select category"
							value={this.state.selectedCategory}
							onChange={this.handleChange("selectedCategory")}
							margin="normal"
							variant="outlined"
						>
							<MenuItem value="all">All</MenuItem>
							{this.state.categories.map(category => (
								<MenuItem key={category.idCategory} value={category}>
									{category.localizedString}
								</MenuItem>
							))}
						</TextField>
						<TextField select className="select-field" label="Feed" value={this.state.feed} onChange={this.handleChange("feed")} margin="normal" variant="outlined">
							<MenuItem value="CATEGORIES_LATEST">CATEGORIES_LATEST</MenuItem>
							<MenuItem value="CATEGORIES_UPDATED">CATEGORIES_UPDATED</MenuItem>
							<MenuItem value="SUSPENDED">SUSPENDED</MenuItem>
							<MenuItem value="REMOVED">REMOVED</MenuItem>
						</TextField>
						<div className="pages-controll">
							<div className="page-counter">
								{this.state.page + 1} / {this.state.lastPage}
							</div>
							<MobileStepper
								style={{
									height: "56px"
								}}
								steps={5}
								position="static"
								variant="text"
								activeStep={1}
								nextButton={
									<Button size="small" onClick={() => this.handlePage(1)} disabled={this.state.page === this.state.lastPage - 1}>
										Next
										<KeyboardArrowRight />
									</Button>
								}
								backButton={
									<Button size="small" onClick={() => this.handlePage(-1)} disabled={this.state.page === 0}>
										<KeyboardArrowLeft />
										Back
									</Button>
								}
							/>
						</div>
					</div>
					<div className="cards">
						{this.state.questions.map(question => {
							return <QuestionCard key={question.idTopic} question={question} updateCallback={this.getTopics} history={this.props.history} />;
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default Forum;
