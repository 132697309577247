import React, { Component } from "react";
import APIrequest from "APIcalls/APIrequest";
import "./css/login.css";

// redux
import * as storeUserInfo from "redux/actions/UserAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// libs
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

// import upash from 'upash';
// import pbkdf2 from '@phc/pbkdf2';
import hashPassword from "functions/hashPassword";

// imgs
import PPlogo from "imgs/pp-logo.svg";

class Login extends Component {
	constructor(props) {
		super(props);
		this.submitLogin = this.submitLogin.bind(this);
		this.setPassword = this.setPassword.bind(this);
		this.loginToAdmin = this.loginToAdmin.bind(this);

		this.state = {
			email: "",
			password: "",
			pass1: "",
			pass2: "",
			showPassword: false,
			passwordFieldsType: false,
			emailFieldIsdisabled: false
		};
	}

	handleChange = key => event => {
		this.setState({
			[key]: event.target.value
		});
	};

	handleMouseDownPassword = event => {
		event.preventDefault();
	};

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	validatePassword(str) {
		// at least one number, one lowercase and one uppercase letter
		// at least 8 characters that are letters, numbers or the underscore
		var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{8,}$/;
		return re.test(str);
	}

	async setPassword() {
		let data = {
			pass1: this.state.pass1,
			pass2: this.state.pass2
		};
		if (data.pass1 !== data.pass2) {
			alert("Passwords must be the same");
		} else if (!this.validatePassword(data.pass1)) {
			alert("Your password should contain at least one number, one lowercase and one uppercase letter, 8 characters, numbers or the underscore");
		} else {
			try {
				data.pass1 = hashPassword(data.pass1);
				data.pass2 = data.pass1;
				let response = await APIrequest.reset_password_admin(data);
                localStorage.setItem("user", JSON.stringify(response.data));
                this.props.history.push("/search");
			} catch (error) {
                alert("Error");
            }
		}
	}

	async submitLogin() {
		let data = {
			email: this.state.email
		};

		if (!data.email) {
			alert("enter your email");
		} else if (!this.validateEmail(this.state.email)) {
			alert("invalid email");
		} else {
			try {
				let response = await APIrequest.check_admin(data);
				if (response.data.result === "NEED_PASSWORD") {
					this.setState({ passwordFieldsType: "setPass", emailFieldIsdisabled: true });
				} else if (response.data.result === "EXIST") {
					this.setState({ passwordFieldsType: "enterPass", emailFieldIsdisabled: true });
				} else if (response.data.result === "NEW") {
					alert("You don't have access rights. Please, contact technical support for details");
				}
			} catch (error) {
				console.log(error);
			}
		}
	}

	async loginToAdmin() {
		try {
			let data = {
				email: this.state.email
			};
			data.password = hashPassword(this.state.password);

			let response = await APIrequest.login_admin(data);
			localStorage.setItem("user", JSON.stringify(response.data));
			this.props.history.push("/search");
		} catch (error) {
			alert("Wrong password");
			console.log(error);
		}
	}

	renderPasswordField() {
		let result;
		if (this.state.passwordFieldsType === "enterPass") {
			result = (
				<div className="flex-col">
					<FormControl className="pass-input login-input">
						<InputLabel htmlFor="adornment-password">Password</InputLabel>
						<Input
							id="adornment-password"
							type={this.state.showPassword ? "text" : "password"}
							value={this.state.password}
							onChange={this.handleChange("password")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}>
										{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<Button onClick={this.loginToAdmin} variant="contained" color="primary" className="submit-login">
						Login
					</Button>
				</div>
			);
		} else if (this.state.passwordFieldsType === "setPass") {
			result = (
				<div className="flex-col">
					<FormControl className="pass-input login-input">
						<InputLabel htmlFor="adornment-password">Set password</InputLabel>
						<Input
							id="adornment-password-1"
							type={this.state.showPassword ? "text" : "password"}
							value={this.state.pass1}
							onChange={this.handleChange("pass1")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}>
										{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<FormControl className="pass-input login-input">
						<InputLabel htmlFor="adornment-password">Retype your password</InputLabel>
						<Input
							id="adornment-password-2"
							type={this.state.showPassword ? "text" : "password"}
							value={this.state.pass2}
							onChange={this.handleChange("pass2")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword}>
										{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<Button onClick={this.setPassword} variant="contained" color="primary" className="submit-login">
						Set password
					</Button>
				</div>
			);
		} else {
			result = (
				<Button onClick={this.submitLogin} variant="contained" color="primary" className="submit-login">
					Login
				</Button>
			);
		}
		return result;
	}

	render() {
		return (
			<div className="login">
				<div className="login-content">
					<div className="login-header-container">
						<img src={PPlogo} alt="PayPhone" />
						<h2 className="login-header">
							Login to PayPhone <br /> admin dashboard
						</h2>
					</div>
					<form className="login-form" autoComplete="on">
						<TextField
							label="Enter your email"
							className="email-input login-input"
							type="email"
							autoComplete="email"
							value={this.state.email}
							onChange={this.handleChange("email")}
							disabled={this.state.emailFieldIsdisabled}
						/>
						{this.renderPasswordField()}
					</form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		storeUserInfo: bindActionCreators(storeUserInfo, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
