import { Button, TextField } from '@material-ui/core';
import React, { Component } from 'react'
import APIrequest from '../../APIcalls/APIrequest';

export default class RefundBtn extends Component {

    constructor(props) {
        super(props);
        this.refundPayment = this.refundPayment.bind(this);

        this.state = {
            reason: "",
            amount: 0,
            showRefundBtn: true,
        }
    }

    handleChange = key => event => {
		this.setState({ [key]: event.target.value });
	};

    async refundPayment() {
        try {
            const data = {
                "idTransaction": this.props.product.idTransaction,
                "reason": this.state.reason,
            };
            
            if (Number(this.state.amount) >= 1) data.amount = this.state.amount;

            const response = await APIrequest.refund_payment(data);
            if (response.status === 200) {
                alert("Success!");
                this.setState({ showRefundBtn: false });
            }
        } catch (error) {
            alert("Error");
            console.log(error);
        }
    }

    render() {
        if (!!!this.props.product || this.props.product.operationType !== "RECHARGE") return null;
        const { reason, amount, showRefundBtn } = this.state;
        const { product: { operationType, status, transactionInfoDTO: { paymentSystem } = { transactionInfoDTO: {} } } } = this.props;
        const showBtn = status === "COMPLETED" && operationType === "RECHARGE" && paymentSystem === "STRIPE" && showRefundBtn;

        return showBtn ? (
            <div className="refund-container">
                <div className="title-3">Refund</div>
                <div className="refund-controls">
                    <TextField
                        className="reason-input"
                        label="Reason"
                        value={reason}
                        variant="outlined"
                        helperText=" "
                        onChange={this.handleChange('reason', 'value')}
                    />
                    <TextField
                        className="amount-input"
                        label="Amount"
                        value={amount}
                        variant="outlined"
                        helperText="0 to refund 100%"
                        onChange={this.handleChange('amount', 'value')}
                    />
                    <Button onClick={this.refundPayment} variant="contained" color="primary">Submit</Button>
                </div>
            </div>
        ) : null;
    }
}
