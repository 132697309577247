import React, { Component } from 'react'
import APIrequest from 'APIcalls/APIrequest';
import StreamItem from './StreamItem';
import './css/stream.css';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default class Streams extends Component {

    constructor(props) {
        super(props);

        this.state = {
            streams: []
        }

        this.getStreams();
    }

    async getStreams() {
        try {
            const response = await APIrequest.get_streams();
            this.setState({
                streams: response.data
            })
        } catch (error) {
            alert("Server error");
            console.log(error);
        }
    }

    render() {
        const { streams } = this.state;

        return (
            <div className="streams">
                <div className="title-3 mb_16">Streams</div>
                <div>
                    <Link to="/create-stream" className="mr_16">
                        <Button variant="contained" color="primary" onClick={() => {}}>Create new stream</Button>
                    </Link>
                    <Link to="/bind-stream">
                        <Button variant="outlined" color="primary" onClick={() => {}}>Bind stream</Button>
                    </Link>
                </div>
                <br/>
                <div className="streams-list">
                    {streams.map( stream => <StreamItem 
                        key={stream.id}
                        stream={stream} 
                        onDelete={(newStreams) => this.setState({ streams: newStreams})} 
                    />)}
                </div>
            </div>
        )
    }
}