import React, { Component } from 'react';
import './css/promocodes.css';
import APIrequest from 'APIcalls/APIrequest';
import moment from 'moment';
import Button from '@material-ui/core/Button';

export default class PromocodeTile extends Component {

    async deletePromoCode(id) {
        try {
            const data = {
                id: id
            }
            await APIrequest.delete_promo_code(data);
            alert("Promocode deleted!");
            this.props.deletedCallback();
        } catch (error) {
            alert("Request failed")
        }
    }

    renderValue(key, value) {
        if (key.toLowerCase().includes("date"))
            return moment(value).format("DD MMMM YYYY, HH:mm")
        else
            return String(value)
    }

    render() {
        const { promocode, usersCallback } = this.props; 

        return promocode.status === "ENABLED" ?
            <div className="promocode-tile">
                <div className="title-4 mb_16">{promocode.code}</div>
                {Object.entries(promocode).map( ([key, value]) => {
                    if (key === 'users' || key === 'code') return null
                    return <div key={key} className="text-1">{`${key}: ${this.renderValue(key, value)}`}</div>
                })}
                <div className="mt_16">
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => this.deletePromoCode(promocode.id)}
                    >
                        delete
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => usersCallback(promocode.users)}
                        style={{ marginLeft: '16px' }}
                    >
                        users
                    </Button>
                </div>
            </div>
            :
            null
    }
}