import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/categories.css';

// libs
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import CardActionArea from '@material-ui/core/CardActionArea';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class Placeholder extends Component {

    constructor(props) {
        super(props);
        this.addPlaceholder = this.addPlaceholder.bind(this);
        this.deletePlaceholder = this.deletePlaceholder.bind(this);
        this.state = {
            placeholders: [],
            selectedPlaceholder: null,
            age: 10
        };
        this.categoryName = this.props.location.pathname.split('/')[2] || '';
        this.categoryID = this.props.location.search.split('?id=')[1] || '';
        this.getPlaceholdersByCategories();
    }

    handleChange(name, value) {
        this.setState({
            [name]: value,
        });
    };

    handleChangeObjState = name => event => {
        let value = event;
        if (event.target && (event.target.value || event.target.value === ''))
            value = event.target.value;

        let newState = Object.assign({}, this.state[name[0]]);
        if (name.length === 2) {
            newState[name[1]] = value;
        }
        else if (name.length === 3) {
            newState[name[1]][name[2]] = value;
        }
        this.setState({
            selectedPlaceholder: newState
        })
    };

    async getPlaceholdersByCategories() {
        const data = {
            categories: [this.categoryName],
        }
        try {
            const response = await APIrequest.get_placeholders_by_categories(data);
            this.setState({
                placeholders: Object.values(response.data)[0] ? Object.values(response.data)[0] : []
            })
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async deletePlaceholder(id) {
        let data = {
            id: id,
        }
        try {
            await APIrequest.delete_placeholder(data);
            alert("Placeholder deleted!");
            this.setState({
                selectedPlaceholder: null
            });
            this.getPlaceholdersByCategories();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    async addPlaceholder() {
        let data = this.state.selectedPlaceholder;
        data.idCategory = this.categoryID;
        try {
            await APIrequest.add_placeholder(data);
            alert("Placeholder changed!");
            this.setState({
                selectedPlaceholder: null
            });
            this.getPlaceholdersByCategories();
        } catch (error) {
            console.log(error)
            alert("Network error. Try again later")
        }
    }

    render() {

        return (
            <div className="categories">
                <div className="page-header-container">
                    <Typography variant="h4" component="h2">
                        Placeholders
                    </Typography>
                </div>
                <div className="page-content">
                    {this.state.selectedPlaceholder ? 
                        <div className="edit-category">
                            <FormControl variant="outlined">
                                <Select
                                    value={this.state.selectedPlaceholder.field}
                                    onChange={this.handleChangeObjState(["selectedPlaceholder", "field"], "value")}
                                    input={<OutlinedInput name="age" id="outlined-age-simple" />}
                                >
                                    <MenuItem value={"WORK"}>WORK</MenuItem>
                                    <MenuItem value={"ABOUT_ME"}>ABOUT_ME</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                multiline
                                label="En"
                                value={this.state.selectedPlaceholder.localizedTexts.en}
                                onChange={this.handleChangeObjState(["selectedPlaceholder", "localizedTexts", "en"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Ru"
                                value={this.state.selectedPlaceholder.localizedTexts.ru}
                                onChange={this.handleChangeObjState(["selectedPlaceholder", "localizedTexts", "ru"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                multiline
                                label="Uk"
                                value={this.state.selectedPlaceholder.localizedTexts.uk}
                                onChange={this.handleChangeObjState(["selectedPlaceholder", "localizedTexts", "uk"], "value")}
                                margin="normal"
                                variant="outlined"
                            />
                            <Button className="edit-category-btn" variant="contained" color="primary" onClick={this.addPlaceholder}>save</Button>
                            <Button className="edit-category-btn" variant="contained" color="secondary" onClick={this.deletePlaceholder.bind(this, this.state.selectedPlaceholder.id)}>delete</Button>
                        </div>
                    :
                        <div className="categories-cards">
                            {this.state.placeholders.map( a => {
                                return <Card className="categories-card">
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            {a.field}
                                        </Typography>
                                        <br/>
                                        <Typography color="textSecondary">
                                            ID: {a.id}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button fullWidth size="small" onClick={this.handleChange.bind(this, "selectedPlaceholder", a)}>Edit</Button>
                                    </CardActions>
                                </Card>
                            })}
                            <Card className="categories-card">
                                <CardActionArea className="add-new-category-CAA" onClick={this.handleChange.bind(this, "selectedPlaceholder", { field: '', localizedTexts: { en: '', ru: '', uk: '' } })}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Add new placeholder
                                    </Typography>
                                </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Placeholder;