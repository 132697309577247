import { combineReducers } from "redux";
import modalState from "./modal";
import resetPassToken from "./resetPassToken";
import user from "./user";
import authToken from "./authToken";
import callStatus from "./callStatus";
import navCounters from "./navCounter";

export default combineReducers({
	modalState,
	resetPassToken,
	user,
	authToken,
	callStatus,
	navCounters
});
