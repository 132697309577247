import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import landListObj from 'json/languages.json';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        minWidth: 760,
        maxWidth: 760,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

let landListArray = [];
for (let vehicle in landListObj) {
    let value = landListObj[vehicle];
    value.id = vehicle;
    landListArray.push(value);
}
landListArray.sort(function (a, b) {
    return a.order - b.order;
});

class LangSelect extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        let selectedLangs = [];
        if (this.props.selectedLangs) {
            this.props.selectedLangs.map(a => {
                landListArray.map(b => {
                    if (a === b.id)
                        selectedLangs.push(b)
                })
            })
        }
    
        this.state = {
            langs: selectedLangs,
        };
    }

    handleChange = event => {
        this.setState({ langs: event.target.value });
        this.props.onLangChange(event.target.value.map(a => { return a.id }))
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-checkbox">Select languages</InputLabel>
                    <Select
                        multiple
                        value={this.state.langs}
                        onChange={this.handleChange}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip key={value.id} label={value.name} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {landListArray.map(lang => (
                            <MenuItem key={lang.name} value={lang}>
                                <Checkbox checked={(this.state.langs.findIndex(p => p.name === lang.name) > -1)} />
                                <ListItemText primary={lang.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>                
            </div>
        );
    }
}

LangSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LangSelect);