import { Button, TextField } from '@material-ui/core';
import React, { Component } from 'react'
import APIrequest from '../../APIcalls/APIrequest';

export default class AddScore extends Component {

    constructor(props) {
        super(props);
        this.setScore = this.setScore.bind(this);

        this.state = {
            comment: "",
            score: 5,
        }
    }

    handleChange = key => event => {
		this.setState({ [key]: event.target.value });
    };
    
    async setScore() {
        try {
            const { operationId, partnerId, operationType } = this.props.product;
            const data = {
                "partnerId": partnerId,
                "relatedOperationType": operationType,
                "score": Number(this.state.score),
                "comment": this.state.comment,
                "relatedOperationId": operationId
            };
            console.log(data);
            const response = await APIrequest.set_score(data);
            console.log(response);
            if (response.status === 200) {
                alert("Success!");
                this.setState({
                    comment: "",
                    score: 5,
                })
            }
        } catch (error) {
            alert("Error")
            console.log(error);
        }
    }

    isBtnDisabled() {
        const { score: scoreStr } = this.state;
        const score = Number(scoreStr);
        return !(score >= 1 && score <=5);
    } 

    render() {
        const { comment, score } = this.state;
        const { status, operationType } = this.props.product;
        const displayCond = status === "COMPLETED" && ( operationType === "PRODUCT_REQUEST" || operationType === "CALL" || operationType === "MESSAGE" );

        return displayCond ? (
            <div className="refund-container">
                <div className="title-3">AddScore</div>
                <div className="refund-controls">
                    <TextField
                        className="reason-input"
                        label="Comment"
                        value={comment}
                        variant="outlined"
                        onChange={this.handleChange('comment', 'value')}
                    />
                    <TextField
                        className="amount-input"
                        label="Score"
                        value={score}
                        variant="outlined"
                        onChange={this.handleChange('score', 'value')}
                    />
                    <Button disabled={this.isBtnDisabled()} onClick={this.setScore} variant="contained" color="primary">Submit</Button>
                </div>
            </div>
        ) : null
    }
}
